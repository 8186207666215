import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication-service';
import { take } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.authenticationService.logout().pipe(take(1))
      .subscribe(
        () => {
          this.router.navigate(['/security/login']);
        }
        // TODO caso de fallo pendiente de implementa
      );
  }

}
