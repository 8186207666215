import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, mergeMap, Observable, ObservedValueOf, OperatorFunction, throwError } from 'rxjs';
import { OData } from '../model/odata';
import { SecurityBasicHttpClientService } from './security-basic-http-client.service';
import { ODataError } from '../model/odata-error';
import { ODataCustomError } from '../model/odata-custom-error';
import { ErrorDirect } from '../model/error-api';

@Injectable()
export class ODataBasicHttpClientService {

  constructor(
    private securityBasicHttpClientService: SecurityBasicHttpClientService
  ) { }

  private convert<T>(): OperatorFunction<OData | ODataError, T | undefined> {
    return map(source => {
      const data = source as OData;
      if (data !== undefined && data['@odata.context'] !== undefined && typeof data['@odata.context'] === 'string') {
        if (typeof data.value === 'string') {
          const result = data.value.length > 0 ? JSON.parse(data.value) as T : undefined;
          const error = result as unknown as ODataError;
          if (error != undefined && error.Code !== undefined && typeof error.Code === 'string' && error.Code.startsWith('Err')
            && error.Description !== undefined && typeof error.Description === 'string') {
            throw new ODataCustomError('Fallo capturado', error);
          } else {
            return result as unknown as T;
          }
        } else {
          return data.value as T;
        }
      } else {
        const error = data as unknown as ErrorDirect;
        if (error !== undefined && error.error !== undefined) {
          const errorGen = new Error(error.error.message);
          errorGen.name = error.error.code;
          throw errorGen;
        } else {
          return data as unknown as T;
        }
      }
    });
  }

  public get<T>(fragment: string, params?: HttpParams): Observable<T | undefined> {
    return this.securityBasicHttpClientService.get<OData>(fragment, params).pipe(this.convert<T>());
  }

  public post<TBody, T>(fragment: string, body: TBody, params?: HttpParams): Observable<T | undefined> {
    return this.securityBasicHttpClientService.post<TBody, OData>(fragment, body, params).pipe(this.convert<T>());
  }
  public put<TBody, T>(fragment: string, body: TBody, params?: HttpParams): Observable<T | undefined> {
    return this.securityBasicHttpClientService.put<TBody, OData>(fragment, body, params).pipe(this.convert<T>());
  }
}
