import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { OrderState } from 'src/app/order/model/order-state';

@Component({
  selector: 'app-container-counter-stats',
  templateUrl: './container-counter-stats.component.html',
  styleUrls: ['./container-counter-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerCounterStatsComponent implements OnChanges {

  @Input() public stats: [OrderState, number][] | null = null;
  @Input() public orderCount = 0;
  
  picked = 0;
  packing = 0;
  packed = 0;
  incident = 0;
  processed = 0;
  completed = 0;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.stats !== null) {
      this.picked = this.stats?.find(s => s[0] === OrderState.Picked)?.[1] ?? 0;
      this.packing = this.stats?.find(s => s[0] === OrderState.Packing)?.[1] ?? 0;
      this.packed = this.stats?.reduce((acc, s) => acc + (s[0] === OrderState.Packed || s[0] === OrderState.Unknow ? s[1] : 0), 0) ?? 0;
      this.incident = this.stats?.find(s => s[0] === OrderState.Incidence)?.[1] ?? 0;
      this.processed = this.orderCount > 0 ? Math.floor( ((this.packed + this.incident) / this.orderCount) * 100) : 0;
      this.completed = this.packed + this.incident;
    } else {
      this.picked = 0;
      this.packing = 0;
      this.packed = 0;
      this.incident = 0;
      this.processed = 0;
      this.completed = 0;
    }
  }

  ngOnInit(): void {
  }

}
