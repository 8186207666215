import { BehaviorSubject, Observable, map, of } from "rxjs";
import { UserService } from "./user-service";
import { UserInfo } from "../model/user-info";

export class UserCustomService extends UserService {

    private userInfoSubject = new BehaviorSubject<UserInfo | null>(null);
    public override setUserInfo(data: UserInfo): void {
        this.userInfoSubject.next(data);
    }
    public getUserName(): string | null {
        const value = this.userInfoSubject.value;
        return value !== null ? value.userId : null;
    }
    public selectUserName(): Observable<string | null> {
        return this.userInfoSubject.asObservable().pipe(map(data => data !== null ? data.userId : null));
    }

    public override clearUserInfo(): void {
        this.userInfoSubject.next(null);
    }
}
