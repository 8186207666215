import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnviromentContextService } from '../services/enviroment-context-service';

@Component({
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

  constructor(
    protected enviromentContextService: EnviromentContextService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParamMap.subscribe(params => {
      const environment = params.get("environment");
      const company = params.get("company");
      const packingStation = params.get("packingStation");
      const requestWeight = params.get("requestWeight");
      if (requestWeight !== null) {
        this.enviromentContextService.setRequestWeight(true);
        if (requestWeight.toLowerCase() === 'false') {
          this.enviromentContextService.setRequestWeight(false);
        }
      } else {
        this.enviromentContextService.setRequestWeight(true);
      }
      if (company !== null && environment !== null && packingStation !== null) {
        this.enviromentContextService.setCompany(company);
        this.enviromentContextService.setEnviroment(environment);
        this.enviromentContextService.setPackingStation(packingStation);
        this.router.navigateByUrl("/manage/packingStation");
      }
    });

  }

  ngOnInit(): void {
  }

}
