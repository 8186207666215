import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPageComponent } from '../layout/main-page/main-page.component';
import { PackageInstructionsComponent } from './package-instructions/package-instructions.component';
import { PackageOrderComponent } from './package-order/package-order.component';
import { SelectBoxComponent } from './select-box/select-box.component';

const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
    children: [
      { path: 'instructions', component: PackageInstructionsComponent },
      { path: 'order', component: PackageOrderComponent },
      { path: 'selectBox', component: SelectBoxComponent }
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PackageRoutingModule { }
