import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface WeightDialogData {
  weight: number;
}

@Component({
  selector: 'app-weight-dialog',
  templateUrl: './weight-dialog.component.html',
  styleUrls: ['./weight-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeightDialogComponent {

  protected weight: number = 0;
  constructor( 
    public dialogRef: MatDialogRef<WeightDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WeightDialogData,
    ) { 
      if (data !== undefined) {
        this.weight = data.weight;
      }
    }

    onNoClick(): void {
      this.dialogRef.close(undefined);
    }

    updateWeight(value: number): void {
      this.weight = value;
    }

}
