import { Observable } from 'rxjs';

export abstract class AuthorizationTokenService {

  constructor() { }

  public abstract getToken(): Observable<string>;
  public abstract getExternalToken(): Observable<string>;
  public abstract isAutenticated(): Observable<boolean>;
}
