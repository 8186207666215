import { Component, Inject, OnInit } from '@angular/core';
import { LogisticDocument } from '../../model/logistic-document';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentPrintService } from '../../services/document-print.service';

@Component({
  selector: 'app-document-print-dialog',
  templateUrl: './document-print-dialog.component.html',
  styleUrls: ['./document-print-dialog.component.scss']
})
export class DocumentPrintDialogComponent implements OnInit {

  protected documentList: LogisticDocument[] = [];
  private documentSelected: LogisticDocument[] = [];

  constructor(public dialogRef: MatDialogRef<DocumentPrintDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LogisticDocument[],
    private documentPrintService: DocumentPrintService
  ) {
    if (data !== undefined) {
      this.documentList = data;
    } else {
      this.documentList = [];
    }
  }

  ngOnInit(): void {
  }

  protected onSelect(data: LogisticDocument[]) {
    this.documentSelected = data;
  }



  protected onPrint(): void {
    if (this.documentSelected.length > 0) {
      this.documentPrintService.printDocumentList(this.documentSelected, false).then(result => {});
    }
  }

}
