<form [formGroup]="form">
    <mat-form-field class="content" appearance="outline">
        <mat-label>Token security</mat-label>
        <mat-icon matPrefix>key</mat-icon>
        <textarea matInput  #message placeholder="Token para api" formControlName="token" data-test-id="securityTokenValue"></textarea>
    </mat-form-field>
</form>
<button mat-stroked-button color="primary" (click)="onSubmit()" data-test-id="aceptarSecurityTokenValue">Aceptar</button>
<ng-container *ngIf="autorizationTokenService.getToken() | async as tokenValue;else noToken">
<p>{{tokenValue}}</p>
</ng-container>
<ng-template #noToken><p>Sin token de seguridad</p></ng-template>
