import { DocumentContentStatus } from "./document-content-status";
import { DocumentLocation } from "./document-location";
import { LogisticDocumentPdfContent } from "./logistic-document-pdf-content";

export class LogisticDocument {
    public url!: string;
    public description!: string;
    public type!: string;
    public content: ArrayBuffer | undefined;
    public error: Error | undefined;
    public documentStatus: DocumentContentStatus = DocumentContentStatus.Unknown;
    public documentoLocation: DocumentLocation = DocumentLocation.Unknown;
    public pdfContent: LogisticDocumentPdfContent | undefined;
}
