import { Injectable } from '@angular/core';

@Injectable()
export class AppNameService  {


  constructor() {
  }

  public set appName(appName: string) {
    // Crear un objeto con la propiedad 'app'
    const cosmosObject = {
      app: appName // o 'pickingonline', según sea necesario
    };

    // Convertir el objeto a una cadena JSON
    const cosmosValue = JSON.stringify(cosmosObject);

    // Establecer la cookie 'cosmos'
    localStorage.setItem('cosmos', cosmosValue);
  }

}