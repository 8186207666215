import { createStore, emitOnce } from '@ngneat/elf';
import { withEntities, selectAllEntities, withActiveIds, selectActiveEntities, setEntities, selectEntity, getEntity, setActiveIds, getActiveEntities, entitiesPropsFactory, addEntities, selectManyByPredicate, upsertEntities, getAllEntities, updateEntities, updateEntitiesByPredicate, updateAllEntities } from '@ngneat/elf-entities';
import { MonoTypeOperatorFunction, Observable, distinctUntilChanged, filter, map, shareReplay } from 'rxjs';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache, selectIsRequestCached, clearRequestsCache } from '@ngneat/elf-requests';
import { Injectable } from '@angular/core';
import { Order } from '../../model/order';
import { produce } from 'immer';
import { OrderLine } from '../../model/order-line';
import { OrderContainer } from '../../model/order-container';
import { BoxContentItem } from 'src/app/package/model/box-content-item';
import { OrderKey } from '../../model/order-key';
import { Box } from 'src/app/package/model/box';
import { OrderLineHelper } from '../order-line-helper';
import { OrderState } from '../../model/order-state';
import { OrderIncidence } from '../../model/order-incidence';

export function write<S>(updater: (state: S) => void): (state: S) => S {
  return function (state) {
    return produce(state, (draft) => {
      updater(draft as S);
    });
  };
}

const { orderLineEntitiesRef, withOrderLineEntities } = entitiesPropsFactory('orderLine');
const { orderContainerEntitiesRef, withOrderContainerEntities } = entitiesPropsFactory('orderContainer');

@Injectable()
export class OrderRepository {

  activeOrder$: Observable<Order[]>;
  order$: Observable<Order[]>;
  activeOrderComplet$: Observable<Order>;

  private store;

  public skipWhileOrderCached: <T>(key: 'order' | `order-${string}`, options?: {
    value?: "none" | "partial" | "full" | undefined;
    returnSource?: Observable<any> | undefined;
  } | undefined) => MonoTypeOperatorFunction<T>;

  public skipWhileOrderContainerCached: <T>(key: 'orderContainer' | `orderContainer-${string}`, options?: {
    value?: "none" | "partial" | "full" | undefined;
    returnSource?: Observable<any> | undefined;
  } | undefined) => MonoTypeOperatorFunction<T>;

  constructor() {
    this.store = this.createStore();
    this.order$ = this.store.pipe(selectAllEntities());
    this.activeOrder$ = this.store.pipe(
      selectActiveEntities(),
      shareReplay(1));
    this.skipWhileOrderCached = createRequestsCacheOperator(this.store);
    this.skipWhileOrderContainerCached = createRequestsCacheOperator(this.store);
    this.activeOrderComplet$ = this.activeOrder$.pipe(
      map<Order[], Order | undefined>(data => data === undefined || (data !== undefined && data.length === 0) ? undefined : data[0]),
      filter(data => data !== undefined),
      distinctUntilChanged((prev, curr) => {
        const result = JSON.stringify(prev) === JSON.stringify(curr);
        return result;
      }),
      map<Order | undefined, Order>(data => data as Order)
    );
  }

  private cleanId(id: string | undefined): string | undefined {
    if (id === undefined) {
      return undefined;
    } else {
      return id.trim().toUpperCase();
    }
  }
  private cleanIdOrder(order: Order): Order {
    return produce(order, draft => {
      draft.id = this.cleanId(draft.id) as string;
    });
  }
  private cleanIdOrderKey(order: OrderKey): OrderKey {
    return produce(order, draft => {
      draft.id = this.cleanId(draft.id);
      draft.containerId = this.cleanId(draft.containerId);
    });
  }
  public upsertOrder(order: Order) {
    order = this.cleanIdOrder(order);
    this.store.update(
      updateRequestCache(`order-${order.id}`),
      upsertEntities([order]),
      upsertEntities(order.lines, { ref: orderLineEntitiesRef })
    );
  }

  public getActiveOrder(): Order | undefined {
    const active = this.store.query(getActiveEntities());
    return active === undefined || (active !== undefined && active.length === 0) ? undefined : active[0];
  }

  public updateStatus(orderId: OrderKey, status: OrderState | undefined) {
    let cleanStatus = OrderState.Unknow;
    if (status !== undefined) {
      cleanStatus = status;
    }
    orderId = this.cleanIdOrderKey(orderId);
    emitOnce(() => {
      this.store.update(
        updateEntities(orderId.id as string,
          write<Order>((order) => {
            order.status = cleanStatus;
          })
        )
      );
      this.store.update(updateEntities(orderId.containerId as string,
        write<OrderContainer>((orderContainer) => {
          if (orderContainer.orders !== undefined) {
            let found = false;
            for (let index = 0; index < orderContainer.orders.length && !found; index++) {
              const order = orderContainer.orders[index];
              if (order.id === orderId.id) {
                order.status = cleanStatus;
                found = true;
              }
            }
          }
        }), { ref: orderContainerEntitiesRef }
      ));
    });
  }

  public updateIncident(orderId: OrderKey, newState: OrderState, lines: Partial<OrderLine>[]) {
    orderId = this.cleanIdOrderKey(orderId);
    emitOnce(() => {
      this.updateStatus(orderId, newState);
      this.updateLines(orderId.id as string, lines, undefined);
    });
  }

  public updateOrderIncident(orderId: OrderKey, newState: OrderState, incidence: OrderIncidence) {
    orderId = this.cleanIdOrderKey(orderId);
    emitOnce(() => {
      this.updateStatus(orderId, newState);

      this.store.update(
        updateEntities(orderId.id as string,
          write<Order>((order) => {
            if (order.incident === undefined) {
              order.incident = [];
            }
            order.incident.push(incidence);
          })
        )
      );
      this.store.update(updateEntities(orderId.containerId as string,
        write<OrderContainer>((orderContainer) => {
          if (orderContainer.orders !== undefined) {
            let found = false;
            for (let index = 0; index < orderContainer.orders.length && !found; index++) {
              const order = orderContainer.orders[index];
              if (order.id === orderId.id) {
                if (order.incident === undefined) {
                  order.incident = [];
                }
                order.incident.push(incidence);
                found = true;
              }
            }
          }
        }), { ref: orderContainerEntitiesRef }
      ));
    });
  }

  private updateLinesInOrder(orderId: string) {
    orderId = this.cleanId(orderId) as string;
    const linesList = this.getLinesByOrderId(orderId);
    this.store.update(updateEntities(orderId,
      write<Order>((order) => {
        order.lines = linesList;
      })));
  }

  private cleanOrderContainer(orderContainer: OrderContainer): OrderContainer {
    return produce(orderContainer, draft => {
      draft.containerId = this.cleanId(draft.containerId) as string;
    });
  }

  private updateLinesInContainer(lines: Partial<OrderLine>[]) {
    const orderContainerList = this.store.query(getAllEntities({ ref: orderContainerEntitiesRef }));
    for (let index = 0; index < orderContainerList.length; index++) {
      let element = orderContainerList[index];
      element = this.cleanOrderContainer(element);
      this.store.update(updateEntities(element.containerId,
        write<OrderContainer>((orderContainer) => {
          if (orderContainer.orders !== undefined) {
            for (let indexLinex = 0; indexLinex < lines.length; indexLinex++) {
              const line = lines[indexLinex];
              line.orderId = this.cleanId(line.orderId as string);
              const order = orderContainer.orders.find(i => i.id === line.orderId);
              if (order !== undefined) {
                const orderLine = order.lines.find(i => i.sku === line.sku);
                if (orderLine !== undefined) {
                  orderLine.pending = (line.pending !== undefined ? line.pending : 0);
                }
              }
            }


          }
        }), { ref: orderContainerEntitiesRef }));
    }
  }

  public updateBoxContent(orderKey: OrderKey, boxContentItem: BoxContentItem) {
    orderKey = this.cleanIdOrderKey(orderKey);
    this.store.update(updateEntities(orderKey.containerId as string,
      write<OrderContainer>((orderContainer) => {
        const order = orderContainer.orders.find(i => i.id === orderKey.id);
        if (order !== undefined) {
          order.boxLines.push(boxContentItem);
        }
      }), { ref: orderContainerEntitiesRef }));
  }

  public addLines(lines: OrderLine[]): void {
    emitOnce(() => {
      this.store.update(addEntities(lines, { ref: orderLineEntitiesRef }));
      const orderId = this.cleanId(lines[0].orderId) as string;
      this.updateLinesInOrder(orderId);
    });
  }

  public getLinesByOrderId(orderId: string): OrderLine[] {
    orderId = this.cleanId(orderId) as string;
    return this.store.query(getAllEntities({ ref: orderLineEntitiesRef })).filter(i => i.orderId === orderId);
  }

  public selectLinesByOrderId(orderId: string): Observable<OrderLine[]> {
    orderId = this.cleanId(orderId) as string;
    return this.store.pipe(
      selectManyByPredicate(
        (item) => item.orderId === orderId,
        {
          ref: orderLineEntitiesRef
        }
      ),
      map(data => data !== undefined ? data : [])
    );
  }

  public closeBox(orderId: string, boxId: string) {
    orderId = this.cleanId(orderId) as string;
    this.store.update(updateEntities(orderId,
      write<Order>((order) => {
        for (let index = 0; index < order.boxLines.length; index++) {
          const element = order.boxLines[index];
          if (element.boxId === boxId) {
            element.closed = true;
          }
        }
      })));
  }

  private updateBoxContentLines(orderId: string, lines: Partial<OrderLine>[], box: Box | undefined) {
    orderId = this.cleanId(orderId) as string;
    const order = this.getById(orderId);
    if (order !== undefined && box !== undefined) {
      let boxContentList = [...order.boxLines];
      if (boxContentList === undefined) {
        boxContentList = [];
      }
      for (let index = 0; index < lines.length; index++) {
        const element = lines[index];
        const line = order.lines.find(i => i.id === element.id);
        let diff: number = 0;
        if (line !== undefined) {
          diff = line.pending - (element.pending !== undefined ? element.pending : 0);
        }
        let boxContent: BoxContentItem;
        const indexFound = boxContentList.findIndex(i => i.boxId === box?._id && i.sku === element.sku);
        if (indexFound !== -1) {
          boxContent = boxContentList[indexFound];
          boxContent = produce<BoxContentItem>(boxContent, (draft) => {
            draft.count = draft.count + diff;
          });
          boxContentList.splice(indexFound, 1)
        } else {
          boxContent = new BoxContentItem(orderId, element.sku as string, diff, box._id, box?.boxModelId, false);
        }
        boxContentList.push(boxContent);
      }
      this.store.update(updateEntities(orderId,
        write<Order>((order) => {
          order.boxLines = boxContentList;
        })));
    }
  }

  public updateBoxModelType(boxId: string, boxModelId: string) {
    emitOnce(() => {
      this.store.update(updateAllEntities(write<Order>((entity) => {
        entity.boxLines.forEach(i => {
          if (i.boxId === boxId) {
            i.boxType = boxModelId;
          }
        });
      })));
      this.store.update(updateAllEntities(write<OrderContainer>((entity) => {
        entity.orders.forEach(i => {
          i.boxLines.forEach(j => {
            if (j.boxId === boxId) {
              j.boxType = boxModelId;
            }
          });
        });
      }), { ref: orderContainerEntitiesRef }));
    });
  }

  public updateLines(orderId: string, lines: Partial<OrderLine>[], box: Box | undefined) {
    orderId = this.cleanId(orderId) as string;
    emitOnce(() => {
      if (box !== undefined) {
        this.updateBoxContentLines(orderId, lines, box);
      }
      this.store.update(upsertEntities(lines, { ref: orderLineEntitiesRef }));
      let newLinesList: OrderLine[] = [];
      const order = this.getById(orderId) as Order;
      for (const iterator of lines) {
        iterator.id = this.cleanId(iterator.id as string);
        const element = this.store.query(getEntity(iterator.id as string, { ref: orderLineEntitiesRef }))
        if (element !== undefined) {
          element.packingStatus = OrderLineHelper.getPackingStatusFromOrderLine(element, order.boxLines);
          newLinesList.push(element);
        }
      }
      this.store.update(upsertEntities(newLinesList, { ref: orderLineEntitiesRef }));
      this.updateLinesInOrder(orderId);
      this.updateLinesInContainer(newLinesList);
    });
  }

  setActiveId(id: Order['id']) {
    id = this.cleanId(id) as string;
    this.store.update(setActiveIds([id]));
  }

  public isResquestCached(id: string): Observable<boolean> {
    id = this.cleanId(id) as string;
    return this.store.pipe(selectIsRequestCached(`order-${id}`));
  }

  public resetCache(): void {
    this.store.update(clearRequestsCache());
  }



  public selectById(id: string): Observable<Order | undefined> {
    id = this.cleanId(id) as string;
    return this.store.pipe(selectEntity(id));
  }

  public getById(id: string): Order | undefined {
    id = this.cleanId(id) as string;
    return this.store.query(getEntity(id));
  }

  public getOrderContainerById(id: string): OrderContainer | undefined {
    id = this.cleanId(id) as string;
    return this.store.query(getEntity(id, { ref: orderContainerEntitiesRef }));
  }

  public setOrderContainer(orderContainer: OrderContainer) {
    orderContainer = this.cleanOrderContainer(orderContainer);
    emitOnce(() => {
      this.store.update(
        updateRequestCache(`orderContainer-${orderContainer.containerId}`),
        setEntities([orderContainer], { ref: orderContainerEntitiesRef })
      );
      if (orderContainer.orders !== undefined) {
        for (let index = 0; index < orderContainer.orders.length; index++) {
          const order = orderContainer.orders[index];
          if (order !== undefined && order.lines !== undefined && order.lines.length > 0) {
            this.upsertOrder(order);
          }
        }
      }
    })
  }

  private getOrderContainerCacheKey(id: string): `orderContainer-${string}` {
    id = this.cleanId(id) as string;
    return `orderContainer-${id}`;
  }

  public resetOrderContainer(id: string): void {
    id = this.cleanId(id) as string;
    const data = this.store.query(getEntity(id, { ref: orderContainerEntitiesRef }));
    if (data !== undefined) {
      this.setOrderContainer(data);
    }
  }

  public isOrderContainerResquestCached(id: string): Observable<boolean> {
    id = this.cleanId(id) as string;
    return this.store.pipe(selectIsRequestCached(this.getOrderContainerCacheKey(id)));
  }

  public selectOrderContainerById(id: string): Observable<OrderContainer | undefined> {
    id = this.cleanId(id) as string;
    return this.store.pipe(selectEntity(id, { ref: orderContainerEntitiesRef }));
  }


  private createStore(): typeof store {
    const store = createStore({ name: 'order' },
      withEntities<Order, 'id'>({ idKey: 'id' }),
      withOrderLineEntities<OrderLine, 'id'>({ idKey: 'id' }),
      withOrderContainerEntities<OrderContainer, 'containerId'>({ idKey: 'containerId' }),
      withActiveIds(),
      withRequestsCache<'order' | `order-${string}`>(),
      withRequestsCache<'orderContainer' | `orderContainer-${string}`>(),
      // withRequestsStatus<'order'>()
    );

    return store;
  }
}
