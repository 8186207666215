import { VoStatus } from "src/app/common-app/model/vo-status";
import { OrderLine } from "../model/order-line";
import { OrderLineView } from "../model/view/order-line-view";
import produce from 'immer';
import { OrderState } from "../model/order-state";

export class OrderViewHelper {
    public static init(lines: OrderLine[]): OrderLineView[] {
        let result = lines as OrderLineView[];
        result = produce(result, draft => {
            for (let index = 0; index < draft.length; index++) {
                const element = draft[index];
                element.voState = VoStatus.UnModified;
            }
        });
        return result;
    }

    public static convertStatus(status: OrderState, originalStatus: string | undefined = undefined): string {
        switch (status) {
          case OrderState.Incidence:
            return 'Incidence'
          case OrderState.Packed:
            return 'Packed'
          case OrderState.Packing:
            return 'Packing'
          case OrderState.Picked:
            return 'Picked'
          case OrderState.Picking:
            return 'Picking'
          case OrderState.Unknow:
            return 'Unknow'
          case OrderState.OutOfPacking:
            return originalStatus === undefined ? 'Unknow' : originalStatus
          default:
            return 'Unknow'
        }
      }
}