import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicHttpClientService } from 'src/app/api/services/basic-http-client.service';
import { RootObject } from '../model/container';
import { ContainerService } from './container.service';
import { EnviromentContextService } from 'src/app/enviroment/services/enviroment-context-service';

@Injectable()
export class ContainerApiService extends ContainerService {

  constructor(
    private basicHttpClientService: BasicHttpClientService,
    private enviromentContextService: EnviromentContextService
  ) {
    super();
  }

  public getContentFromFather(id: string): Observable<RootObject> {
    const params = new HttpParams({
      fromObject: {
        company: this.enviromentContextService.getCompany() as string,
        "$filter": `padre eq '${id}'`,
        "$expand": "contenidoContenedores"
      }
    });
    return this.basicHttpClientService.get("contenedores", params)
  }
}
