import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { AuthorizationTokenService } from 'src/app/security/services/authorization-token.service';
import { ApiConfigService } from './api-config.service';

@Injectable()
export class SecurityBasicHttpClientService {

  constructor(
    private apiConfigService: ApiConfigService,
    private httpClient: HttpClient,
    private authorizationTokenService: AuthorizationTokenService
  ) { }

  private apiCall<T>(fragment: string, callback: (url: string, headers: HttpHeaders) => Observable<T>): Observable<T> {
    const url = this.apiConfigService.getUrl(fragment);
    return this.authorizationTokenService.getToken().pipe(
      mergeMap(token => {
        let headers = new HttpHeaders();
        headers = headers.set("Authorization", `Bearer ${token}`);
        return callback(url, headers);
      })
    )
  }

  public get<T>(fragment: string, params?: HttpParams): Observable<T> {
    return this.apiCall<T>(fragment, (url: string, headers: HttpHeaders) =>
      this.httpClient.get<T>(url, { headers, responseType: 'json', params },)
    )
  }

  public post<TBody, T>(fragment: string, body: TBody, params?: HttpParams): Observable<T> {
    return this.apiCall<T>(fragment, (url: string, headers: HttpHeaders) =>
      this.httpClient.post<T>(url, body, { headers, responseType: 'json', params },)
    )
  }
  public put<TBody, T>(fragment: string, body: TBody, params?: HttpParams): Observable<T> {
    return this.apiCall<T>(fragment, (url: string, headers: HttpHeaders) =>
      this.httpClient.put<T>(url, body, { headers, responseType: 'json', params },)
    )
  }
}
