import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryActionHistoryComponent } from './delivery-action-history/delivery-action-history.component';
import { MatListModule } from '@angular/material/list';
import { NgPipesModule } from 'ngx-pipes';
import { DeliveryActionRespositoryService } from './services/delivery-action-respository.service';



@NgModule({
  declarations: [
    DeliveryActionHistoryComponent
  ],
  imports: [
    CommonModule,
    MatListModule,
    NgPipesModule
  ],
  exports: [
    DeliveryActionHistoryComponent
  ]
})
export class DeliveryActionsModule { 
  static forRoot(): ModuleWithProviders<DeliveryActionsModule> {
    return {
      ngModule: DeliveryActionsModule,
      providers: [
        DeliveryActionRespositoryService
      ]
    };
  }
}
