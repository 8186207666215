import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SecurityBasicHttpClientService } from './services/security-basic-http-client.service';
import { BasicHttpClientService } from './services/basic-http-client.service';
import { ODataBasicHttpClientService } from './services/o-data-basic-http-client.service';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ]
})
export class ApiModule {
  static forRoot(): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        SecurityBasicHttpClientService,
        { provide: BasicHttpClientService, useClass: ODataBasicHttpClientService },
        environment.apiInterceptor,
      ]
    };
  }
}
