<div class="wrapper">
    <form [formGroup]="form">
        <p>
            <mat-form-field class="content" appearance="standard">
                <mat-label>User Name</mat-label>
                <mat-icon matSuffix>person</mat-icon>
                <input matInput appAutofocus #userName placeholder="User name" formControlName="userId" data-test-id="userId" tabindex="0" (keydown.enter)="password.focus()" (keydown.tab)="password.focus()"/>
                <mat-error *ngIf="form.controls.userId.errors?.['required']">Required</mat-error>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field class="content" appearance="standard">
                <mat-label>Password</mat-label>
                <mat-icon matSuffix>key</mat-icon>
                <input matInput #password placeholder="Password" formControlName="password" data-test-id="password" tabindex="1" (keydown.enter)="onSubmit()" (keydown.tab)="onSubmit()"/>
                <mat-error *ngIf="form.controls.password.errors?.['required']">Required</mat-error>
            </mat-form-field>
        </p>
    </form>
    <button mat-stroked-button color="primary" (click)="onSubmit()" data-test-id="aceptaruserId" tabindex="2" [appDisabledOnWorking]="progres$"
        [disabled]="form.invalid" tabindex="2">Ok</button>
</div>