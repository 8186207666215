import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './layout/page-not-found/page-not-found.component';
import { EnviromentGuard } from './enviroment/guard/enviroment.guard';
import { AuthenticationGuard } from './security/guard/authentication-guard.guard';

const routes: Routes = [
  { path: 'manage', loadChildren: () => import('./picking/manage-pick/manage-pick.module').then(m => m.ManagePickModule), canActivate: [EnviromentGuard, AuthenticationGuard] },
  { path: 'unitaryPick', loadChildren: () => import('./picking/unitary-pick/unitary-pick.module').then(m => m.UnitaryPickModule), canActivate: [EnviromentGuard, AuthenticationGuard] },
  { path: 'batchPick', loadChildren: () => import('./picking/batch-pick/batch-pick.module').then(m => m.BatchPickModule), canActivate: [EnviromentGuard, AuthenticationGuard] },
  { path: 'page', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [EnviromentGuard, AuthenticationGuard] },
  { path: 'package', loadChildren: () => import('./package/package.module').then(m => m.PackageModule), canActivate: [EnviromentGuard, AuthenticationGuard] },
  { path: 'incident', loadChildren: () => import('./incident/incident.module').then(m => m.IncidentModule), canActivate: [EnviromentGuard, AuthenticationGuard]},
  { path: 'user', loadChildren: () => import('./security/security.module').then(m => m.SecurityModule), canActivate: [EnviromentGuard] },
  { path: 'security', loadChildren: () => import('./security-passage-passkey/security-passage-passkey.module').then(m => m.SecurityPassagePasskeyModule) },
  { path: 'environment', loadChildren: () => import('./enviroment/enviroment.module').then(m => m.EnviromentModule) },
  { path: 'device', loadChildren: () => import('./device/device.module').then(m => m.DeviceModule)},
  // { path: '**', 
  //   title: 'Pagina no encontrada',
  //   component: PageNotFoundComponent },  // Wildcard route for a 404 page
  { path: '**', redirectTo: 'enviroment/config'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
