import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class SpinnerService {

  private showCount = new Map<string, number>();
  constructor(
    private spinner: NgxSpinnerService,
  ) { }

  private getShowCount(name: string): number {
    let count = this.showCount.get(name);
    if (count === undefined) {
      count = 0;
      this.showCount.set(name, count);
    }
    return count;
  }

  public show(name: string = ''): void {
    let count = this.getShowCount(name) + 1;
    this.showCount.set(name, count);
    if (count === 1) {
      this.spinner.show();
    }
  }

  public hide(name: string = ''): void {   
    let count = this.getShowCount(name);
    if (count > 0) {
      count--;
      this.showCount.set(name, count);
      if (count === 0) {
        this.spinner.hide();
      }
    }
  }
}
