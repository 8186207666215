import { immerable } from "immer";

export class BoxContentItem {
    [immerable] = true
    public IdItem!: string;
    public orderId!: string;
    public sku! : string;  
    public boxType: string = '';
    public count: number = 0;
    public boxId! : string;
    public closed: boolean = false;
    
    constructor(orderId: string, sku: string, count: number, boxId: string, boxType: string, closed: boolean) {
        this.orderId = orderId;
        this.sku = sku;
        this.count = count;
        this.boxId = boxId;
        this.boxType = boxType;
        this.closed = closed;
        this.updateId();
    }

    public updateId(): void {
        this.IdItem = BoxContentItem.composeId(this.sku, this.boxId);
    }
    public static composeId(sku: string, boxId: string): string {
        return `${sku}_${boxId}`;
    }
}