import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerService } from './services/container.service';
import { ContainerMockService } from './services/container-mock.service';
import { ContainerApiService } from './services/container-api.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    ContainerApiService,
    { provide: ContainerService, useClass: ContainerMockService},
    
  ]
})
export class ContainerModule { }
