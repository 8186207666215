import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPageComponent } from '../layout/main-page/main-page.component';
import { TokenSecurityInitComponent } from './token-security-init/token-security-init.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
    children: [
      { path: 'init', component: TokenSecurityInitComponent },
      { path: 'login', component: LoginComponent },
      { path: 'logout', component: LogoutComponent}
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SecurityRoutingModule { }
