import { Injectable } from '@angular/core';
import { Navigator, HIDConnectionEvent, HIDDevice } from '../model/hid';
import { Observable, from, fromEvent, map, mergeMap, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HidService {

  constructor() { }

  public onConnect(): Observable<HIDDevice> {
    const navigatorClean = (navigator as any) as Navigator;
    return fromEvent<HIDConnectionEvent>(navigatorClean.hid, 'connect').pipe(
      map(event => { return event.device; })
    )
  }

  public onDisconnect(): Observable<HIDDevice> {  
    const navigatorClean = (navigator as any) as Navigator;
    return fromEvent<HIDConnectionEvent>(navigatorClean.hid, 'disconnect').pipe(
      map(event => { return event.device; })
    )
  }

  public preCheck(): boolean {
    const navigatorClean = (navigator as any) as Navigator;
    return navigatorClean.hid !== undefined && window.isSecureContext;
  }

  public getDevice(vendorId: number): Observable<HIDDevice | undefined> {
    if (!this.preCheck()) {  //if the browser does not support the HID API
      return throwError(() => new Error('HID API not supported'));
    }
    const navigatorClean = (navigator as any) as Navigator;
    let result$ = from(navigatorClean.hid.getDevices()).pipe(
      mergeMap(devices => {
        let device = devices.find(d => d.vendorId === vendorId);
        if (device !== undefined) {
          return of(device);
        } else {
          try {
            console.log('Request device'  )
            const requestDevice = navigatorClean.hid.requestDevice({ filters: [{ vendorId: vendorId }] })
              .catch(e => {
                return [];
              });
            return from(requestDevice).pipe(map(deviceList => deviceList.length === 0 ? undefined : deviceList[0]))
          } catch (e) {
            return throwError(() => new Error('Failed request device ' + e));
          }
        }
      }));

    return result$;
  }

  public openDevice(device: HIDDevice | undefined): Observable<HIDDevice | undefined> {
    if (device === undefined || (device !== undefined && device.opened)) {
      return of(device);
    } else {
      return from(device.open()).pipe(map(() => device));
    }
  }
}
