import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, catchError, from, lastValueFrom, map, Observable, of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { Order } from 'src/app/order/model/order';
import { OrderLine } from 'src/app/order/model/order-line';
import { OrderService } from 'src/app/order/services/order.service';
import { PackageOrderService } from '../services/package-order.service';
import { OrderLinePackedVoKey } from '../model/order-line-packed-vo-key';
import { OrderTableStyle } from '../model/order-table-style';
import { OrderUtilHelper } from 'src/app/order/util/order-util-helper';
import { OrderKey } from 'src/app/order/model/order-key';
import { OrderRepository } from 'src/app/order/services/state/order.repository';
import { ScanToBoxService } from '../services/scan-to-box.service';
import { BoxContentItem } from '../model/box-content-item';
import { Box } from '../model/box';
import { TextNotificationService } from 'src/app/common-app/services/text-notification.service';
import { OrderHelper } from 'src/app/order/services/order-helper';
import { OrderLineStats } from 'src/app/order/model/order-line-stats';
import { OrderPackingOperationsService } from 'src/app/packaging-process/services/order-packing-operations.service';
import { PickType } from 'src/app/picking/manage-pick/model/pick-type';
import { BoxRepository } from '../services/state/box.repository';
import { BoxModelService } from '../services/box-model.service';
import { BoxModel } from '../model/box-model';
import { BoxPacked } from '../model/box-packed';
import { InternalError } from 'src/app/common-app/model/internal-error';
import { OrderState } from 'src/app/order/model/order-state';
import { MatDialog } from '@angular/material/dialog';
import { WeightDialogComponent, WeightDialogData } from '../weight-dialog/weight-dialog.component';
import { DocumentPrintService } from 'src/app/document/services/document-print.service';
import { LogisticDocument } from 'src/app/document/model/logistic-document';
import { OrderDocumentService } from 'src/app/order/services/order-document.service';
import { PlaySoundService } from 'src/app/common-app/services/play-sound.service';
import { OrderLinePackedVoService } from '../services/order-line-packed-vo.service';
import { OrderPackedState } from '../model/order-packed-state';
import { PackedBoxService } from '../services/packed-box.service';
import { BoxModelSelectorService } from 'src/app/box/services/box-model-selector.service';
import { PackagingProcessService } from 'src/app/packaging-process/services/packaging-process.service';
import { PackageProcessState } from 'src/app/packaging-process/model/package-process-state';
import { EnviromentContextService } from 'src/app/enviroment/services/enviroment-context-service';
import { ErrorHistoryService } from 'src/app/common-app/services/error-history.service';
import { DocumentPrintDialogComponent } from 'src/app/document/components/document-print-dialog/document-print-dialog.component';
import { DocumentContentStatus } from 'src/app/document/model/document-content-status';
import { SpinnerService } from 'src/app/common-app/services/spinner.service';
import { DocumentLocation } from 'src/app/document/model/document-location';
import { ErrorService } from 'src/app/common-app/services/error.service';
import { ConfirmDialogData } from 'src/app/common-app/confirm-dialog/confirm-dialog-data';
import { ConfirmDialogComponent } from 'src/app/common-app/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-package-order',
  templateUrl: './package-order.component.html',
  styleUrls: ['./package-order.component.scss'],
  providers: [SpinnerService]
})
export class PackageOrderComponent implements OnInit, OnDestroy {

  private orderId: OrderKey | undefined;
  private onlyView: boolean = false;
  protected order: Order | undefined;
  protected orderLineList: OrderLine[] | undefined;
  protected order$: Observable<Order | undefined> = of(undefined);
  protected packedState: OrderPackedState = new OrderPackedState();
  protected packedState$: Subject<OrderPackedState> = new Subject<OrderPackedState>();
  protected selectedItem: OrderLine | undefined;
  protected boxModelId$: BehaviorSubject<Box | undefined>;
  protected destroy$ = new Subject<void>();
  protected onBackWorking$ = new Subject<void>();
  protected addToBoxSubject: Subject<string> | undefined;
  protected progres$ = new Subject<void>();

  protected stats: OrderLineStats | undefined;

  protected lineKey: OrderLinePackedVoKey | undefined;

  protected orderTableStyle = OrderTableStyle;
  protected spinnerNameCloseBox = 'closeBoxSpinner';
  protected isCloseDisabled = true;
  protected isRestartDisabled = true;
  protected isInstructionsDisabled = true;
  protected isPrintDisabled = true;
  protected isIncidentDisabled = false;
  protected isScanDisabled = false;

  protected hideIncidentBadge = true;
  protected incidentNumber = 0;
  private packageProcessState: PackageProcessState | undefined;

  constructor(
    private orderService: OrderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private textNotificationService: TextNotificationService,
    private packageOrderService: PackageOrderService,
    private errorService: ErrorService,
    private boxRepository: BoxRepository,
    private orderRepository: OrderRepository,
    private boxModelService: BoxModelService,
    private orderPackingOperationsService: OrderPackingOperationsService,
    private scanToBoxService: ScanToBoxService,
    private dialog: MatDialog,
    private documentPrintService: DocumentPrintService,
    private orderDocumentService: OrderDocumentService,
    private playSoundService: PlaySoundService,
    private orderLinePackedVoService: OrderLinePackedVoService,
    private packedBoxService: PackedBoxService,
    private boxModelSelectorService: BoxModelSelectorService,
    private packagingProcessService: PackagingProcessService,
    private enviromentContextService: EnviromentContextService,
    private errorHistoryService: ErrorHistoryService,
    private spinner: SpinnerService
  ) {
    this.boxModelId$ = new BehaviorSubject<Box | undefined>(undefined);
    this.boxModelId$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(_data => {
      this.configButton();
      this.configIncident();
      if (this.order !== undefined) {
        this.loadState(this.order);
      }
    })

  }

  private cleanup() {
    this.destroy$.next();
    this.destroy$.complete();
    this.packageOrderService.resetBox();
  }
  ngOnDestroy(): void {
    this.cleanup();
    this.onBackWorking$.complete();
    this.onBackWorking$.unsubscribe();
    this.progres$.complete();
    this.progres$.unsubscribe();
  }

  ngOnInit(): void {
    this.packageProcessState = this.packagingProcessService.getActive();
    this.activatedRoute.paramMap.subscribe(params => {
      this.onlyView = params.get('onlyView') !== null ? params.get('onlyView') === 'true' : false;
      this.autoSelectSku(this.packageProcessState?.pickType);
      this.orderId = OrderUtilHelper.getOrderKey(params);
      const paramBoxModelId = params.get('boxModelId');
      let action: Observable<Box | undefined>;
      if (paramBoxModelId !== null) {
        this.orderId = OrderHelper.convertToOrderKey(this.orderRepository.getActiveOrder());
        const changeBoxType = params.get('action') === 'changeBoxType';
        action = this.updateBox(paramBoxModelId, changeBoxType, params.get('boxId'));
      } else {
        this.boxModelId$.next(undefined);
        action = of(undefined);
      }
      action.pipe(take(1)).subscribe(_data => {
        this.loadData(this.orderId);
        const backFromSelectBox = this.isBackFromOther();
        if (backFromSelectBox) {
          this.scanToBoxService.completeLast();
        } else {
          this.checkScanPending();
        }
      });
    });
  }

  private isBackFromSelectBox(): boolean {
    return this.activatedRoute.snapshot.paramMap.get('action') === 'backSelectBox';
  }
  private isBackFromDeclareIncident(): boolean {
    return this.activatedRoute.snapshot.paramMap.get('action') === 'backDeclareIncident';
  }
  private isBackFromOther(): boolean {
    return this.isBackFromDeclareIncident() || this.isBackFromSelectBox();
  }
  public autoSelectSku(pickType: PickType | undefined): void {
    this.packedState$.pipe(take(1)).subscribe((data) => {
      if (pickType !== undefined && !this.isBackFromOther()) {
        switch (pickType) {
          case PickType.Unitary:
            if (data.orderLineList !== undefined && data.orderLineList.length === 1) {
              if (!this.onlyView) {
                const sku = data.orderLineList[0].sku;
                this.onScan(sku);
              }
            }
            break;
          default:
            break;
        }
      }
    });

  }

  private updateBox(boxModelId: string, changeBoxType: boolean, boxId: string | null = null): Observable<Box | undefined> {
    let action: Observable<Box | undefined> = of(undefined);

    if (this.orderId !== undefined && this.orderId.slotId !== undefined) {
      const order = this.orderService.getOrder(this.orderId);
      if (order !== undefined) {
        let basicAction: Observable<Box | undefined>;
        if (changeBoxType && boxId !== null) {
          basicAction = this.packageOrderService.changeBoxType(boxId, boxModelId).pipe(catchError(error => {
            this.textNotificationService.error('Not possible change box type');
            return of(undefined);
          }));
        } else {
          basicAction = this.packageOrderService.updateBoxType(boxModelId, this.orderId.slotId);
        }
        action = basicAction.pipe(
          take(1),
          tap(data => {
            this.boxModelId$.next(data);
            this.boxModelSelectorService.registerModelFromOrder(order);
          }));
      }
    }
    return action;
  }

  private configIncident() {
    if (this.order !== undefined) {
      this.incidentNumber = 0;
      if (this.order.lines !== undefined) {
        for (const iterator of this.order.lines) {
          if (iterator.incidences !== undefined && iterator.incidences.length > 0) {
            this.incidentNumber += 1;
          }
        }
      }
      if (this.order.incident !== undefined && this.order.incident.length > 0) {
        this.incidentNumber += 1;
      }
      this.hideIncidentBadge = this.incidentNumber === 0;
    }

  }

  private hasBoxOpen(): boolean {
    let result = false;
    if (this.boxModelId$.value !== undefined) {
      result = true;
    } else {
      if (this.order !== undefined && this.order.boxLines !== undefined && this.order.boxLines.find(i => !i.closed) !== undefined) {
        result = true;
      }
    }

    return result;
  }

  private configButton() {
    this.isCloseDisabled = !this.hasBoxOpen();
    if (this.order !== undefined) {
      if (this.order.status === OrderState.Packed || this.order.status === OrderState.Incidence) {
        this.isInstructionsDisabled = true;
        this.isPrintDisabled = false;
        this.isRestartDisabled = false;
      } else {
        this.isInstructionsDisabled = this.order.instructions.length === 0;
        this.isPrintDisabled = this.order.boxLines.length === 0;
        this.isRestartDisabled = this.order.boxLines.length === 0;
      }
    }
  }

  private checkScanPending() {
    const code = this.scanToBoxService.getLastUnCompleted();
    if (code !== undefined) {
      this.addToBox(code);
    }
  }

  private loadOrderCompletData(order: Order | undefined) {
    if (order !== undefined) {
      this.loadOrderData(order);
      this.loadBox(order);
      this.loadState(order);
    }
  }


  private loadData(orderId: OrderKey | undefined) {
    this.order$ = this.orderRepository.activeOrder$.pipe(
      map(data => data === undefined ? undefined : data[0]),
      tap(data => {
        this.orderId = OrderHelper.convertToOrderKey(data);
        this.loadOrderCompletData(data);
      }),
      takeUntil(this.destroy$)
    );
    if (orderId !== undefined) {
      this.order$ = this.orderService.selectOrder(orderId, true).pipe(takeUntil(this.destroy$));
    }

    if (orderId !== undefined) {
      const order = this.orderService.getOrder(orderId);
      this.loadOrderCompletData(order);
    } else {
      this.orderRepository.activeOrderComplet$
        .pipe(
          take(1),
          tap(data => {
            this.spinner.show();
            this.loadOrderCompletData(data);
          })
        )
        .subscribe({
          next: (_data) => { this.spinner.hide(); },
          error: (error) => { this.spinner.hide(); },
          complete: () => { this.spinner.hide(); }
        });
    }
  }

  private autoSelectBoxType(order: Order): Observable<Box | undefined> {
    const boxModelId = this.boxModelSelectorService.selectModel(order);
    if (boxModelId !== undefined && order.lines.reduce((prev, current) => prev + current.pending, 0) > 0) {
      return this.updateBox(boxModelId, false).pipe(
        tap(data => { this.loadBox(order); })
      );
    } else {
      return of(undefined);
    }
  }
  private loadBox(order: Order | undefined) {
    let box: Box | undefined;
    if (order !== undefined && order?.boxLines !== undefined && order?.boxLines.length > 0) {
      let closed = true;
      let boxContent: BoxContentItem | undefined = undefined;
      for (let index = 0; index < order?.boxLines.length && closed; index++) {
        boxContent = order?.boxLines[index];
        box = this.boxRepository.getEntity(boxContent.boxId);
        if (box === undefined) {
          closed = boxContent.closed;
        } else {
          closed = box.closed;
        }
      }
      if (!closed && boxContent !== undefined) {
        if (box === undefined) {
          box = new Box();
          box.boxModelId = boxContent.boxType;
          box._id = boxContent.boxId;
          box.closed = boxContent.closed;
        }
        this.packageOrderService.setActiveBoxType(boxContent.boxId, boxContent.boxType, closed).pipe(take(1))
          .subscribe(_data => { });
      }
    }
    if (box === undefined) {
      box = this.packageOrderService.getActiveBox();;
    }
    if (box !== undefined) {
      if (box.closed) {
        this.boxModelId$.next(undefined);
      } else {
        if (this.boxModelId$.value === undefined || this.boxModelId$.value._id !== box._id) {
          this.boxModelId$.next(box);
        }
      }
    } else {
      this.boxModelId$.next(undefined);
    }
    if (this.boxModelId$.value === undefined && order !== undefined) {
      this.autoSelectBoxType(order).pipe(take(1)).subscribe(_data => { });
    }
  }

  private loadState(orderData: Order | undefined) {
    if (orderData !== undefined) {
      this.packedState = this.orderLinePackedVoService.convertOrderToLinePackedVoList(orderData, this.boxModelId$.value);
      this.packedState$.next(this.packedState);
    }
  }

  private loadOrderData(orderData: Order) {
    this.orderId = OrderHelper.convertToOrderKey(orderData);
    this.order = orderData;
    this.orderLineList = orderData.lines;
    this.loadState(orderData);
    this.stats = this.orderService.calculateStats(orderData?.lines);
    this.configButton();
    this.configIncident();
    this.isIncidentDisabled = orderData.status === OrderState.Packed;
    this.isScanDisabled = orderData.status === OrderState.Packed;
  }

  private pullLine(orderId: OrderKey, line: OrderLine, box: Box | undefined): Observable<string | undefined> {
    if (box !== undefined && orderId !== undefined) {
      return this.packageOrderService
        .pullItem(orderId, box.boxModelId, line.sku, line.barCode !== undefined ? line.barCode : [], 1)
        .pipe(
          take(1),
        );
    } else {
      return of(undefined);
    }
  }


  private updateOrderLine(barCodeOrSku: string): Observable<OrderLine | undefined> {
    if (this.orderId !== undefined) {
      this.scanToBoxService.scan(barCodeOrSku, true);
      const box = this.packageOrderService.getActiveBox();
      return this.orderPackingOperationsService.updateOrderLine(this.orderId, barCodeOrSku, box, -1, this.pullLine.bind(this))
        .pipe(
          take(1),
          tap(data => {
            this.lineKey = data;
          }),
          map(data => data?.line)
        );
    } else {
      return of(undefined);
    }
  }

  private checkIfBoxIsSelect(barCode: string): Observable<string | undefined> {
    const boxModel = this.packageOrderService.getBoxModel();
    if (boxModel === undefined) {
      return this.findBoxModel(barCode).pipe(map(data => data?.id));
    } else {
      return of(boxModel)
    }
  }

  private notificationForAddItem(lines: OrderLine[], lineWitPending: OrderLine | undefined) {
    if (lines.length > 0) {
      if (lineWitPending === undefined) {
        this.textNotificationService.text('Collected all for this element');
      }
    } else {
      this.playSoundService.playSound('beep');
      this.textNotificationService.text('Bar code not found');
    }
  }

  private addSkuToBox(barCodeOrSku: string, lineToInspect: OrderLine[]) {
    this.spinner.show();
    const line = lineToInspect.find(i => i.pending > 0);
    this.notificationForAddItem(lineToInspect, line);
    if (line !== undefined) {
      this.checkIfBoxIsSelect(barCodeOrSku).pipe(
        take(1),
        switchMap(dataModelBox => {
          if (dataModelBox !== undefined) {
            return of(dataModelBox);
          } else {
            return this.autoSelectBoxType(this.order as Order).pipe(take(1), map(data => data?.boxModelId));
          }
        })
      )
        .subscribe(dataModelBox => {
          if (dataModelBox !== undefined) {
            this.scanToBoxService.completeLast();
            this.updateOrderLine(barCodeOrSku).pipe(take(1)).subscribe({
              next: data => {
                const order = this.orderRepository.getActiveOrder();
                this.loadState(order);
                this.loadOrderCompletData(order);
                this.spinner.hide();
                if (data !== undefined) {
                  this.textNotificationService.text(`Add ${barCodeOrSku}`);
                }
              },
              error: (error: Error) => {
                this.spinner.hide();
                if (error.name === 'ERROR01') {
                  // TODO intenacionalizar
                  this.textNotificationService.error('Collected all for this element');
                } else {
                  this.showGeneralNotificationError(error);
                }
              }
            });
          } else {
            this.scanToBoxService.scan(barCodeOrSku, false);
            this.onSelectBoxType();
          }
        });
    } else {
      this.spinner.hide();
    }
  }

  private findBoxModel(boxModelId: string): Observable<BoxModel | undefined> {
    return this.boxModelService.getBoxModel().pipe(
      map(data => {
        let found: BoxModel | undefined;
        if (data !== null) {
          for (const iterator of data.boxModel) {
            for (const box of iterator.boxList) {
              if (box.id === boxModelId) {
                found = box;
              }
            }
          }
        }
        return found;
      }),
      take(1),
    );
  }

  private selectBox(boxModel: BoxModel | undefined) {
    const box = this.packageOrderService.getActiveBox();
    if (box !== undefined) {
      this.textNotificationService.error('Close the active box before');
    } else {
      this.spinner.show();
      if (boxModel !== undefined) {
        this.updateBox(boxModel.id, false).subscribe({
          next: data => {
            this.spinner.hide();
            if (data !== undefined) {
              this.textNotificationService.text('Box selected');
            }
          },
          error: (error: Error) => {
            this.spinner.hide();
            this.showGeneralNotificationError(error, 'Select box error');
          }
        })
      }
    }
  }

  private addToBox(barCode: string): void {
    // TODO mejora de uso de @ngneat/until-destroy

    const barCodeOrSku = barCode.trim().toUpperCase();
    this.textNotificationService.clean();
    const orderKey = OrderHelper.convertToOrderKey(this.orderRepository.getActiveOrder());
    const lineList = this.orderService.getLine(orderKey, barCodeOrSku);
    if (lineList.length > 0) {
      this.addSkuToBox(barCodeOrSku, lineList);
    } else {
      this.findBoxModel(barCodeOrSku).pipe(take(1)).subscribe(data => {
        if (data !== undefined) {
          this.selectBox(data);
        } else {
          this.playSoundService.playSound('beep');
          this.textNotificationService.error(`SKU ${barCodeOrSku} not found`);
        }
      });
    }
  }

  public onScan(barCode: any) {
    if (this.checkComand(barCode)) {
      this.executeComand(barCode);
    } else {
      const cleanBarCode = (barCode as string).trim();
      this.addToBox(cleanBarCode);
    }
  }

  private checkComand(data: string): boolean {
    if (data !== undefined) {
      const cleanData = data.trim().toUpperCase();
      return cleanData.startsWith('##') && cleanData.endsWith('#');
    } else {
      return false;
    }
  }
  private executeComand(data: string) {
    if (data !== undefined) {
      const cleanData = data.trim().toUpperCase();
      switch (cleanData) {
        case '##CLOSE#':
          this.onCloseBox();
          break;
        default:
          this.textNotificationService.error('Command not recognized');
          break;
      }
    }
  }

  private pendingLineCount(): number {
    let result = 0;
    if (this.orderLineList !== undefined) {
      for (const iterator of this.orderLineList) {
        if (iterator.pending > 0) {
          result += 1;
        }
      }
    }
    return result;
  }

  private updateStatus(orderKey: OrderKey, boxPacked: BoxPacked) {
    if (boxPacked.shipingList.length > 0) {
      let status = boxPacked.shipingList[0].status;
      if (status === undefined || status === OrderState.Unknow) {
        const pendingCount = this.pendingLineCount();
        if (pendingCount === 0) {
          status = OrderState.Packed;
        } else {
          status = OrderState.Packing;
        }
      }
      this.orderService.updateStatus(orderKey, status);
    }
  }


  protected getBoxWeight(): Observable<number | undefined> {
    const dialogRef = this.dialog.open(WeightDialogComponent, {
      data: { weight: 0 } as WeightDialogData,
    });

    return dialogRef.afterClosed().pipe(map(data => data as number));
  }

  protected executeCloseBox(): void {
    let actionRequestWeight: Observable<number | undefined> = of(0);
    if (this.enviromentContextService.getRequestWeight()) {
      actionRequestWeight = this.getBoxWeight();
    }
    actionRequestWeight.pipe(take(1))
      .subscribe(dataWeight => {
        if (dataWeight !== undefined) {
          this.processCloseBox(dataWeight);
        } else {
          this.textNotificationService.error('Weight not exist');
        }
      });
  }

  protected onCloseBox(): void {
    if (this.boxModelId$.value !== undefined && this.packedBoxService.boxIsIncidence(this.boxModelId$.value?._id)) {
      this.processCloseBox(0);
    } else {
      if (!this.isLastBox()) {
        const dataDialog = new ConfirmDialogData();
        // TODO internacionalizar
        dataDialog.message = 'There are still items pending, do you want to close the box anyway?';
        const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dataDialog });
        dialogRef.afterClosed().pipe(take(1)).subscribe((result: boolean) => {
          if (result) {
            this.executeCloseBox();
          } else {
            this.progres$.next();
          }
        });
      } else {
        this.executeCloseBox();
      }


    }
  }

  protected onRestartBox(): void {
    if (this.orderId !== undefined) {
      this.packageOrderService.restartBoxOrder(this.orderId).pipe(
        switchMap(_data => {
          this.packageOrderService.resetBox();
          return this.orderService.selectOrder(this.orderId as OrderKey, true);
        }),
        take(1))
        .subscribe({
          next: data => {
            this.loadOrderCompletData(data);
            this.textNotificationService.text('Box restarted');
            this.progres$.next();
          },
          error: (error: Error) => {
            this.showNotificationError(error);
            this.progres$.next();
          }
        });
    } else {
      this.textNotificationService.error('Order not selected');
      this.progres$.next();
    }
  }

  private convertBoxPacketToLogisticDocument(box: BoxPacked): LogisticDocument[] {
    return box.shipingList.map(i =>
      i.documents.map(d => {
        const result = new LogisticDocument();
        // TODO pendiente de fijar el tipo
        result.url = d.url;
        result.type = d.type;
        result.description = `Box ${box.boxId}`
        result.documentoLocation = DocumentLocation.Box;
        return result;
      })).flat();
  }

  private showNotificationError(error: Error) {
    let text: string | undefined = undefined;
    if (error instanceof InternalError) {

      switch (error.name) {
        case 'PRINTPDF_0001':
          text = 'Failed get documents';
          break;
        case 'PRINTPDF_0002':
          text = 'Failed print documents';
          break;
        case 'CLOSEBOX_1':
          text = 'Box not selected';
          break;
        case 'CLOSEBOX_2':
          text = 'Call to backend failed in close box';
          break;
        default:
          text = 'Failed general print documents';
          break;
      }
    }
    this.showGeneralNotificationError(error, text);
  }

  private showGeneralNotificationError(error: Error, message: string | undefined = undefined) {
    const text = this.errorService.friendlyError(error, message ?? error.message);
    this.textNotificationService.error(text);
    this.errorHistoryService.registerError(error.name, text, error);
  }

  private boxIsEmpty(): boolean {
    let result = true;
    if (this.boxModelId$.value !== undefined) {
      result = this.packedState.orderLineList.filter(i => i.boxList.filter(b => b.boxId === this.boxModelId$.value?._id).length > 0).length === 0;
    }
    return result;
  }

  private isLastBox(): boolean {
    this.stats = this.orderService.calculateStats(this.orderLineList);
    return this.stats !== undefined ? this.stats.pending === 0 : false;
  }

  private processCloseBox(dataWeight: number) {
    const lastBox = this.isLastBox();
    if (this.boxModelId$.value !== undefined) {
      if (this.boxIsEmpty()) {
        this.textNotificationService.error('Box is empty');
      } else {
        this.spinner.show(this.spinnerNameCloseBox);
        const orderKey = this.orderId as OrderKey;
        this.packageOrderService.closeBox(orderKey, dataWeight, lastBox).pipe(
          switchMap(data => {
            this.updateStatus(orderKey, data);
            if (this.orderId !== undefined) {
              const order = this.orderService.getOrder(this.orderId);
              this.loadBox(order);
              if (order !== undefined) {
                this.boxModelSelectorService.registerModelFromOrder(order);
              }
            }
            this.textNotificationService.text('Box closed');
            const documents = this.convertBoxPacketToLogisticDocument(data);
            return this.executePrint(documents, true);
          }
          ),
          take(1)
        ).subscribe({
          next: (data) => {
            // TODO internacionalizar
            this.spinner.hide(this.spinnerNameCloseBox);
            if (lastBox) {
              this.navigateSelectSlot();
            }
            this.progres$.next();
          },
          error: (error: Error) => {
            this.spinner.hide(this.spinnerNameCloseBox);
            this.showNotificationError(error);
            this.progres$.next();
            // TODO añadir opciones para deshacer el cambio
            // this.undoLastUpdateOrderLine().pipe(take(1)).subscribe({});
          }
        }
        );
      }
    } else {
      this.textNotificationService.text('Box not selected');
      if (lastBox) {
        this.navigateSelectSlot();
      }
    }
  }

  protected onChangeBoxType(): void {
    this.router.navigate(['package/selectBox', { action: 'changeBoxType', boxId: this.boxModelId$.value?._id }]);
    this.progres$.next();
  }

  protected onSelectBoxType(): void {
    this.router.navigate(['package/selectBox']);
    this.progres$.next();
  }

  protected onInstructions(): void {
    // TODO
    this.progres$.next();
  }

  protected onViewBox(): void {
    // TODO
  }


  protected executePrint(documents: LogisticDocument[], autoPrint: boolean = false): Observable<void> {
    return this.documentPrintService.fillDocumentList(documents)
      .pipe(
        take(1),
        switchMap(documentList => {
          let result: Observable<void> = of(undefined);
          if (autoPrint && documentList.filter(i => i.documentStatus !== DocumentContentStatus.Ok).length === 0) {
            result = from(this.documentPrintService.printDocumentList(documentList, false)).pipe(
              map(_data => undefined),
            );
          } else {
            const dialog = this.dialog.open(DocumentPrintDialogComponent, {
              data: documentList,
              minHeight: 'calc(100vh - 90px)',
              height: 'auto'
            });
            result = dialog.afterClosed().pipe(take(1));
          }
          return result;
        }),
        catchError(error => {
          this.showNotificationError(error);
          return of(undefined);
        })
      );
  }

  protected async onPrint(): Promise<void> {
    this.spinner.show();
    const orderKey = OrderHelper.convertToOrderKey(this.orderRepository.getActiveOrder());
    try {
      const documents = await lastValueFrom(this.orderDocumentService.selectOrderDocument(orderKey)
        .pipe(take(1)));

      // const itemPrueba = new LogisticDocument();
      // itemPrueba.url = 'https://wsresteu.moddoplatform.com/rest/DocumentsServices/documents?idSite=1392&externalOrderNumber=00100900661576520240504095147_2&documentType=eciCClabel&outputType=pdf';
      // itemPrueba.type = 'FAC';
      // itemPrueba.description = 'Prueba';
      // documents.push(itemPrueba);

      if (Array.isArray(documents) && documents.length > 0) {
        this.executePrint(documents)
          .subscribe({
            complete: () => {
              this.spinner.hide();
              this.progres$.next();
            }
          });
      } else {
        this.textNotificationService.error('Not documents to print');
        this.spinner.hide();
        this.progres$.next();
      }
    } catch (error) {
      this.showNotificationError(error as Error);
      this.spinner.hide();
      this.progres$.next();
    }
  }


  protected onChangeSelected(item: any): void {
    this.selectedItem = item;
  }
  protected onIncident(): void {

    this.router.navigate(['incident/declare']);
    this.progres$.next();
  }

  protected onBack(): void {
    this.navigateSelectSlot();
  }

  protected navigateSelectSlot(): void {
    if (this.packageProcessState?.pickType === undefined) {
      this.textNotificationService.error('Error check navigation back');
    } else {
      this.cleanup();
      switch (this.packageProcessState.pickType) {
        case PickType.Batch:
          this.router.navigate(['/batchPick']);
          break;
        case PickType.Unitary:
          this.router.navigate(['/unitaryPick'])
          break;
        case PickType.Order:
          this.router.navigate(['/manage/packingStation']);
          break;
        default:
          break;
      }
    }
  }
}
