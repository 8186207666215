import { Component, OnInit } from '@angular/core';
import { ErrorHistoryService } from '../services/error-history.service';
import { ErrorEntry } from '../model/error-entry';

@Component({
  selector: 'app-error-history-view-dialog',
  templateUrl: './error-history-view-dialog.component.html',
  styleUrls: ['./error-history-view-dialog.component.scss']
})
export class ErrorHistoryViewDialogComponent implements OnInit {

  errorHistory: ErrorEntry[] = [];
  displayedColumns: string[] = ['code', 'text', 'date', 'errorDetail'];
  

  constructor(
     errorHistoryService: ErrorHistoryService
  ) { 
    this.errorHistory = errorHistoryService.getErrorList();
  }

  ngOnInit(): void {
  }

}
