import { Injectable } from '@angular/core';
import { Observable, forkJoin, map, mergeMap, take } from 'rxjs';
import { BasicHttpClientService } from 'src/app/api/services/basic-http-client.service';
import { OrderPackagingContainerService } from './order-packaging-container.service';
import { ObtenerContenedorEmpaquetadoRequest } from 'src/app/container/model/api/obtener-contenedor-empaquetado-request';
import { ObtenerContenedorEmpaquetadoResponse } from 'src/app/container/model/api/obtener-contenedor-empaquetado-response';
import { EnviromentContextService } from 'src/app/enviroment/services/enviroment-context-service';
import { UserService } from 'src/app/security/services/user-service';
import { OrderApiHelper } from './order-api-helper';
import { OrderContainer } from '../model/order-container';
import { ComposeUrlService } from 'src/app/api/services/compose-url.service';
import { ProductivityService } from 'src/app/productivity/services/productivity.service';

export enum IdType {
  Order,
  Container,
  Both
}

@Injectable({
  providedIn: 'root'
})
export class OrderPackagingContainerApiService implements OrderPackagingContainerService {

  constructor(
    private httpClient: BasicHttpClientService,
    private enviromentContextService: EnviromentContextService,
    private userService: UserService,
    private composeUrlService: ComposeUrlService,
    private productivityService: ProductivityService
  ) { }

  public resetCache(): void {
    throw new Error('Method not implemented.');
  }

  public getOrderPackagingContainerByType(id: string, type: IdType): Observable<OrderContainer | undefined> {
    return forkJoin([
      this.userService.selectUserName().pipe(take(1)),
      this.enviromentContextService.packingStation.pipe(take(1)),
      this.productivityService.selectProductivity().pipe(take(1))])
      .pipe(
        // TODO gestion del error en el caso que no hay usuario o packingstation
        mergeMap(data => {
          const query: ObtenerContenedorEmpaquetadoRequest = {
            "iId": data[2] as number,
            "cUsuarioPDA": data[0] as string,
            "cContenedor": id,
            cPuesto: data[1] as string
          };
          return this.httpClient
            .post<ObtenerContenedorEmpaquetadoRequest, ObtenerContenedorEmpaquetadoResponse>(this.composeUrlService.composeUrl(`CosmosApi_ObtenerContenedorEmpaquetado`), query)
            .pipe(map(data => {
              let result: OrderContainer | undefined = OrderApiHelper.convertOrderContainerResponse(data, id, type);
              return result;
            })
            )
        }
        )
      );
  }

  public getOrderPackagingContainer(packagingContainerId: string): Observable<OrderContainer | undefined> {
    return this.getOrderPackagingContainerByType(packagingContainerId, IdType.Both);
  }
}
