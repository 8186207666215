import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Order } from '../model/order';
import { OrderState } from '../model/order-state';
import { OrderViewHelper } from '../services/order-view-helper';

@Component({
  selector: 'app-order-summary-sheet',
  templateUrl: './order-summary-sheet.component.html',
  styleUrls: ['./order-summary-sheet.component.scss']
})
export class OrderSummarySheetComponent implements OnInit, OnChanges {

  @Input() public order: Order | undefined | null;

  protected orderId: string = "";
  protected containerId: string = "";
  protected waveId: string = "";
  protected serviceType: string = "";
  protected country: string = "";
  protected status: string = "";
  protected slot: string = "";
  protected pickId: string = "";
  protected date: Date | undefined = undefined;
  protected urgent = false;
  protected channel: string = "";
  protected deliveryType: string = "";
  protected carrier: string = "";

  constructor() { }



  ngOnChanges(changes: SimpleChanges): void {
    if (this.order !== null && this.order !== undefined) {
      this.orderId = this.order.id;
      this.waveId = this.order.wave;
      this.pickId = this.order.pickId;
      this.country = this.order.country;
      this.status = OrderViewHelper.convertStatus(this.order.status, this.order.originalStatus);
      this.slot = this.order.slotId !== undefined ? this.order.slotId : "";
      this.containerId = this.order.containerId;
      this.date = this.order.date;
      this.urgent = this.order.urgent;
      this.channel = this.order.channel;
      this.deliveryType = this.order.deliveryType;
      this.carrier = this.order.carrier;
    } else {
      this.orderId = "";
      this.containerId = "";
      this.waveId = "";
      this.country = "";
      this.status = "";
      this.slot = "";
      this.pickId = '';
      this.date = undefined;
      this.urgent = false;
      this.channel = '';
      this.deliveryType = '';
      this.carrier = '';
    }
  }

  ngOnInit(): void {
  }

}
