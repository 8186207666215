import { createStore, emitOnce } from '@ngneat/elf';
import {
  withEntities, selectAllEntities, setEntities, withActiveIds, selectActiveEntities, toggleActiveIds, upsertEntitiesById, addActiveIds,
  resetActiveIds, getAllEntities, deleteAllEntities, entitiesPropsFactory, upsertEntities, updateEntitiesByPredicate, hasEntity
} from '@ngneat/elf-entities';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { withRequestsCache, withRequestsStatus, createRequestsCacheOperator, selectIsRequestCached } from '@ngneat/elf-requests';
import { Injectable } from '@angular/core';
import { BoxContentItem } from '../../model/box-content-item';
import { OrderBoxGroup } from '../../model/order-box-group';
import { Box } from '../../model/box';

const { boxModelEntitiesRef, withBoxModelEntities } = entitiesPropsFactory('boxModel');

@Injectable({ providedIn: 'root' })
export class BoxContentItemRepository {
  activeBoxContentItem$: Observable<BoxContentItem[]>;
  boxContentItem$: Observable<BoxContentItem[]>;

  private store;

  public skipWhileBoxModelCached: <T>(key: 'boxModel', options?: {
    value?: "none" | "partial" | "full" | undefined;
    returnSource?: Observable<any> | undefined;
  } | undefined) => MonoTypeOperatorFunction<T>;

  constructor() {
    this.store = this.createStore();
    this.skipWhileBoxModelCached = createRequestsCacheOperator(this.store);
    this.boxContentItem$ = this.store.pipe(selectAllEntities());
    this.activeBoxContentItem$ = this.store.pipe(selectActiveEntities());
  }

  getContentItem(): BoxContentItem[] {
    return this.store.query(getAllEntities());
  }

  setBoxContentItem(boxContentItem: BoxContentItem[], active: boolean = false) {
    emitOnce(() => {
      this.resetActive();
      this.deleteBoxContentItemAll();
      this.store.update(setEntities(boxContentItem));
      if (active) {
        this.addActiveIds(boxContentItem.map(i => i.IdItem));
      }
    })
  }

  public addContentItem(boxContent: BoxContentItem[], active: boolean = false) {
    emitOnce(() => {
      for (const iterator of boxContent) {
        if (!this.store.query(hasEntity(iterator.IdItem))) {
          this.store.update(upsertEntities(iterator));
        }
      }
      if (active) {
        this.addActiveIds(boxContent.map(i => i.IdItem));
      }
    })
  }

  public deleteBoxContentItemAll() {
    this.store.update(deleteAllEntities());
  }

  toggleActiveIds(ids: Array<BoxContentItem['sku']>) {
    this.store.update(toggleActiveIds(ids));
  }

  addActiveIds(ids: Array<BoxContentItem['sku']>) {
    this.store.update(addActiveIds(ids));;
  }

  public upsert(id: string, count: number, creator: (id: string) => BoxContentItem) {
    this.store.update(
      upsertEntitiesById([id], {
        updater: (entity) => {
          entity.count = entity.count + count;
          return entity
        },
        creator,
      })
    );
  }

  public upsertBoxModelType(boxId: string, boxType: string): void {
    this.store.update(updateEntitiesByPredicate(data => data.boxId === boxId, { boxType }));
  }
  public upsertBoxContent(orderId: string, sku: string, count: number, box: Box) {
    const creator = (id: string) => new BoxContentItem(orderId, sku, 1, box._id, box.boxModelId, false);
    const id = BoxContentItem.composeId(sku, box._id);
    emitOnce(() => {
      this.upsert(id, count, creator.bind(this));
      this.addActiveIds([id]);
    });
  }

  public closeBox(boxId: string) {
    this.store.update(updateEntitiesByPredicate(data => data.boxId === boxId, { closed: true }));
  }

  public resetActive() {
    this.store.update(resetActiveIds());
  }

  public isResquestBoxModelCached(): Observable<boolean> {
    return this.store.pipe(selectIsRequestCached(`boxModel`));
  }

  public selectBoxModel(): Observable<OrderBoxGroup[]> {
    return this.store.pipe(selectAllEntities({ ref: boxModelEntitiesRef }));
  }

  public upsertBoxModel(data: OrderBoxGroup) {
    this.store.update(upsertEntities(data, { ref: boxModelEntitiesRef }));
  }

  private createStore(): typeof store {
    const store = createStore({ name: 'boxContentItem' },
      withEntities<BoxContentItem, 'IdItem'>({ idKey: 'IdItem' }),
      withBoxModelEntities<OrderBoxGroup, 'id'>({ idKey: 'id' }),
      withRequestsCache<'boxModel'>(),
      withActiveIds(), withRequestsStatus<'boxContentItem'>());
    return store;
  }
}
