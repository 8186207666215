import { Injectable } from '@angular/core';
import { Observable, distinctUntilChanged, exhaustMap, filter, from, fromEvent, interval, map, mergeMap, of, throwError } from 'rxjs';
import { Navigator, HIDInputReportEvent, HIDDevice } from '../model/hid';
import { HidService } from './hid.service';



@Injectable({
  providedIn: 'root'
})
export class WeighingScaleService {

  private vendorId = 0x0922;

  constructor(private readonly hidService: HidService) { }

  public getMeasure(): Observable<number> {
    return this.hidService.getDevice(this.vendorId).pipe(
      mergeMap(device => this.hidService.openDevice(device)),
      filter(device => device !== undefined && device.opened),
      mergeMap(device => fromEvent(device as HIDDevice, 'inputreport')),
      map(event => {
        const eventData = (event as any as HIDInputReportEvent).data;
        const i0 = eventData.getUint8(0),
          i1 = eventData.getUint8(1),
          i2 = eventData.getUint8(2),
          i3 = eventData.getUint8(3),
          i4 = eventData.getUint8(4);

        //console.log("i", i0, i1, i2, i3, i4);
        const weight = i3 + (i4 * 256);
        return weight;
      }),

      distinctUntilChanged()
    )
  }

  public onConnect(): Observable<HIDDevice> {
    return this.hidService.onConnect().pipe(filter(device => device.vendorId === this.vendorId));
  }

  public onDisconnect(): Observable<HIDDevice> {
    return this.hidService.onDisconnect().pipe(filter(device => device.vendorId === this.vendorId));
  }
  
  public isOpen(): Observable<boolean> {
    return interval(100).pipe(
      exhaustMap(() => this.hidService.getDevice(this.vendorId)),
      // mergeMap(device => this.hidService.openDevice(device)),
      map(device => device !== undefined && device.opened),
      distinctUntilChanged()
    );
  }
}
