import { Injectable } from '@angular/core';
import { ErrorEntry } from '../model/error-entry';

@Injectable({
  providedIn: 'root'
})
export class ErrorHistoryService {
  private errorList: ErrorEntry[] = [];

  constructor() { }

  registerError(code: string, text: string, errorObject: unknown) {
    const errorEntry: ErrorEntry = {
      code,
      text,
      errorObject,
      date: new Date()
    };

    this.errorList = [errorEntry];
  }

  getErrorList(): ErrorEntry[] {
    return this.errorList;
  }
}

