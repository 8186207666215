import { Injectable } from "@angular/core";
import { Observable, defaultIfEmpty, tap } from "rxjs";
import { Order } from "../model/order";
import { OrderKey } from "../model/order-key";
import { OrderLine } from "../model/order-line";
import { OrderLineStatus } from "../model/order-line-status";
import { OrderApiService } from "./order-api.service";
import { OrderLineHelper } from "./order-line-helper";
import { OrderService } from "./order.service";
import { OrderRepository } from "./state/order.repository";
import { OrderHelper } from "./order-helper";
import { OrderLineStats } from "../model/order-line-stats";
import { Box } from "src/app/package/model/box";
import produce from "immer";
import { OrderState } from "../model/order-state";
import { ArticleIncidentType } from "src/app/incident/model/article-incident-type";
import { OrderIncidence } from "../model/order-incidence";
import { tag } from "rxjs-spy/operators";

@Injectable()
export class OrderCacheService extends OrderService {

    constructor(
        private orderService: OrderApiService,
        private orderRepository: OrderRepository) {
        super();
    }

    private internalGetOrder(id: OrderKey): Observable<Order | undefined> {
        return this.orderService.selectOrder(id).pipe(
            tap(data => {
                if (data !== undefined) {
                    this.orderRepository.upsertOrder(data);
                }
            }),
            this.orderRepository.skipWhileOrderCached(`order-${id.id}`)
        );
    }

    public updateStatus(orderKey: OrderKey, status: OrderState | undefined): void {
        this.orderRepository.updateStatus(orderKey, status);
    }

    public resetCache() {
        this.orderRepository.resetCache();
    }

    public getOrder(orderKey: OrderKey): Order | undefined {
        return this.orderRepository.getById(orderKey.id as string)
    }

    public updateIncident(orderId: OrderKey, newState: OrderState, incidentValue: ArticleIncidentType, barCodeOrSku: string, increment: number) {
        const prepared = this.prepareUpdatePending(orderId, barCodeOrSku, increment * -1);
        if (prepared[1] !== undefined && orderId !== undefined && orderId.id !== undefined) {
            const updated = prepared[1];
            let newIncidences: OrderIncidence[] = [];
            if (prepared[0]?.incidences !== undefined) {
                newIncidences = prepared[0].incidences;
            }
            updated.incidences = produce(newIncidences, (draft) => {
                let found = false;
                for (const iterator of draft) {
                    if (iterator.articleIncidentType === incidentValue) {
                        iterator.count = iterator.count + increment;
                        found = true;
                    }
                }
                if (!found) {
                    draft.push({
                        articleIncidentType: incidentValue,
                        count: increment,
                        originalType: ''
                    })
                }
            });
            this.orderRepository.updateIncident(orderId, newState, [updated]);
        }
        if (prepared[2] !== undefined) {
            throw prepared[2];
        }
    }

    public updateOrderIncident(orderId: OrderKey, newState: OrderState, incidentValue: OrderIncidence) {
        this.orderRepository.updateOrderIncident(orderId, newState, incidentValue);
    }

    public selectOrder(orderKey: OrderKey, initialReset: boolean = false): Observable<Order | undefined> {
        if (initialReset) {
            this.resetCache();
        }
        return this.internalGetOrder(orderKey).pipe(
            tag(`orderCacheService.selectOrder-${orderKey.id}`),
            defaultIfEmpty(this.orderRepository.getById(orderKey.id as string))
        );
    }

    public refreshOrder(orderId: OrderKey): Observable<Order | undefined> {
        return this.selectOrder(orderId, true);
    }

    public newLines(orderId: string, lines: OrderLine[]): Observable<OrderLine[]> {
        this.orderRepository.addLines(lines);
        return this.orderRepository.selectLinesByOrderId(orderId);
    }
    public updateLines(orderId: string, lines: OrderLine[]): Observable<OrderLine[]> {
        return this.orderService.updateLines(orderId, lines);
    }

    public updateLine(orderId: string, lines: Partial<OrderLine>[], box: Box | undefined): void {
        this.orderRepository.updateLines(orderId, lines, box);
    }

    private statusLineValidForUpdate(status: OrderLineStatus | undefined): boolean {
        return !((status === OrderLineStatus.Damage) || (status === OrderLineStatus.Incident)
            || (status === OrderLineStatus.Missing));
    }

    public checkArticle(i: OrderLine, barCodeOrSku: string, orderId: string | undefined = undefined): boolean {
        return OrderHelper.checkArticle(i, barCodeOrSku, orderId);
    }
    public findLine(orderId: OrderKey | undefined, barCodeOrSku: string): OrderLine[] {
        if (orderId !== undefined && orderId.id !== undefined) {
            return this.orderRepository.getLinesByOrderId(orderId.id)
                .filter(i => this.checkArticle(i, barCodeOrSku))
        } else {
            return [];
        }
    }

    public getLine(orderId: OrderKey | undefined, barCodeOrSku: string): OrderLine[] {
        if (orderId === undefined || (orderId !== undefined && orderId.id === undefined)) {
            return [];
        }
        const order = this.orderRepository.getById(orderId.id as string);
        return order !== undefined ? order.lines.filter(i => this.checkArticle(i, barCodeOrSku)) : [];
    }

    public closeBox(orderId: OrderKey | undefined, boxId: string) {
        if (orderId !== undefined && orderId.id !== undefined) {
            this.orderRepository.closeBox(orderId.id, boxId);
        }
    }

    private getPendingCount(element: OrderLine, increment: number): number {
        return element.pending + increment;
    }

    private getPackedCount(element: OrderLine, increment: number): number {
        return element.quantityPacked + (-1 * increment);
    }

    private validateIncrement(element: OrderLine, increment: number): Error | undefined {
        let error: Error | undefined = undefined;
        const countPending = this.getPendingCount(element, increment);
        const countPacked = this.getPackedCount(element, increment);
        // const packedReference = element.quantityRegistered;
        const packedReference = countPacked; // TODO cuando se solucione el problema de la cantidad registrada se volvera a poner la linea anterior
        if (countPending > -1 && countPending <= element.total && countPacked <= packedReference) {
            if (!this.statusLineValidForUpdate(element.status)) {
                error = new Error('Element status incorrect');
                error.name = 'ERROR02';
            }
        } else {
            if (countPending < 0) {
                error = new Error('No pending elements');
                error.name = 'ERROR01';
            } else {
                if (countPacked > packedReference) {
                    error = new Error('Cannot pack more than what has been registered');
                    error.name = 'ERROR03';
                } else {
                    error = new Error('Error desconocido');
                    error.name = 'ERROR04';
                }
            }
        }
        return error;
    }



    private prepareUpdatePending(orderId: OrderKey | undefined, barCodeOrSku: string, increment: number):
        [OrderLine | undefined, Partial<OrderLine> | undefined, Error | undefined] {
        const lines = this.findLine(orderId, barCodeOrSku);
        let result: Partial<OrderLine> | undefined = undefined;
        let error: Error | undefined = undefined;
        let element: OrderLine | undefined = undefined;
        let found = false;
        for (let index = 0; index < lines.length && !found ; index++) {
            element = lines[index];
            error = this.validateIncrement(element, increment);
            found = error === undefined;
        }
        
        if (element !== undefined && orderId !== undefined && orderId.id !== undefined) {
            error = this.validateIncrement(element, increment);
            if (error === undefined) {
                result = { id: element.id, orderId: orderId.id, sku: element.sku, 
                    pending: this.getPendingCount(element, increment), quantityPacked: this.getPackedCount(element, increment)};
            }
        }
        return [element, result, error];

    }

    public updatePending(orderId: OrderKey | undefined, barCodeOrSku: string, increment: number, box: Box | undefined, persist: boolean = true): OrderLine | undefined {
        const prepared = this.prepareUpdatePending(orderId, barCodeOrSku, increment);
        let result = prepared[0];
        if (prepared[1] !== undefined && orderId !== undefined && orderId.id !== undefined && persist) {
            this.orderRepository.updateLines(orderId.id, [prepared[1]], box);
            result = this.orderRepository.getById(orderId.id)?.lines.find(i => i.id === prepared[1]?.id);
        }
        if (prepared[2] !== undefined) {
            throw prepared[2];
        }
        return result;
    }

    public calculatePending(line: OrderLine): number {
        let result = line.pending;
        if (line.incidences !== undefined && line.incidences.length > 0) {
            const incidencesSumCount = line.incidences.map(i => i.count).reduce((a, b) => a + b);
            result = incidencesSumCount >= line.pending ? 0 : line.pending - incidencesSumCount;
        }
        return result;
    }

    public calculateStats(lines: OrderLine[] | undefined): OrderLineStats | undefined {

        if (lines !== undefined) {
            const result = new OrderLineStats();
            result.total = lines.reduce<number>((prev, value, _index) => prev + value.total, 0);
            result.pending = lines.reduce<number>((prev, value, _index) => prev + this.calculatePending(value), 0);
            const skuList: string[] = [];
            const skuPackingList: string[] = [];
            for (const item of lines) {
                if (skuList.indexOf(item.sku) < 0) {
                    result.skuTotal = result.skuTotal + 1;
                    skuList.push(item.sku);
                }
                if (skuPackingList.indexOf(item.sku) < 0 && item.pending > 0) {
                    skuPackingList.push(item.sku);
                    result.skuPacking = result.skuPacking + 1;
                }
            }
            return result;
        } else {
            return undefined;
        }

    }

    public updateBoxModelType(boxId: string, boxModelId: string): void {
        this.orderRepository.updateBoxModelType(boxId, boxModelId);
    }

    public reInitOrder(orderId: OrderKey): Observable<Order | undefined> {
        throw new Error('Method not implemented.');
    }
}