import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, forkJoin, map, of, switchMap, take } from 'rxjs';
import { LogisticDocument } from '../model/logistic-document';
import { AuthorizationTokenService } from 'src/app/security/services/authorization-token.service';
import { FileDownloadRequest } from '../model/file-download-request';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ComposeUrlService } from 'src/app/api/services/compose-url.service';
import { WINDOW } from 'src/app/common-app/services/window';
import { BridgeTokenConfigProviderService } from 'src/app/security-passage-passkey/services/bridge-token-config-provider.service';

type DocumentDownloadContent = {
  content: ArrayBuffer | undefined;
  error: Error | undefined;
};

@Injectable({
  providedIn: 'root'
})
export class DocumentDownloadService {

  private urlTools;
  constructor(
    private composeUrlService: ComposeUrlService,
    private http: HttpClient,
    private authorizationTokenService: AuthorizationTokenService,
    private bridgeTokenConfigProviderService: BridgeTokenConfigProviderService,
    @Inject(WINDOW) private window: Window
  ) {
    this.urlTools = this.composeUrlService.composeToolsUrl('fileDownload');
  }

  private getParamsDownLoadFile(): HttpParams {
    const code = this.bridgeTokenConfigProviderService.getCode();
    let params = new HttpParams();
    if (code !== undefined) {
      params = params.set('code', code);
    }
    return params;
  }

  private downloadFileByProxy(doc: LogisticDocument): Observable<ArrayBuffer> {
    const request = {
      url: doc.url,
      site: this.window.location.hostname
    } as FileDownloadRequest;
    return this.authorizationTokenService.getExternalToken().pipe(
      take(1),
      switchMap(token => this.http.post(this.urlTools, request,
        {
          responseType: 'arraybuffer',
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          }),
          params: this.getParamsDownLoadFile()
        })
      ));
  }

  public downloadFile(doc: LogisticDocument): Observable<LogisticDocument> {
    return this.http.options(doc.url).pipe(
      switchMap(() =>
        this.http.get(doc.url, { responseType: 'arraybuffer', params: this.getParamsDownLoadFile() })
          .pipe(map(data => {
            doc.content = data;
            return doc;
          }))
      ),
      catchError(() => this.downloadFileByProxy(doc)
        .pipe(
          map(content => { return { content, error: undefined } as DocumentDownloadContent }),
          catchError((error) => of({ error, content: undefined } as DocumentDownloadContent)
          ),
          map(data => {
            doc.content = data.content;
            doc.error = data.error;
            return doc;
          })
        )));
  }

  public downloadFileList(docList: LogisticDocument[]): Observable<LogisticDocument[]> {
    const downLoadList = docList.map(doc => this.downloadFile(doc));
    return forkJoin(downLoadList);
  }
}
