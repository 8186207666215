import { ParamMap } from "@angular/router";
import { OrderKey } from "../model/order-key";

export class OrderUtilHelper {

    public static getContainerId(params: ParamMap): string | undefined {
        const containerId = params.get('containerId') as string;       
        return containerId === undefined || containerId === null ? undefined : containerId;
    }

    public static getOrderKey(params: ParamMap): OrderKey | undefined {
        const result = new OrderKey();
        const orderId = params.get('orderId') as string;
        if (orderId !== undefined && orderId !== null) {
            result.id = orderId;
        }
        const containerId = OrderUtilHelper.getContainerId(params);
        if (containerId !== undefined && containerId !== null) {
            result.containerId = containerId;
        }
        const slotId = params.get('slotId') as string;
        if (slotId !== undefined && slotId !== null) {
            result.slotId = slotId;
        }
        return orderId === undefined || orderId === null ? undefined : result;
    }
}