<div class="counter-container">
    <!-- counter.component.html -->
    <div class="counter">
        <h2 class="title">Picked</h2>
        <p class="counter-picked">{{ picked }}</p>
    </div>
    <div class="counter">
        <h2 class="title">Packing</h2>
        <p class="counter-packing">{{ packing }}</p>
    </div>
    <div class="counter">
        <h2 class="title">Packed</h2>
        <p  class="counter-packed">{{ packed }}</p>
    </div>
    <div class="counter">
        <h2 class="title">Incident</h2>
        <p class="counter-incident">{{ incident }}</p>
    </div>
    <div class="counter">
        <h2 class="title">Processed</h2>
        <p  class="counter-processed">{{ processed }}% <span class="subtitleProcessed">[{{completed}}/{{orderCount}}]</span></p>
    </div>
</div>