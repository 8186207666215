import { Injectable } from '@angular/core';
import { DeliveryAction } from '../model/delivery-action';
import { Store, createStore } from '@ngneat/elf';
import { addEntitiesFifo, deleteAllEntities, hasEntity, selectAllEntities, selectLast, withEntities } from '@ngneat/elf-entities';
import { Observable, debounceTime } from 'rxjs';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

type StoreType = Store<{
  name: string;
  state: {
    entities: Record<string, DeliveryAction>;
    ids: string[];
  };
  config: {
    idKey: "id";
  };
}, {
  entities: Record<string, DeliveryAction>;
  ids: string[];
}>;

@Injectable()
export class DeliveryActionRespositoryService {

  private storeDb: Map<string, StoreType>;

  constructor() {
    this.storeDb = new Map<string, StoreType>();
  }

  private createStore(storeName: string): typeof store {
    const store = createStore({ name: storeName },
      withEntities<DeliveryAction, 'id'>({ idKey: 'id' }),
    );
    persistState(store, {
      key: storeName,
      storage: localStorageStrategy,
      source: () => store.pipe(debounceTime(1000)),
    });

    return store;
  }

  private getStore(storeName: string): StoreType {
    let store = this.storeDb.get(storeName);
    if (store === undefined) {
      store = this.createStore(storeName);
      this.storeDb.set(storeName, store);
    }
    return store;
  }

  public selectLastInQueue(storeName: string): Observable<DeliveryAction | undefined> {
    return this.getStore(storeName).pipe(selectLast());
  }

  public enqueue(storeName: string, action: DeliveryAction, limit: number = 5): void {
    const store = this.getStore(storeName);
    if (!store.query(hasEntity(action.id))) {
      store.update(addEntitiesFifo([action], { limit: limit }));
    }
  }

  public selectActions(storeName: string): Observable<DeliveryAction[]> {
    const store = this.getStore(storeName);
    return store.pipe(selectAllEntities());
  }

  public reset(storeName: string): void {
    const store = this.getStore(storeName);
    store.update(deleteAllEntities());
  }
}
