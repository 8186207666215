import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { is } from 'immer/dist/internal';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss']
})
export class ScrollTopComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  private cdkScrollable: CdkScrollable | undefined;
  windowScrolled: boolean | undefined;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private scrollDispatcher: ScrollDispatcher,
    private breakpointObserver: BreakpointObserver) {
    this.windowScrolled = false;
    this.scrollDispatcher.scrolled().pipe(takeUntil(this.destroy$)).subscribe(x => {
      if (x instanceof CdkScrollable) {
        this.cdkScrollable = x;
        this.processPosition(x);
      }
    }
    );

    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query] && this.cdkScrollable !== undefined) {
            this.processPosition(this.cdkScrollable);
          }
        }
      });
  }

  private processPosition(scrollable: CdkScrollable) {
    if (scrollable.getElementRef().nativeElement.scrollTop > 100) {
      this.windowScrolled = true;
    }
    else if (this.windowScrolled && scrollable.getElementRef().nativeElement.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  scrollToTop() {
    if (this.cdkScrollable !== undefined) {
      this.cdkScrollable.scrollTo({ top: 0 });
    }
    // window.requestAnimationFrame(smoothscroll);
    // window.scrollTo(0, currentScroll - (currentScroll / 8));



    // var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

  }

  ngOnInit() {
  }

}
