import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicHttpClientService } from 'src/app/api/services/basic-http-client.service';
import { RootObject } from '../model/container';
import { ContainerService } from './container.service';

@Injectable({
    providedIn: 'root'
})
export class ContainerMockService extends ContainerService {

    constructor(private httpClient: BasicHttpClientService) { 
        super();
    }

    public getContentFromFather(id: string): Observable<RootObject> {
        return this.httpClient.get<RootObject>(`orderBox/${id}`);
    }
}
