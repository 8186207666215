import { ContainerDocumentResponse, ContainerResponse, DatoResponse, InstructionsResponse, ObtenerContenedorEmpaquetadoResponse, IncidenceResponse, OrderLineResponse, OrderResponse } from "src/app/container/model/api/obtener-contenedor-empaquetado-response";
import { Order } from "../model/order";
import { OrderLine } from "../model/order-line";
import { BoxContentItem } from "src/app/package/model/box-content-item";
import { OrderLineHelper } from "./order-line-helper";
import { PackingState } from "src/app/common-app/model/packing-state";
import { OrderContainer } from "../model/order-container";
import { OrderIncidence } from "../model/order-incidence";
import { OrderState } from "../model/order-state";
import { InstructionOrder } from "../model/instruction-order";
import { IncidentApiHelper } from "src/app/incident/services/incident-api-helper";
import { IdType } from "./order-packaging-container-api.service";
import { LogisticDocument } from "src/app/document/model/logistic-document";
import { Box } from "src/app/package/model/box";
import { DocumentLocation } from "src/app/document/model/document-location";
import { OrderHelper } from "./order-helper";
import produce from "immer";

export class OrderApiHelper {

  private static OriginalStatusOutOfPacking = ['EXPEDICIÓN'];

  public static convertStatus(status: string | undefined): OrderState {
    if (status !== undefined) {
      switch (status) {
        case 'Picking':
          return OrderState.Picking;
        case 'Picked':
          return OrderState.Picked;
        case 'Packing':
          return OrderState.Packing;
        case 'Packed':
          return OrderState.Packed;
        case 'Incidence':
          return OrderState.Incidence;
        default:
          if (OrderApiHelper.OriginalStatusOutOfPacking.includes(status.toLocaleUpperCase())) {
            return OrderState.OutOfPacking;
          } else {
            return OrderState.Unknow;
          }
      }
    } else {
      return OrderState.Unknow;
    }
  }

  private static findItem(data: DatoResponse[], id: string, idType: IdType): DatoResponse | undefined {
    let found: DatoResponse | undefined = undefined;
    switch (idType) {
      case IdType.Container:
        found = data.find(i => i.Container.toUpperCase() === id);
        break;
      case IdType.Order:
        found = data.find(i => i.Orders.find(j => j['Order No']?.toUpperCase() === id) !== undefined);
        if (found !== undefined && found.Orders !== undefined
          && found.Orders.reduce((prev, current) => prev + ((current["Order Lines"] !== undefined && current["Order Lines"].length > 0) ? 1 : 0), 0) !== 1) {
          found = undefined;
        }
        break;
      default:
        break;
    }
    return found;
  }
  private static findContainerOrOrder(data: DatoResponse[], id: string, idType: IdType): DatoResponse | undefined {
    let found: DatoResponse | undefined = undefined;
    switch (idType) {
      case IdType.Container:
      case IdType.Order:
        found = OrderApiHelper.findItem(data, id, idType);
        break;
      case IdType.Both:
        found = OrderApiHelper.findItem(data, id, IdType.Container);
        if (found == undefined) {
          found = OrderApiHelper.findItem(data, id, IdType.Order);;
        }
        break;
      default:
        break;
    }
    return found;
  }

  public static convertOrderContainerResponse(item: ObtenerContenedorEmpaquetadoResponse | undefined, id: string, idType: IdType): OrderContainer | undefined {
    const result: OrderContainer = {} as OrderContainer;
    if (item != undefined && item.Datos != undefined) {
      const cleanId = id.toUpperCase();
      const found = OrderApiHelper.findContainerOrOrder(item.Datos, cleanId, idType);
      if (found != undefined) {
        result.containerId = found.Container;
        if (item.Productivity !== undefined && item.Productivity.length > 0) {
          result.productivityId = parseInt(item.Productivity[0]['Productivity Id']);
        }
        result.columns = found.Columns;
        result.container = found.Container;
        result.orders =
          found.Orders.map(i => OrderApiHelper.convertOrderResponse(i, found.Container, i.Slot, result.productivityId, found.Wave, found["Pick No"]) as Order);
        result.slots = found.Slots;
        result.type = found.Type;
        result.wave = found.Wave;
      }
    }
    return result;
  }

  public static parseDate(data: string): Date | undefined {
    let result: Date | undefined = undefined;
    const numericDate = Date.parse(data);
    if (!isNaN(numericDate)) {
      result = new Date(numericDate);
    }
    return result;
  }

  private static convertBoxList(containers: ContainerResponse[] | undefined): Box[] {
    if (containers !== undefined) {
      let result: Box[] = [];
      for (const iterator of containers) {
        const box = new Box();
        box._id = iterator.Container;
        box.closed = iterator.Closed;
        box.boxModelId = iterator["Client Box"];
        box.documentList = OrderApiHelper.convertDocumentList(iterator.Documents, DocumentLocation.Box, box._id);
        result.push(box);
      }
      return result;
    } else {
      return [];
    }
  }

  public static convertOrderResponse(item: OrderResponse | undefined, containerId: string, slotId: string | undefined, productivityId: number, wave: string, pickId: string): Order | null {
    if (item === undefined) {
      return null;
    }
    let orderLines: OrderLine[] = [];
    const orderId: string = item['Order No'] as string;
    const boxLines = this.convertBoxLineList(item["Containers"], orderLines, orderId);
    const boxList = this.convertBoxList(item["Containers"]);
    if (item['Order Lines'] !== undefined) {
      let tempBoxLines = structuredClone(boxLines);
      orderLines = item['Order Lines'].sort((a, b) => {
        let result = a.Item.localeCompare(b.Item);
        if (result === 0) {
          result = OrderApiHelper.pendingCount(a) - OrderApiHelper.pendingCount(b);
        }
        return result;
      }).map((data, index) => {
        const result = this.convertLine(data, index, item['Order No'] as string, slotId as string, tempBoxLines);
        tempBoxLines = result[1];
        return result[0];
      }, this);

    }

    const order = {
      country: item["Ship To"]?.Country === undefined ? "" : item["Ship To"]?.Country,
      date: OrderApiHelper.parseDate(item["Order Date"]),
      id: orderId,
      containerId: containerId,
      wave: wave,
      pickId: pickId,
      status: this.convertStatus(item.Status),
      originalStatus: item.Status,
      serviceType: item['Service Type'],
      lines: orderLines,
      slotId: slotId,
      productivityId: productivityId,
      instructions: this.convertInstruction(item.Instructions),
      boxLines: boxLines,
      urgent: item.Urgent === undefined ? false : item.Urgent,
      channel: item.Channel,
      deliveryType: item["Delivery Type"],
      carrier: item.Carrier,
      incident: OrderApiHelper.convertIncidence(item.Incidences),
      documentList: this.convertDocumentList(item.Documents, DocumentLocation.Order, orderId),
      boxList: boxList
    } as Order;
    return order;
  }

  private static convertInstruction(instructions: InstructionsResponse[] | undefined): InstructionOrder[] {
    let result: InstructionOrder[] = [];
    if (instructions !== undefined) {
      result = instructions.map(i => { return { serviceType: i.SA1 } as InstructionOrder; });
    }
    return result;
  }

  private static convertIncidence(incidences: IncidenceResponse[]): OrderIncidence[] {
    let result: OrderIncidence[] = [];
    if (incidences !== undefined) {
      result = incidences.map(i => {
        return {
          originalType: i.Type,
          articleIncidentType: IncidentApiHelper.convertFromApi(i.Type),
          count: i.Quantity
        } as OrderIncidence;
      });
    }
    return result;
  }

  private static pendingCount(value: OrderLineResponse): number {
    return value.Quantity - value['Quantity Packed'];
  }

  private static convertLine(value: OrderLineResponse, index: number, orderId: string, slotId: string, boxLines: BoxContentItem[]): [OrderLine, BoxContentItem[]] {
    const incidentLineList = OrderApiHelper.convertIncidence(value.Incidences)
    const pendingCount = OrderApiHelper.pendingCount(value);
    const sku = value.Item;
    const result = {
      barCode: value['Bar Codes'] !== undefined ? value['Bar Codes'].split('#') : [],
      description: value.Description,
      orderId: orderId,
      slotId: slotId,
      id: OrderLineHelper.composeId(orderId, index),
      lineNumber: index,
      sku: sku,
      total: value.Quantity,
      pending: pendingCount,
      packingStatus: PackingState.Unknown,
      gift: 0,
      instructions: value.Instructions,
      incidences: incidentLineList,
      quantityRegistered: value['Quantity Packed'],
      quantityPacked: value['Quantity Packed'],
    } as OrderLine;
    result.boxContentItemList = OrderHelper.substractFromSku(sku, result.quantityPacked, boxLines);
    result.packingStatus = OrderLineHelper.getCompletPackingStatus(result, result.boxContentItemList);
    return [result, boxLines];
  }

  public static convertDocumentList(documentsOrder: ContainerDocumentResponse[] | undefined, location: DocumentLocation, id: string = ''): LogisticDocument[] {
    let result: LogisticDocument[] = [];
    if (documentsOrder !== undefined) {
      result = documentsOrder.map(i => {
        const result = new LogisticDocument();
        switch (location) {
          case DocumentLocation.Order:
            result.description = `Order ${i.Type}`;
            break;
          case DocumentLocation.Box:
            result.description = `Box ${id}`;
            break;
          default:
            result.description = `# ${i.Type}`;
            break;
        }
        result.url = i.Value;
        result.type = i.Type;
        result.content = undefined;
        result.documentoLocation = location;
        return result;
      });
    }
    return result;
  }

  public static convertBoxLineList(containers: ContainerResponse[] | undefined, orderLines: OrderLine[], orderId: string): BoxContentItem[] {
    if (containers !== undefined) {
      let result: BoxContentItem[] = [];
      for (const iterator of containers) {
        result = result.concat(iterator["Content Lines"].map((data) =>
          new BoxContentItem(orderId, data.Item, data.Quantity, iterator.Container, iterator["Client Box"], iterator.Closed)));
      }
      return result;
    } else {
      return [];
    }
  }
}