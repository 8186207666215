import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginData } from '../model/login-data';
import { AuthenticationService } from '../services/authentication-service';
import { TextNotificationService } from 'src/app/common-app/services/text-notification.service';
import { Router } from '@angular/router';
import { ErrorService } from 'src/app/common-app/services/error.service';
import { Subject, take } from 'rxjs';

type PasswordFormType = { userId: FormControl<string>, password: FormControl<string> };

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  
  protected form: FormGroup<PasswordFormType>;
  protected progres$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private textNotificationService: TextNotificationService,
    private errorService: ErrorService,
    private router: Router) {
    this.form = this.fb.nonNullable.group<PasswordFormType>({
      userId: new FormControl<string>('',  {nonNullable: true, validators: [Validators.required]}),
      password: new FormControl<string>('',  {nonNullable: true}),
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    const data: LoginData = {
      password: this.form.value.password as string,
      userId: this.form.value.userId as string
    };
    this.authenticationService.login(data).pipe(
      take(1)
    ).subscribe(
      {
        next: () => {
          this.textNotificationService.text("Login success");
          this.router.navigate(['/manage/packingStation']);
          this.progres$.next();
        },
        error: (error) => {
          if (error.status !== undefined && error.status === 401) {
            this.textNotificationService.error('Login failed');
          } else {
            this.textNotificationService.error(this.errorService.friendlyError(error, error.message));
          }
          this.progres$.next();
        }
      }
    )
  }

}
