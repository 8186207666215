import { Observable } from 'rxjs';
import { RootObject } from '../model/container';


export abstract class ContainerService {

    constructor() { }

    public abstract getContentFromFather(id: string): Observable<RootObject> ;
}
