import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BasicHttpClientService } from 'src/app/api/services/basic-http-client.service';
import { Article } from '../model/article';
import { ArticleService } from './article.service';

@Injectable()
export class ArticleMockService extends ArticleService {

    constructor(private httpClient: BasicHttpClientService) {
        super();
    }

    public getByBarCode(barCode: string): Observable<Article | null> {
        const params = new HttpParams({ fromString: `barCode=${barCode}` });
        return this.httpClient.get<Article[]>(`articles/`, params).pipe(map(data => data.length == 0 ? null : data[0]));
    }
}