import { IncidentTypeValue } from "../model/api/incident-type-value";
import { ArticleIncidentType } from "../model/article-incident-type";

export class IncidentApiHelper {
    public static convertToApi(orderLineStatus: ArticleIncidentType): string | undefined {
        let incident: IncidentTypeValue | undefined = undefined;
        switch (orderLineStatus) {
          case ArticleIncidentType.Damaged:
            incident = IncidentTypeValue.Damaged
            break;
          case ArticleIncidentType.Missing:
            incident = IncidentTypeValue.Missing
            break;
          case ArticleIncidentType.Technical:
            incident = IncidentTypeValue.Technical
            break;
          case ArticleIncidentType.Data:
            incident = IncidentTypeValue.Data
            break;
          default:
            break;
        }
        return incident;
      }

      public static convertFromApi(orderLineStatus: string): ArticleIncidentType | undefined {
        let incident: ArticleIncidentType | undefined = undefined;
        switch (orderLineStatus) {
          case IncidentTypeValue.Damaged:
            incident = ArticleIncidentType.Damaged
            break;
          case IncidentTypeValue.Missing:
            incident = ArticleIncidentType.Missing
            break;
          case IncidentTypeValue.Technical:
            incident = ArticleIncidentType.Technical
            break;
          default:
            break;
        }
        return incident;
      }
}
