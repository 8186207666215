<h2 mat-dialog-title>Errors</h2>
<mat-dialog-content class="mat-typography">
    <ng-container *ngIf="errorHistory.length > 0; else noErrors">
        <table mat-table [dataSource]="errorHistory" class="mat-elevation-z8">
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef> Error </th>
                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
            </ng-container>
            <ng-container matColumnDef="text">
                <th mat-header-cell *matHeaderCellDef> Message </th>
                <td mat-cell *matCellDef="let element"> {{element.text}} </td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> Date </th>
                <td mat-cell *matCellDef="let element"> {{element.date | date:'short'}} </td>
            </ng-container>
            <ng-container matColumnDef="errorDetail">
                <th mat-header-cell *matHeaderCellDef> Detail </th>
                <td mat-cell *matCellDef="let element"> {{element.errorObject | json}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </ng-container>
    <ng-template #noErrors>
        <p>No errors found</p>
    </ng-template>
  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Ok</button>
</mat-dialog-actions>
