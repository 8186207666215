<app-order-summary-sheet [order]="order"></app-order-summary-sheet>
<mat-card class="content">
    <mat-card-title data-test-id="titleChooseBoxType">Choose box type</mat-card-title>
    <mat-card-content class="wrapper">
        <div class="back">
            <button mat-stroked-button class="largeButton" color="primary" (click)="onBack()"
                aria-label="Back"><mat-icon>keyboard_backspace</mat-icon> Back</button>
        </div>
        <div class="scan">
            <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box"
                [fullScreen]="true">
                <p class="spinner"> Loading... </p>
            </ngx-spinner>
            <app-scan-input class="scanInput" (onId)="onScan($event)"></app-scan-input>
        </div>
        <div class="data">
            <ng-container *ngIf="!(loading$ | async)">
                <mat-selection-list *ngFor="let boxGroup of (orderBoxGroup?.boxModel ?? [])" [multiple]="false"
                    (selectionChange)="onSelectionChange($event)">

                    <div mat-subheader>{{ boxGroup.name }}</div>
                    <div class="wrapperBox boxModel">
                        <mat-list-option class="boxTypeOption" *ngFor="let boxModel of boxGroup.boxList" [value]="boxModel.id">
                            <div mat-line class="boxType">
                                <h2 [attr.data-test-id]="'boxTypeValue_'+boxModel.id"><strong>{{boxModel.id}}</strong>{{boxModel.description}}</h2>
                                </div>
                        </mat-list-option>
                    </div>
                </mat-selection-list>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p class="spinner"> Loading... </p>
</ngx-spinner>