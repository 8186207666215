import { Inject, Injectable } from '@angular/core';
import { WINDOW } from 'src/app/common-app/services/window';

@Injectable({
  providedIn: 'root'
})
export class PassageConnectionProviderService {

  constructor(@Inject(WINDOW) private window: Window) { }

  public getAppId(): string {
    switch (this.window.location.hostname) {
      case 'localhost':
        return 'BsOzMu5xbP9B7Li2gdqaPQwg';
      case 'test.cosmosomni.com':
        return 'YA4waFFxQmNMd9asvt9W7E4v';
      case 'brownie.cosmosomni.com':
        return 'TVAmse1rQovRzX77TjRoejLu';
      default:
        return '';
    }
    
  }
}
