import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackMessageType, SnackMessageVo } from '../snack-message/snack-message-vo';
import { SnackMessageComponent } from '../snack-message/snack-message.component';

@Injectable({
  providedIn: 'root'
})
export class TextNotificationService {

  constructor(private snackBar: MatSnackBar) { }

  public text(text: string): void {
    this.snackBar.open(text, undefined, { duration: 3000 });
  }

  public error(text: string): void {
    const data = new SnackMessageVo();
    data.message = text;
    data.messageType = SnackMessageType.Error;
    this.snackBar.openFromComponent(SnackMessageComponent, { duration: 5000, data: data });
  }

  public clean(): void {
    this.snackBar.dismiss();
  }
}
