import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-kpi-card',
  templateUrl: './kpi-card.component.html',
  styleUrls: ['./kpi-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KpiCardComponent implements OnInit, OnChanges {

  @Input() title: string | undefined;
  @Input() duration: number | undefined;
  @Input() progress: boolean | undefined;
  @Input() difference: string | undefined;
  @Input() value: number = 0;
  @Input() format: 'percent' | 'currency' | undefined;
  @Input() decimalPlaces: number = 0;
  public postfix: string | null = null;
  public prefix = null;
  public diffValue: number | null = null;

  constructor() {

  }

  ngOnInit(): void {
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.format === undefined) {
      this.postfix = '';
    } else {
      switch (this.format) {
        case 'percent':
          this.postfix = '%';
          break;
        case 'currency':
          this.postfix = '€';
          break;
        default:
          break;
      }
    }
  }

}
