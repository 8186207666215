import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniqueCount'
})
export class UniqueCountPipe<T> implements PipeTransform {

  transform(value: T[], field: keyof T): number {
    if (!Array.isArray(value)) {
      return 0;
    }

    const uniqueValues = new Set(value.map(item => item[field]));
    return uniqueValues.size;
  }

}
