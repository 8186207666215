import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ConfigElectronService } from '../services/config-electron.service';
import { PortalConfig, Printer } from '../model/config';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-edit-config-portal',
  templateUrl: './edit-config-portal.component.html',
  styleUrls: ['./edit-config-portal.component.scss']
})
export class EditConfigPortalComponent implements OnInit {
  protected configForm: FormGroup;
  protected dataSource = new BehaviorSubject<FormArray | null>(null);

  constructor(
    private fb: FormBuilder,
    private configService: ConfigElectronService
  ) { 
    this.configForm = this.fb.group({
      url: ['', Validators.required],
      packingStation: ['', Validators.required],
      company: ['', Validators.required],
      environment: ['', Validators.required],
      requestWeight: [false, Validators.required],
      printers: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.configService.getConfigPortal().subscribe((config: PortalConfig) => {
      this.configForm.patchValue(config.app.pickingOnline);
      this.setPrinters(config.app.pickingOnline.printers);
    });
  }

  get printers(): FormArray {
    return this.configForm.get('printers') as FormArray;
  }

  get printersArray(): Printer[] {
    return this.printers.value;
  }

  convertPrinter(printer: Printer): FormGroup {
    return this.fb.group({
      documentType: [printer.documentType, Validators.required],
      printerName: [printer.printerName, Validators.required],
      scale: [printer.scale],
      paperSize: [printer.paperSize]
    });
  }

  setPrinters(printers: Printer[]): void {
    const printersFGs = printers.map(printer => this.convertPrinter(printer));
    const printersFormArray = this.fb.array(printersFGs);
    this.configForm.setControl('printers', printersFormArray);
    this.dataSource.next(printersFormArray);
  }

  addPrinter(): void {
    this.printers.push(this.convertPrinter(new Printer('', '', '', '')));
    this.dataSource.next(this.printers);
  }

  removePrinter(index: number): void {
    this.printers.removeAt(index);
    this.dataSource.next(this.printers);
  }

  onSubmit(): void {
    if (this.configForm.valid) {
      const updatedConfig = new PortalConfig({
        pickingOnline: this.configForm.value
      });

      this.configService.saveConfigPortal(updatedConfig).subscribe();
    }
  }
}
