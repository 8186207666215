import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackMessageType, SnackMessageVo } from './snack-message-vo';

@Component({
  selector: 'app-snack-message',
  templateUrl: './snack-message.component.html',
  styleUrls: ['./snack-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackMessageComponent implements OnInit {

  protected messageTypeValue = SnackMessageType;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackMessageVo) { }

  ngOnInit(): void {
  }

}
