import { Inject, Injectable } from '@angular/core';
import { ComposeUrlService } from 'src/app/api/services/compose-url.service';
import { WINDOW } from 'src/app/common-app/services/window';

@Injectable({
  providedIn: 'root'
})
export class BridgeTokenConfigProviderService {

  constructor(
    private composeUrlService: ComposeUrlService,
    @Inject(WINDOW) private window: Window
  ) { }

  public getUrl(): string {
    return this.composeUrlService.composeToolsUrl('authenticationBc');
  }

  public getCode(): string | undefined {
    switch (this.window.location.hostname) {
      case 'localhost':
        return undefined;
      case 'test.cosmosomni.com':
        return 'RV2mq7dJ_8EvfxlZ9rnKAoH9HUQX4E5k4MGjM4KBZ1GYAzFuFEMQeQ==';
      case 'brownie.cosmosomni.com':
        return 'GEQrIpAZQJKegcdKtZylRJIXXVEA86BQ93cfNE3zyg4AAzFuZgpn6w==';
      default:
        return undefined;
    }
  }
}
