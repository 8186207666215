import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ElementRef } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { PackingState } from 'src/app/common-app/model/packing-state';
import { OrderLine } from 'src/app/order/model/order-line';
import { OrderLineStatus } from 'src/app/order/model/order-line-status';
import { OrderLinePackedBoxVo, OrderLinePackedVo } from '../model/order-line-packed-vo';
import { OrderLinePackedVoKey } from '../model/order-line-packed-vo-key';
import { OrderTableStyle } from '../model/order-table-style';
import { animate, state, style, transition, trigger } from '@angular/animations';

class RecordItemType {
  public line: OrderLinePackedVo | undefined;
  public packingState: PackingState | undefined;
  public boxId: string | undefined;
  public boxTotal: number | undefined;
  public boxModelType: string | undefined;
  public lineKey: string | undefined;
  public boxList: OrderLinePackedBoxVo[] = []
};

@Component({
  selector: 'app-order-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class OrderTableComponent implements OnInit, OnChanges {

  @Input() public orderLines: OrderLinePackedVo[] | undefined;
  @Input() public selectedItem: OrderLinePackedVoKey | undefined;
  @Input() public itemStyle: OrderTableStyle = OrderTableStyle.Packing;
  @Output() public onSelected: EventEmitter<OrderLine | undefined> = new EventEmitter<OrderLine | undefined>();
  public dataSource: RecordItemType[] = [];
  protected expandedElement: RecordItemType | null = null;

  public selection: SelectionModel<RecordItemType>;
  private completeListColumns = ['sku', 'description', 'pending', 'total', 'gift', 'status', 'boxId', 'itemPackedCount', 'expand'];
  protected displayedColumns: string[] = this.completeListColumns;
  protected packingStateType = PackingState;
  private unSubcriptionSubject = new Subject<void>();
  protected selectKey: string | undefined;

  constructor() {
    this.selection = new SelectionModel<RecordItemType>(false, [], undefined, (a: RecordItemType, b: RecordItemType) => a.lineKey === b.lineKey);
    this.selection.changed.pipe(takeUntil(this.unSubcriptionSubject)).subscribe(
      data => {
        if (data.source.hasValue()) {
          this.onSelected.emit(data.source.selected[0].line);
        } else {
          this.onSelected.emit(undefined);
        }
      }
    )
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const orderChange = changes['orderLines'];
    if (orderChange !== undefined && orderChange.currentValue !== undefined) {
      this.loadDataOrder();
    }
    const selectedItemChange = changes['selectedItem'];
    if (selectedItemChange !== undefined && selectedItemChange.currentValue !== undefined) {
      this.selectKey = this.lineKey(selectedItemChange.currentValue.boxId, selectedItemChange.currentValue.sku)
    } else {
      this.selectKey = undefined;
    }

    const selectItemStyle = changes['itemStyle'];
    if (selectItemStyle !== undefined && selectItemStyle.currentValue !== undefined) {
      switch (selectItemStyle.currentValue) {
        case OrderTableStyle.Packing:
          this.displayedColumns = this.completeListColumns;
          break;
        case OrderTableStyle.Packed:
          this.displayedColumns = ['sku', 'description', 'gift', 'boxId', 'itemPackedCount'];
          break;
        case OrderTableStyle.Unitary:
          this.displayedColumns = ['sku', 'description', 'gift', 'status', 'boxId', 'orderId'];
          break;
        default:
          break;
      }
    }
  }

  public loadDataOrder(): void {
    if (this.orderLines !== undefined && this.orderLines !== null) {
      this.dataSource = this.orderLines.map(line => this.convert(line));
    } else {
      this.dataSource = [];
    }
  }

  private getPackingStatus(status: OrderLineStatus | undefined, packingState: PackingState | undefined): PackingState | undefined {
    let result: PackingState | undefined = undefined;
    if (packingState !== undefined) {
      return packingState;
    }
    switch (status) {
      case OrderLineStatus.Incident:
      case OrderLineStatus.Damage:
      case OrderLineStatus.Missing:
        result = PackingState.Incident;
        break;
      default:
        break;
    }
    return result;
  }

  private lineKey(boxId: string | undefined, sku: string) {
    return `${boxId}_${sku}`;
  }

  private convert(line: OrderLinePackedVo): RecordItemType {
    const result: RecordItemType = new RecordItemType();
    result.packingState = this.getPackingStatus(line.status, line.packingStatus);
    result.lineKey = this.lineKey(line.boxList !== undefined && line.boxList.length > 0 ? line.boxList[0].boxId : undefined, line.sku);
    if (line.boxList !== undefined && line.boxList.length > 0) {
      const box = line.boxList[0];
      result.boxId = box.boxId;
      result.boxTotal = box.itemPackedCount;
      result.boxModelType = box.boxModelType;
      result.packingState = box.status;
      result.boxList = line.boxList.slice(1);
    }
    result.line = line;
    return result;
  }

}
