import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PassageConnectionProviderService } from '../services/passage-connection-provider.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {

  public appId: string = '';

  constructor(passageConnectionProviderService: PassageConnectionProviderService) { 
    this.appId = passageConnectionProviderService.getAppId();
  }

  ngOnInit(): void {
  }

}
