import { Injectable } from '@angular/core';
import { PDFDocument, PDFPage } from 'pdf-lib';
import { Observable, Subject, catchError, forkJoin, from, map, switchMap, take, tap, throwError } from 'rxjs';
import * as printJS from "print-js";
import { InternalError } from 'src/app/common-app/model/internal-error';


export enum PrintStatus {
  loadinStart,
  loadingEnd,
  printDialogClose
}

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor() { }

  printFile(data: Uint8Array): Observable<PrintStatus> {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const event$ = new Subject<PrintStatus>();
    printJS({
      printable: url,
      type: 'pdf',
      onLoadingEnd: () => event$.next(PrintStatus.loadingEnd),
      onLoadingStart: () => event$.next(PrintStatus.loadinStart),
      onPrintDialogClose: () => {
        event$.next(PrintStatus.printDialogClose);
        event$.complete();
      },
      onError: (error, _request) => event$.error(new InternalError('PRINTPDF_0002', 'Fallo al imprimir el fichero', error, undefined))
    })
    return event$.asObservable();
  }

  public convertListToPdf(data: ArrayBuffer[]): Observable<PDFDocument>[] {
    return data.map(this.convertToPdf);
  }

  public convertToPdf(data: ArrayBuffer): Observable<PDFDocument> {
    return from(PDFDocument.load(data));
  }

  public preparePrintPdf(pdfDocumentPages: PDFDocument[]): Observable<PDFDocument> {

    return from(PDFDocument.create()).pipe(
      switchMap(documentData => {
        const pageList: Observable<PDFPage[]>[] = [];
        for (const pdf of pdfDocumentPages) {
          pageList.push(from(documentData.copyPages(pdf, pdf.getPageIndices())));
        }
        return forkJoin(pageList).pipe(
          map(dataPage => {
            dataPage.forEach((pageList) => pageList.forEach((page) => documentData.addPage(page)));
            return documentData;
          }))
      }));
  }

  public printPdf(pdfDocument: PDFDocument): Observable<PrintStatus> {
    /* Array of pdf urls */
    // let pdfsToMerge = ['http://127.0.0.1:5500/src/dummy.pdf'];
    return from(pdfDocument.save()).pipe(switchMap(data => this.printFile(data)));
  }

}
