import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import '@passageidentity/passage-elements/passage-auth'

import { SecurityPassagePasskeyRoutingModule } from './security-passage-passkey-routing.module';
import { LoginComponent } from './login/login.component';
import { BridgeComponent } from './bridge/bridge.component';
import { BridgeTokenService } from './services/bridge-token.service';
import { AuthorizationTokenService } from '../security/services/authorization-token.service';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [
    LoginComponent,
    BridgeComponent
  ],
  imports: [
    CommonModule,
    SecurityPassagePasskeyRoutingModule,
    NgxSpinnerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SecurityPassagePasskeyModule {
  public static forRoot() {
    return {
      ngModule: SecurityPassagePasskeyModule,
      providers: [
        BridgeTokenService,
        {
          provide: AuthorizationTokenService, useExisting: BridgeTokenService
        }
      ]
    }
  }
}
