import { BoxContentItem } from "src/app/package/model/box-content-item";
import { Order } from "../model/order";
import { OrderKey } from "../model/order-key";
import { OrderLine } from "../model/order-line";
import produce from "immer";

export class OrderHelper {
  public static checkArticle(i: OrderLine, barCodeOrSku: string, orderId: string | undefined = undefined): boolean {
    const codeClean = barCodeOrSku.trim().toUpperCase();
    const checkArticle = (i.barCode !== undefined ? i.barCode.indexOf(codeClean) >= 0 : false)
      || (i.sku !== undefined && i.sku.trim().toUpperCase() === codeClean);
    return checkArticle && (orderId === undefined || (orderId !== undefined && orderId === i.orderId));
  }

  public static findContainer(sku: string, order: Order): BoxContentItem | undefined {
    return OrderHelper.findContainerBySku(sku, order.boxLines);
  }

  public static findContainerBySku(sku: string, data: BoxContentItem[]): BoxContentItem | undefined {
    return data.find(i => i.sku === sku);
  }

  public static filterContainerBySku(sku: string, data: BoxContentItem[]): BoxContentItem[] {
    return data.filter(i => i.sku === sku);
  }

  public static substractFromSku(sku: string, count: number, boxList: BoxContentItem[]): BoxContentItem[] {
    let result: BoxContentItem[] = [];
    let boxListForSku = OrderHelper.filterContainerBySku(sku, boxList);
    for (let index = 0; index < boxListForSku.length && count > 0; index++) {
      const element = boxListForSku[index];
      if (element.sku === sku) {
        if (element.count <= count) {
          count -= element.count;
          result.push(element);
          boxList.splice(index, 1);
          index--;
        } else {
          const box = produce(element, draft => { draft.count = count; });
          result.push(box);
          element.count -= count;
          count = 0;
        }
      }
    }
    return result;
  }

  public static convertToOrderKey(order: Order | undefined): OrderKey {
    const orderIdFromData = new OrderKey();
    orderIdFromData.id = order?.id;
    orderIdFromData.containerId = order?.containerId;
    orderIdFromData.slotId = order?.slotId;
    return orderIdFromData;
  }

}