import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, tap, forkJoin, take, map, takeUntil, Subject, withLatestFrom } from 'rxjs';
import { LoadingHelper } from 'src/app/common-app/services/loading-helper';
import { TextNotificationService } from 'src/app/common-app/services/text-notification.service';
import { Order } from 'src/app/order/model/order';
import { OrderKey } from 'src/app/order/model/order-key';
import { OrderService } from 'src/app/order/services/order.service';
import { OrderRepository } from 'src/app/order/services/state/order.repository';
import { OrderUtilHelper } from 'src/app/order/util/order-util-helper';
import { OrderBoxGroup } from '../model/order-box-group';
import { BoxModelService } from '../services/box-model.service';
import { PackagingProcessService } from 'src/app/packaging-process/services/packaging-process.service';
import { PackageProcessState } from 'src/app/packaging-process/model/package-process-state';
import { PickType } from 'src/app/picking/manage-pick/model/pick-type';

@Component({
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss']
})
export class SelectBoxComponent implements OnInit, OnDestroy {

  private orderId: OrderKey | undefined;
  protected order: Order | undefined | null;
  protected orderBoxGroup: OrderBoxGroup | undefined | null;
  protected loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected destoy$ = new Subject<void>();
  private packageProcessState: PackageProcessState | undefined;
  protected action: string | undefined
  protected boxId: string | undefined;
  
  constructor(
    private orderService: OrderService,
    private boxModelService: BoxModelService,
    private orderRepository: OrderRepository,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private textNotificationService: TextNotificationService,
    private packagingProcessService: PackagingProcessService
  ) {
    this.packageProcessState = this.packagingProcessService.getActive();
   }

  ngOnDestroy(): void {
    this.destoy$.next();
  }
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.action = params.get('action') === null ? undefined : params.get('action') as string;
      this.boxId = params.get('boxId') === null ? undefined : params.get('boxId') as string;
      this.orderId = OrderUtilHelper.getOrderKey(params);
      this.loadData(this.orderId);
    });
  }

  private loadData(orderId: OrderKey | undefined) {

    let getData$ = this.orderRepository.activeOrder$.pipe(map(data => data === undefined ? undefined : data[0]));
    if (orderId !== undefined) {
      getData$ = this.orderService.selectOrder(orderId, false);
    }
    this.spinner.show();
    this.boxModelService.getBoxModel().pipe(
      tap((data) => {
        this.orderBoxGroup = data;
      }),
      withLatestFrom(getData$.pipe(
        tap((data) => {
          this.order = data;
        }),
        takeUntil(this.destoy$)
      )),
      takeUntil(this.destoy$)
    ).subscribe({
      next: () => {
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
        this.textNotificationService.error('Fail load list box');
      }
    });
  }

  private onSelect(boxModelId: string) {
    let route = 'package/order';
    let params: object = { boxModelId: boxModelId };
    if (this.action !== undefined) {
      params = { ...params, action: this.action, boxId: this.boxId };
    }
    this.router.navigate([route, params]);
  }
  public onSelectionChange(event: MatSelectionListChange): void {
    const selected = event.options.filter(o => o.selected).map(o => o.value as string);
    if (selected.length > 0) {
      this.onSelect(selected[0]);
    }
  }

  public onScan(barCode: string) {
    const cleanBarCode = (barCode as string).trim().toUpperCase();
    let selected: string | undefined = undefined;
    if (this.orderBoxGroup?.boxModel !== undefined) {
      for (let index = 0; index < this.orderBoxGroup?.boxModel.length && selected === undefined; index++) {
        const element = this.orderBoxGroup?.boxModel[index];
        const elementFind = element.boxList.find(value => value.id.trim().toUpperCase() === cleanBarCode || value.barCode === cleanBarCode);
        selected = elementFind !== undefined ? elementFind.id : undefined;
      }
    }
    if (selected !== undefined) {
      this.onSelect(selected);
    } else {
      this.textNotificationService.error('Box model not found');
    }
  }

  public onBack(): void {
    this.router.navigate(['package/order', { action: 'backSelectBox' }]);
  }

}
