import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleService } from './services/article.service';
import { ArticleMockService } from './services/article-mock.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: ArticleService, useClass: ArticleMockService },
  ]
})
export class MasterModule { }
