import { Injectable } from '@angular/core';
import { OrderLinePackedBoxVo } from '../model/order-line-packed-vo';
import { PackingState } from 'src/app/common-app/model/packing-state';

@Injectable({
  providedIn: 'root'
})
export class PackedBoxService {

  constructor() { }

  public boxIsIncidence(boxId: string | undefined): boolean {
    if (boxId === undefined) {
      return false;
    }
    return boxId.startsWith('INCI');
  }

  public statusOrderLinePackedBoxVo(box: OrderLinePackedBoxVo): PackingState {
    if (this.boxIsIncidence(box.boxId)) { 
      return PackingState.Incident;
    } else {
      return box.closed ? PackingState.Packed : PackingState.Packing;
    }
  }
}
