import { Injectable } from '@angular/core';
import { ErrorData } from 'src/app/api/model/error-data';
import { ODataCustomError } from 'src/app/api/model/odata-custom-error';
import { InternalError } from '../model/internal-error';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  public friendlyError(error: Error, alternativeText: string): string {
    let result = alternativeText;
    const customError = error as ODataCustomError
    if (customError !== undefined && customError.data !== undefined) {
      const code = customError.data.Code.toUpperCase();
      result = code.startsWith("ERR") ? customError.data.Description : alternativeText;
    } else {
      if (error instanceof ErrorData) {
        const errorData = error as ErrorData;
        const code = errorData.code?.toUpperCase(); 
        result = code !== undefined && code.startsWith("ERR") ? errorData.message : alternativeText; 
      } else {
        if ( error instanceof InternalError && error.innerError !== undefined) {
          result = this.friendlyError(error.innerError, alternativeText);
        }
      }
    }
    return result;
  }
}
