<div class="container-list" tabindex="0">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="data">
        <ng-container matColumnDef="sku">
            <th mat-header-cell *matHeaderCellDef class="tituloCabecera"> SKU </th>
            <td mat-cell *matCellDef="let element"> {{!element.packingLine ? element.line.sku : ''}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef class="tituloCabecera"> Descr. </th>
            <td mat-cell *matCellDef="let element"> {{!element.packingLine ? element.line.description : ''}} </td>
        </ng-container>

        <ng-container matColumnDef="pending">
            <th mat-header-cell *matHeaderCellDef class="tituloCabecera"> Pending </th>
            <td mat-cell *matCellDef="let element"><ng-container
                    *ngIf="element.line.pending !== 0">{{!element.packingLine ? element.line.pending:
                    ''}}</ng-container></td>
        </ng-container>

        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef class="tituloCabecera"> Total </th>
            <td mat-cell *matCellDef="let element"><ng-container *ngIf="element.line.total !== 0">
                    {{!element.packingLine ? element.line.total : ''}} </ng-container></td>
        </ng-container>

        <ng-container matColumnDef="quantityRegistered">
            <th mat-header-cell *matHeaderCellDef class="tituloCabecera"> Regist. </th>
            <td mat-cell *matCellDef="let element"><ng-container *ngIf="element.line.quantityRegistered !== 0">
                {{!element.packingLine ? element.line.quantityRegistered : ''}}</ng-container></td>
        </ng-container>

        <ng-container matColumnDef="gift">
            <th mat-header-cell *matHeaderCellDef class="tituloCabecera"> Gift </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.line.gift !== 0" [matBadge]="element.line.gift"
                    matBadgeColor="accent">card_giftcard</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="tituloCabecera"> Status </th>
            <td mat-cell *matCellDef="let element" class="statusColumn">
                <app-packing-state [state]="element.packingState"
                    [testId]="'status'+element.line.sku"></app-packing-state>
            </td>
        </ng-container>

        <ng-container matColumnDef="boxId">
            <th mat-header-cell *matHeaderCellDef class="tituloCabecera"> Box </th>
            <td mat-cell *matCellDef="let element"><ng-container
                    *ngIf="element.boxId !== undefined">{{element.boxId}}/{{element.boxModelType}}</ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="itemPackedCount">
            <th mat-header-cell *matHeaderCellDef class="tituloCabecera"> Box T. </th>
            <td mat-cell *matCellDef="let element"> {{element.boxTotal === 0 ? '': element.boxTotal}} </td>
        </ng-container>

        <ng-container matColumnDef="orderId">
            <th mat-header-cell *matHeaderCellDef class="tituloCabecera"> Order </th>
            <td mat-cell *matCellDef="let element">{{element.line.orderId}}</td>
        </ng-container>

        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions" class="tituloCabecera">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row" *ngIf="element.boxList.length > 0"
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" colspan="9" class="subTable">
                <div class="element-detail subTable" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <table mat-table [dataSource]="element.boxList" class="data">
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef class="tituloCabecera">&nbsp;</th>
                            <td mat-cell *matCellDef="let element" class="statusColumn statusSubTable">
                                <app-packing-state [state]="element.status"
                                    [testId]="'statusboxId'+element.boxId"></app-packing-state>
                            </td>
                        </ng-container>
                
                        <ng-container matColumnDef="boxId">
                            <td mat-cell *matCellDef="let element"><ng-container
                                    *ngIf="element.boxId !== undefined">{{element.boxId}}/{{element.boxModelType}}</ng-container>
                            </td>
                        </ng-container>
                
                        <ng-container matColumnDef="itemPackedCount">
                            <td mat-cell *matCellDef="let element"> {{element.itemPackedCount === 0 ? '': element.itemPackedCount}} </td>
                        </ng-container>
                
                        <tr mat-row *matRowDef="let row; columns: ['status', 'boxId', 'itemPackedCount'];"></tr>
                    </table>                    
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
        <tr mat-row [class.selectRow]="selection.isSelected(row)" *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.toggle(row)"
            [ngClass]="selectKey === row.lineKey ? 'element-row selectRow': 'element-row'"
            [class.example-expanded-row]="expandedElement === row" [appScrollIntoView]="selectKey === row.lineKey"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
</div>