import { Injectable } from '@angular/core';
import { LogisticDocument } from '../model/logistic-document';
import { PrintService, PrintStatus } from 'src/app/print/services/print.service';
import { NEVER, Observable, Subject, catchError, combineLatest, concatMap, forkJoin, from, lastValueFrom, map, mergeMap, of, repeat, repeatWhen, switchMap, take, tap, throwError, toArray } from 'rxjs';
import { InternalError } from 'src/app/common-app/model/internal-error';
import { DocumentDownloadService } from './document-download.service';
import { PDFDocument } from 'pdf-lib';
import { DocumentContentStatus } from '../model/document-content-status';
import { LogisticDocumentPdfContent } from '../model/logistic-document-pdf-content';

@Injectable({
  providedIn: 'root'
})
export class DocumentPrintService {

  constructor(
    private printService: PrintService,
    private documentDownloadService: DocumentDownloadService
  ) {

  }

  public groupByType(data: LogisticDocument[]): Map<string, LogisticDocument[]> {
    const result = new Map<string, LogisticDocument[]>();
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (result.has(element.type)) {
        const documentsInType = result.get(element.type) as LogisticDocument[];
        documentsInType?.push(element);
        result.set(element.type, documentsInType);
      } else {
        result.set(element.type, [element])
      }
    }
    return result;
  }

  public async printPdf(document: PDFDocument): Promise<PrintStatus> {
    return await lastValueFrom(this.printService.printPdf(document));
  }

  private async printPdfDocument(printList: Observable<PDFDocument[]>): Promise<PrintStatus[]> {
    let result: PrintStatus[] = [];
    const pdfDocumentList = await lastValueFrom(printList);
    for (let index = 0; index < pdfDocumentList.length; index++) {
      const element = pdfDocumentList[index];
      const status = await lastValueFrom(this.printService.printPdf(element));
      result.push(status);
    }
    return result;
  }

  public async printDocumentList(data: LogisticDocument[], downloadFile: boolean = true): Promise<PrintStatus[]> {
    let result: PrintStatus[] = [];
    if (data.length > 0) {
      const roundToPrint = this.groupByType(data);
      let compactPages: Observable<PDFDocument[]>;
      if (downloadFile) {
        compactPages = from(roundToPrint).pipe(
          mergeMap(itemByType => this.documentDownloadService.downloadFileList(itemByType[1])),
          catchError(error => throwError(() => new InternalError('PRINTPDF_0001', 'Fallo al recuperar el fichero', error, undefined))),
          mergeMap(docList => forkJoin(this.printService.convertListToPdf(docList.filter(doc => doc.content !== undefined).map(doc => doc.content as ArrayBuffer)))));
      } else {
        compactPages = from(roundToPrint).pipe(
          map(data => data[1].filter(doc => doc.pdfContent !== undefined && doc.pdfContent.content !== undefined).map(doc => doc.pdfContent?.content as PDFDocument)))
      }

      const printTask = compactPages.pipe(
        mergeMap(doc => this.printService.preparePrintPdf(doc)),
        toArray(),
        take(1)
      );
      result = await this.printPdfDocument(printTask);
    }
    return result;
  }

  private fillLogisticDocument(document: LogisticDocument): LogisticDocument {
    const result = document;
    result.documentStatus = DocumentContentStatus.Ok;
    if ((document.content === undefined && document.error !== undefined) ||
      (document.content !== undefined && document.content?.byteLength === 0)) {
      result.documentStatus = DocumentContentStatus.NoFileContent;
    }
    return result;
  }

  public fillDocumentList(data: LogisticDocument[]): Observable<LogisticDocument[]> {
    return this.documentDownloadService.downloadFileList(data).pipe(
      take(1),
      map(data => data.map(this.fillLogisticDocument)),
      switchMap(docList => {
        const result: Observable<LogisticDocument>[] = [];
        let item: Observable<LogisticDocument> | undefined = undefined;
        for (const iterator of docList) {
          if (iterator.content !== undefined && iterator.content.byteLength > 0) {
            item = this.printService.convertToPdf(iterator.content as ArrayBuffer).pipe(
              tap(data => {
                iterator.pdfContent = new LogisticDocumentPdfContent();
                iterator.pdfContent.content = data;
                iterator.pdfContent.error = undefined;
                iterator.documentStatus = DocumentContentStatus.Ok;
              }),
              map(_data => iterator),
              catchError(error => {
                iterator.pdfContent = new LogisticDocumentPdfContent();
                iterator.pdfContent.error = error;
                iterator.documentStatus = DocumentContentStatus.PdfError;
                return of(iterator);
              }));
          } else {
            iterator.documentStatus = DocumentContentStatus.NoFileContent;
            item = of(iterator);
          }
          result.push(item);
        }
        return forkJoin(result);

      }))
  }
}
