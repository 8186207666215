import { Injectable } from '@angular/core';
import { Order } from 'src/app/order/model/order';
import { PackedBoxService } from 'src/app/package/services/packed-box.service';

type BoxSelect = { sku: string, boxType: string, count: number };

@Injectable({
  providedIn: 'root'
})
export class BoxModelSelectorService {

  private boxTypeList: Map<string, string> = new Map<string, string>();

  constructor(private packedBoxService: PackedBoxService) { }

  public selectModel(order: Order): string | undefined {
    let boxType: string | undefined = undefined;
    if (this.checkIsPosibleSameBoxType(order)) {
      const sku = order.lines[0].sku;
      boxType = this.boxTypeList.get(this.getKey(sku, order.lines[0].total));
    }
    return boxType;
  }

  private getKey(sku: string, count: number): string {
    return `${sku}-${count}`;
  }

  private selectBoxTypeFromOrder(order: Order): BoxSelect | undefined {
    let result: BoxSelect | undefined = undefined;
    if (order.boxLines.length === 1 && order.lines.length === 1 && this.packedBoxService.boxIsIncidence(order.boxLines[0].boxId) === false) {
      result = { boxType: order.boxLines[0].boxType, sku: order.boxLines[0].sku, count: order.boxLines[0].count};
    }
    return result;
  }

  private checkIsPosibleSameBoxType(order: Order): boolean {
    return order.lines.length === 1 && (order.boxLines.length === 0 || (order.boxLines.length > 0 && (this.packedBoxService.boxIsIncidence(order.boxLines[0].boxId) === false)));
  }

  public registerModelFromOrder(order: Order): void {
    if (this.checkIsPosibleSameBoxType(order)){
      const boxSelect = this.selectBoxTypeFromOrder(order);
      if (boxSelect !== undefined) {
        this.boxTypeList.set(this.getKey(boxSelect.sku, boxSelect.count), boxSelect.boxType);
      }
    }
  }
}
