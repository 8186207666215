import { Component, OnDestroy, OnInit } from '@angular/core';
import { PassageAuthService } from '../services/passage-auth.service';
import { BridgeTokenService } from '../services/bridge-token.service';
import { Router } from '@angular/router';
import { TextNotificationService } from 'src/app/common-app/services/text-notification.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-bridge',
  templateUrl: './bridge.component.html',
  styleUrls: ['./bridge.component.scss']
})
export class BridgeComponent implements OnInit, OnDestroy {

  constructor(
    private passageAuthService: PassageAuthService,
    private bridgeTokenService: BridgeTokenService,
    private textNotificationService: TextNotificationService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }


  ngOnDestroy(): void {
    this.spinner.hide();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.passageAuthService.value.subscribe(data => {
      if (data !== undefined && data.isAuthenticated && data.token !== undefined) {
        this.bridgeTokenService.externalToken(data.token);
      }
    });
    this.bridgeTokenService.getToken().subscribe( {
      next: () => {
        this.textNotificationService.text("Security config success");
        this.router.navigate(['/user/login']);
      },
      error: (error) => {
        if (error.status !== undefined && error.status === 401) {
          this.textNotificationService.error('Login failed');
        } else {
          this.textNotificationService.error(error.message);
        }
        
      }
    });
  }

}
