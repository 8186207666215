import { Observable } from "rxjs";
import { OrderKey } from "src/app/order/model/order-key";
import { Box } from "../model/box";
import { BoxContentItem } from "../model/box-content-item";
import { ArticleIncidentType } from "src/app/incident/model/article-incident-type";
import { BoxPacked } from "../model/box-packed";
import { OrderState } from "src/app/order/model/order-state";
import { OrderIncidence } from "src/app/order/model/order-incidence";

export abstract class PackageOrderService {

    public abstract updateBoxType(boxModelId: string, slotId: string): Observable<Box>;
    public abstract getOrderContent(): Observable<BoxContentItem[]>;
    public abstract getBoxModel(): string | undefined;
    public abstract closeBox(orderId: OrderKey, weight: number, lastBox: boolean): Observable<BoxPacked>;
    public abstract resetBox(): void;
    public abstract pullItem(orderId: OrderKey, boxType: string, sku: string, barCode: string[], count: number): Observable<string>;
    public abstract pullIncident(orderId: OrderKey, sku: string, count: number, incidentValue: ArticleIncidentType): Observable<string | null>;
    public abstract getActiveBox(): Box | undefined;
    public abstract setActiveBoxType(id: string, boxModelId: string, closed: boolean): Observable<Box>;
    public abstract orderPullIncident(orderId: OrderKey, incidentValue: OrderIncidence): Observable<OrderState>;
    public abstract restartBoxOrder(orderId: OrderKey): Observable<any | null>
    public abstract changeBoxType(boxId: string, boxType: string): Observable<Box | undefined>
}