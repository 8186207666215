import { Injectable } from '@angular/core';
import { createStore, emitOnce } from '@ngneat/elf';
import { addEntities, getActiveEntity, getActiveId, resetActiveId, setActiveId, upsertEntities, withActiveId, withEntities } from '@ngneat/elf-entities';
import { PackageProcessState } from '../model/package-process-state';
import { PickType } from 'src/app/picking/manage-pick/model/pick-type';

@Injectable({
  providedIn: 'root'
})
export class PackagingProcessService {

  private store = createStore(
    { name: 'packagingProcess' },
    withEntities<PackageProcessState>(),
    withActiveId()
  );
  constructor() { }

  public updatePickType(pickType: PickType): void {
    emitOnce(() => {
      const active = this.store.query(getActiveEntity());
      if (active !== undefined) {
        active.pickType = pickType;
        this.store.update(upsertEntities(active));
      }
    });
  }
 
  public updateContainerId(containerId: string): void {
    emitOnce(() => {
      const active = this.store.query(getActiveEntity());
      if (active !== undefined) {
        active.containerId = containerId;
        this.store.update(upsertEntities(active));
      }
    });
  }  

  public getActive(): PackageProcessState | undefined {
    return this.store.query(getActiveEntity());
  }

  public resetActive(): void {
    this.store.update(resetActiveId());
  }

  public initProcess(): void {
    const id = new Date().getTime().toString();
    emitOnce(() => {
      this.store.update(resetActiveId());
      this.store.update(addEntities({ id, pickType: undefined, containerId: undefined}));
      this.store.update(setActiveId(id));
    });
  }
}
