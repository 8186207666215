import { Observable } from "rxjs";

export abstract class EnviromentContextService {

    public abstract enviroment: Observable<string | null>;
    public abstract company: Observable<string | null>;
    public abstract packingStation: Observable<string | null>;
    public abstract requestWeight: Observable<boolean>;

    public abstract getCompany(): string | null;
    public abstract getEnviroment(): string | null;

    public abstract setEnviroment(name: string): void;

    public abstract setCompany(name: string): void;

    public abstract setPackingStation(name: string): void;
    public abstract setRequestWeight(requestWeight: boolean): void;
    public abstract getRequestWeight(): boolean;
}
