import { immerable } from "immer";
import { PackingState } from "src/app/common-app/model/packing-state";
import { OrderLine } from "src/app/order/model/order-line";

export class OrderLinePackedBoxVo {
    [immerable] = true
    public boxId: string | undefined;
    public boxModelType: string | undefined;
    public itemPackedCount: number = 0;
    public status!: PackingState | undefined;
    public closed: boolean = false;
}

export class OrderLinePackedVo extends OrderLine {
    [immerable] = true
    public boxList : OrderLinePackedBoxVo[] = [];
}

