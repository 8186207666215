import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeliveryAction } from '../model/delivery-action';

@Component({
  selector: 'app-delivery-action-history',
  templateUrl: './delivery-action-history.component.html',
  styleUrls: ['./delivery-action-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryActionHistoryComponent implements OnInit {


  @Input()
  public actionList: DeliveryAction[] | null = null;
  @Output() public selectOrder: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  protected trackByFn(index: number, item: DeliveryAction) { return item.id; }

  protected selectItem(orderId: string) {
    this.selectOrder.emit(orderId);
  }

}
