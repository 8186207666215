import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthorizationTokenService } from './authorization-token.service';

@Injectable()
export class MockAuthorizationTokenService implements AuthorizationTokenService {

    private autenticateSubject = new BehaviorSubject<boolean>(false);
    private _tokenValue : string = "";
    public get tokenValue() : string {
        return this._tokenValue;
    }
    public set tokenValue(v : string) {
        this._tokenValue = v;
        this.autenticateSubject.next(v !== undefined && v.length > 0);
    }
    
    constructor() {
        this.tokenValue = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiJodHRwczovL2FwaS5idXNpbmVzc2NlbnRyYWwuZHluYW1pY3MuY29tIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvZWY0MmQzMTktNDdjOS00ZjZiLWIzN2YtZTc5NWQyYmQxYzVlLyIsImlhdCI6MTY5MzE2MzA5OCwibmJmIjoxNjkzMTYzMDk4LCJleHAiOjE2OTMxNjY5OTgsImFpbyI6IkUyRmdZSml5SVBYLzRzbWhoZVZjV2U4VUE2NGtBUUE9IiwiYXBwaWQiOiJkMWRjYzdmMS0xMDI3LTQxNDktOTBkYS00YzE4NGFiYTk2N2YiLCJhcHBpZGFjciI6IjEiLCJpZHAiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9lZjQyZDMxOS00N2M5LTRmNmItYjM3Zi1lNzk1ZDJiZDFjNWUvIiwiaWR0eXAiOiJhcHAiLCJvaWQiOiIyZjdiYTZlZC1jZGY0LTRkZjQtYTJiZC0wOWM3YmY0MzQ4ZWYiLCJyaCI6IjAuQVU4QUdkTkM3OGxIYTAtemYtZVYwcjBjWGozdmJabHNzMU5CaGdlbV9Ud0J1SjlQQUFBLiIsInJvbGVzIjpbIkF1dG9tYXRpb24uUmVhZFdyaXRlLkFsbCIsImFwcF9hY2Nlc3MiLCJBUEkuUmVhZFdyaXRlLkFsbCIsIkFkbWluQ2VudGVyLlJlYWRXcml0ZS5BbGwiXSwic3ViIjoiMmY3YmE2ZWQtY2RmNC00ZGY0LWEyYmQtMDljN2JmNDM0OGVmIiwidGlkIjoiZWY0MmQzMTktNDdjOS00ZjZiLWIzN2YtZTc5NWQyYmQxYzVlIiwidXRpIjoiMVhzT3BVWWNLa3lleHJKeU1PUDlBQSIsInZlciI6IjEuMCJ9.gDaMny1JZKRj4xgWmsgnKsuy2MIaVsLOeliHy-QmYFXw8dWXfBUFg8fDoahTYa9Ii-CKjQfs9kI3qabidjdv8LTxeUMSltYK5omrf6660jMFwdgv0wxFUIayvSrjGZjIV_i-BZ0vxDvqaOnHiIBeuPKA35TMGVI3jFq7FGHenqTecNR0S7mOho11C669jGACKJxkbGete5e9yEN_jAwYuwy7FjcwO8jD-qqEHvNbtU6EQOr3sxwN-hFSE8gCkLGqBHtZTXF-dLcNejAADaGG_fF7bYsW2qA16iIdGmJerCTGLLjkwgcqqK_Q0B_afjgCY-qLwL3OGSesUjNBhIlZcg';
     }
    public getExternalToken(): Observable<string> {
        throw new Error('Method not implemented.');
    }
    public isAutenticated(): Observable<boolean> {
        return this.autenticateSubject.asObservable();;
    }

    public getToken(): Observable<string> {
        return of(this._tokenValue);
    }
}