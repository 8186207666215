import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WeighingScalePageComponent } from './weighing-scale-page/weighing-scale-page.component';
import { MainPageComponent } from '../layout/main-page/main-page.component';


const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
    children: [
      { path: 'weighingScale', component: WeighingScalePageComponent }
    ]
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeviceRoutingModule { }