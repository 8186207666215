import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScanToBoxService {

  private history: [string, boolean][] = [];

  constructor() { }

  public scan(code: string, complete: boolean) {
    this.history.push([code, complete]);
  }

  protected peek(): [string, boolean] | undefined {
    if (this.history.length > 0) {
      return this.history[this.history.length - 1];
    } else {
      return undefined;
    }
  }

  public completeLast() {
    const item = this.peek();
    if (item != undefined) {
      item[1] = true;
    }
  }

  public getLastUnCompleted(): string | undefined {
    const item = this.peek();
    if (item !== undefined && item[1] === false) {
      return item[0];
    } else {
      return undefined;
    }
  }

  public getLastCompleted(): string | undefined {
    for (let i = this.history.length - 1; i >= 0; i--) {
      const item = this.history[i];
      if (item[1] === true) {
        return item[0];
      }
    }
    return undefined;
  }
}
