import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BoxContentItemRepository } from '../services/state/box-content-item.repository';
import { map } from 'rxjs/operators';
import { BoxContentItem } from '../model/box-content-item';
import { Observable } from 'rxjs';

export class BoxContentView {
  public boxId: string = '';
  public content: BoxContentItem[] = [];
}

@Component({
  selector: 'app-box-content-table',
  templateUrl: './box-content-table.component.html',
  styleUrls: ['./box-content-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxContentTableComponent implements OnInit {
  dataSource$: Observable<BoxContentView[]>;
  columnsToDisplay = ['Box', 'boxType'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: string | null = null;

  constructor(private boxContentItemRepository: BoxContentItemRepository) {
    this.dataSource$ = this.boxContentItemRepository.boxContentItem$.pipe(map(data => {
      const groupResult = data.reduce<Map<string, BoxContentItem[]>>((acc, value) => {
        let group = acc.get(value.boxId);
        // Group initialization
        if (group === undefined) {
          group = [];
          acc.set(value.boxId, group);
        }

        // Grouping
        group.push(value);

        return acc;
      }, new Map<string, BoxContentItem[]>());

      const result: BoxContentView[] = [];
      groupResult.forEach((value, key) => result.push({
        boxId: key,
        content: value
      }))
      return result;
    }))
  }

  ngOnInit(): void {
  }

}
