import { Injectable } from '@angular/core';
import { OrderKey } from 'src/app/order/model/order-key';
import { OrderLine } from 'src/app/order/model/order-line';
import { OrderLineStatus } from 'src/app/order/model/order-line-status';
import { IncidentApiService } from './incident-api.service';
import { Observable, map, throwError } from 'rxjs';
import { OrderService } from 'src/app/order/services/order.service';
import { InternalError } from 'src/app/common-app/model/internal-error';
import { ArticleIncidentType } from '../model/article-incident-type';
import { OrderApiHelper } from 'src/app/order/services/order-api-helper';
import { OrderState } from 'src/app/order/model/order-state';
import { OrderLineIncidentView } from '../model/order-line-incident-view';
import { OrderIncidence } from 'src/app/order/model/order-incidence';

@Injectable({
  providedIn: 'root'
})
export class IncidentService {

  private statusList = [OrderLineStatus.Incident, OrderLineStatus.Damage, OrderLineStatus.Missing];

  constructor(
    private incidentApiService: IncidentApiService,
    private orderService: OrderService,
  ) { }

  public filterIncident(lines: OrderLineIncidentView[]): OrderLineIncidentView[] {
    return lines !== undefined && lines !== null ?
      lines.filter(value => value.incidentType !== undefined) : [];
  }

  public filterIncidentByType(type: ArticleIncidentType, lines: OrderLineIncidentView[]): OrderLineIncidentView[] {
    return lines !== undefined && lines !== null ? lines.filter(value =>  value.incidentType === type) : [];
  }

  public filterDamage(lines: OrderLineIncidentView[]): OrderLineIncidentView[] {
    return this.filterIncidentByType(ArticleIncidentType.Damaged, lines);
  }

  public filterMissing(lines: OrderLineIncidentView[]): OrderLineIncidentView[] {
    return this.filterIncidentByType(ArticleIncidentType.Missing, lines);
  }

  public declareIncident(orderId: OrderKey,
    sku: string, count: number, status: ArticleIncidentType): Observable<OrderState> {
    const order = this.orderService.getOrder(orderId);
    if (order === undefined) {
      return throwError(() => new InternalError('DECLAREINCIDENT_2', 'No se puedo recuperar la orden para declarar el incidente', undefined, undefined));
    } else {
      return this.incidentApiService
        .declareIncident(order.pickId, order.id, sku, count, status)
        .pipe(
          map(data => {
            return data.Datos.length === 0 ? OrderState.Unknow : OrderApiHelper.convertStatus(data.Datos[0]['Order Status'])
          })
        )
    }
  }

  public declareOrderIncident(orderId: OrderKey, incidence: OrderIncidence): Observable<OrderState> {
    const order = this.orderService.getOrder(orderId);
    if (order === undefined) {
      return throwError(() => new InternalError('DECLAREINCIDENT_2', 'No se puedo recuperar la orden para declarar el incidente', undefined, undefined));
    } else {
      return this.incidentApiService
        .declareOrderIncident(order.pickId, order.id, incidence.articleIncidentType)
        .pipe(
          map(data => {
            return data.Datos.length === 0 ? OrderState.Unknow : OrderApiHelper.convertStatus(data.Datos[0]['Order Status'])
          })
        )
    }
  }
}
