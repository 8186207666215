import { Injectable } from '@angular/core';
import { Observable, forkJoin, mergeMap, take, throwError } from 'rxjs';
import { BasicHttpClientService } from 'src/app/api/services/basic-http-client.service';
import { InternalError } from 'src/app/common-app/model/internal-error';
import { UserService } from 'src/app/security/services/user-service';
import { IncidentRequest } from '../model/api/incident-request';
import { ArticleIncidentType } from '../model/article-incident-type';
import { LeerProductoContenedorEmpaquetadoResponse } from 'src/app/package/model/api/leer-producto-contenedor-empaquetado-response';
import { IncidentApiHelper } from './incident-api-helper';
import { ComposeUrlService } from 'src/app/api/services/compose-url.service';
import { ProductivityService } from 'src/app/productivity/services/productivity.service';

@Injectable({
  providedIn: 'root'
})
export class IncidentApiService {

  constructor(
    private httpClient: BasicHttpClientService,
    private composeUrlService: ComposeUrlService,
    private userService: UserService,
    private productivityService: ProductivityService
  ) { }

  public declareIncident(pickId: string, orderId: string, 
    sku: string, count: number, status: ArticleIncidentType): Observable<LeerProductoContenedorEmpaquetadoResponse> {
      return forkJoin([
        this.userService.selectUserName().pipe(take(1)),
        this.productivityService.selectProductivity().pipe(take(1))]).pipe(
      mergeMap(data => {
        const incidentType = IncidentApiHelper.convertToApi(status);
        if (incidentType === undefined) {
          return throwError(() => new InternalError('DECLAREINCIDENT_1', 'El tipo de incidente no esta mapeado', undefined, undefined));
        }
        const query: IncidentRequest = {
          iId: data[1] as number,
          cDocumento: pickId,
          cPedido: orderId,
          cProducto: sku,
          cTipoIncidencia: incidentType,
          dCantidad: count,
          cUsuarioPDA: data[0] as string
        };
        return this.httpClient
          .post<IncidentRequest, LeerProductoContenedorEmpaquetadoResponse>(this.composeUrlService.composeUrl(`CosmosApi_MarcarProductoIncidenciaEmpaquetado`), query)
      })
    );
  }

  public declareOrderIncident(pickId: string, orderId: string, status: ArticleIncidentType): Observable<LeerProductoContenedorEmpaquetadoResponse> {
    return this.declareIncident(pickId, orderId, '', 0, status);
  }
}
