import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { EnviromentContextService } from '../services/enviroment-context-service';

@Injectable({
  providedIn: 'root'
})
export class EnviromentGuard implements CanActivate {

  constructor(private enviromentContextService: EnviromentContextService, private router: Router) {  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.enviromentContextService.getEnviroment() === null) {
      window.alert('Enviroment not config');
      this.router.navigate(['/enviroment/config']);
    }
    return true;
  }
  
}
