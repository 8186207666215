import { Observable } from "rxjs";
import { Order } from "../model/order";
import { OrderKey } from "../model/order-key";
import { OrderLine } from "../model/order-line";
import { OrderLineStats } from "../model/order-line-stats";
import { Box } from "src/app/package/model/box";
import { OrderState } from "../model/order-state";
import { ArticleIncidentType } from "src/app/incident/model/article-incident-type";
import { OrderIncidence } from "../model/order-incidence";

export abstract class OrderService {
  
  constructor() { }
  public abstract selectOrder(id: OrderKey, initialReset: boolean): Observable<Order | undefined>;
  public abstract getOrder(orderKey: OrderKey): Order | undefined;
  public abstract newLines(orderId: string, lines: OrderLine[]): Observable<OrderLine[]>;
  public abstract updateLines(orderId: string, lines: OrderLine[]): Observable<OrderLine[]>;
  public abstract updatePending(orderId: OrderKey | undefined, barCodeOrSku: string, increment: number, box: Box | undefined, persist: boolean): OrderLine | undefined;
  public abstract updateLine(orderId: string, lines: Partial<OrderLine>[], box: Box | undefined): void;
  public abstract getLine(orderId: OrderKey | undefined, barCodeOrSku: string): OrderLine[];
  public abstract checkArticle(i: OrderLine, barCodeOrSku: string, orderId: string | undefined): boolean;
  public abstract calculateStats(lines: OrderLine[] | undefined): OrderLineStats | undefined;
  public abstract calculatePending(line: OrderLine): number
  public abstract resetCache(): void;
  public abstract closeBox(orderId: OrderKey | undefined, boxId: string): void;
  public abstract updateStatus(orderKey: OrderKey, status: OrderState | undefined): void;
  public abstract updateIncident(orderId: OrderKey, newState: OrderState,incidentValue: ArticleIncidentType, barCodeOrSku: string, increment: number): void;
  public abstract updateOrderIncident(orderId: OrderKey, newState: OrderState, incidentValue: OrderIncidence): void;
  public abstract reInitOrder(orderId: OrderKey): Observable<Order | undefined>;
  public abstract updateBoxModelType(boxId: string, boxModelId: string): void;
  public abstract refreshOrder(orderId: OrderKey): Observable<Order | undefined>;
}
