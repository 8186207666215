<ng-container *ngIf="((dataSource$ | async) || []) as data">
    <ng-container *ngIf="data.length === 0; else withData">
        <div style="display: flex; 
                flex-direction: column;
                width: 100%;
                align-items:center; 
                justify-content: center; ">
            <mat-icon class="customIconSize">
                grid_off
            </mat-icon>
            <span style="font-weight: bold;padding-top: 5px;">Items not packed</span>
        </div>
    </ng-container>

    <ng-template #withData>
        <table mat-table [dataSource]="data" multiTemplateDataRows class="mat-elevation-z8">
            <ng-container matColumnDef="Box">
                <th mat-header-cell *matHeaderCellDef> Box </th>
                <td mat-cell *matCellDef="let element"> {{element.boxId}} </td>
            </ng-container>
            <ng-container matColumnDef="boxType">
                <th mat-header-cell *matHeaderCellDef> Box type </th>
                <td mat-cell *matCellDef="let element"> {{element.content[0].boxType}} </td>
            </ng-container>
            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button aria-label="expand row"
                        (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                    <div class="example-element-detail"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <app-box-content-detail [content]="element.content"></app-box-content-detail>

                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
    </ng-template>
</ng-container>