import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, defaultIfEmpty, isEmpty, Observable, of, share, shareReplay, switchMap, tap, throwError } from "rxjs";
import { ErrorApi } from "src/app/api/model/error-api";
import { ErrorData } from "src/app/api/model/error-data";
import { OrderPackagingContainerService } from "./order-packaging-container.service";
import { ODataCustomError } from "src/app/api/model/odata-custom-error";
import { OrderRepository } from "./state/order.repository";
import { OrderContainer } from "../model/order-container";
import { OrderPackagingContainerApiService } from "./order-packaging-container-api.service";
import { tag } from "rxjs-spy/operators";


@Injectable()
export class OrderPackagingContainerCacheService extends OrderPackagingContainerService {

    constructor(
        private orderPackagingContainerApiService: OrderPackagingContainerApiService,
        private orderRepository: OrderRepository) {
        super();
    }

    private processError(originalError: HttpErrorResponse | ODataCustomError): ErrorData {
        const result = new ErrorData();
        const oDataError = originalError as ODataCustomError;
        if (oDataError.data !== undefined) {
            result.code = oDataError.data.Code;
            result.message = oDataError.data.Description;
        } else {
            const source = originalError as HttpErrorResponse;
            result.originalCode = source.status.toString();
            result.message = `Error en el acceso al api ${result.originalCode}`;
            const errorApi = source.error as ErrorApi;
            switch (source.status) {
                case 400:
                    if (errorApi.error !== undefined) {
                        if (errorApi.error.message.indexOf('que no se puede encontrar en la tabla relacionada') >= 0
                            || errorApi.error.message.indexOf('that cannot be found in the related table ') >= 0) {
                            result.code = 'API_404';
                            result.message = 'Elemento no encontrado';
                        }
                    }
                    break;
                case 401:
                    result.code = 'API_401';
                    result.message = 'Sin autorizacion para la operacion';
                    break;
                default:
                    break;
            }
        }

        return result;
    }

    private internalGetOrder(packagingContainerId: string): Observable<OrderContainer | undefined> {
        return this.orderPackagingContainerApiService.getOrderPackagingContainer(packagingContainerId).pipe(
            tag(`OrderPackagingContainerCacheService.internalGetOrder-${packagingContainerId}`),
            catchError((error) => throwError(() => this.processError(error))),
            tap(data => {
                if (data !== undefined) {
                    this.orderRepository.setOrderContainer(data);
                }
            }),
            this.orderRepository.skipWhileOrderContainerCached(`orderContainer-${packagingContainerId}`)
        );
    }

    public resetCache() {
        this.orderRepository.resetCache();
    }

    private cleanId(id: string): string {
        return id.trim().toUpperCase();
    }

    public getOrderPackagingContainer(packagingContainerId: string, initialReset: boolean = false): Observable<OrderContainer | undefined> {
        if (initialReset) {
            this.resetCache();
        }
        const cleanId = this.cleanId(packagingContainerId);
        const action = this.internalGetOrder(cleanId).pipe(shareReplay(1));
        return action.pipe(
            tag(`OrderPackagingContainerCacheService.getOrderPackagingContainer-${cleanId}`),
            isEmpty(),
            switchMap((isEmpty: boolean) => {
                if (isEmpty) {
                    return of(this.orderRepository.getOrderContainerById(cleanId));
                } else {
                    return action;
                }
            }),
        );
    }
}