import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnviromentContextService } from './services/enviroment-context-service';
import { ConfigComponent } from './config/config.component';
import { EnviromentRoutingModule } from './enviroment-routing.module';
import { CommonAppModule } from "../common-app/common-app.module";
import { MatIconModule } from '@angular/material/icon';
import { StorageEnviromentContextService } from './services/storage-enviroment-context.service';
import { AppNameService } from './services/app-name-service';



@NgModule({
    declarations: [
        ConfigComponent
    ],
    imports: [
        CommonModule,
        EnviromentRoutingModule,
        CommonAppModule,
        MatIconModule
    ]
})
export class EnviromentModule { 
  static forRoot(): ModuleWithProviders<EnviromentModule> {
    return {
      ngModule: EnviromentModule,
      providers: [
        AppNameService,
        StorageEnviromentContextService,
        { provide: EnviromentContextService, useExisting: StorageEnviromentContextService }
      ]
    };
  }
}
