import { Component, Input, OnInit } from '@angular/core';
import { PackingState } from '../model/packing-state';

@Component({
  selector: 'app-packing-state',
  templateUrl: './packing-state.component.html',
  styleUrls: ['./packing-state.component.scss']
})
export class PackingStateComponent implements OnInit {

  @Input() public state: PackingState | undefined;
  @Input() public testId: string | undefined;
  @Input() public status: string | undefined;
  protected packingState = PackingState;

  constructor() { }

  ngOnInit(): void {
  }

}
