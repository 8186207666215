import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BoxContentItem } from '../model/box-content-item';

@Component({
  selector: 'app-box-content-detail',
  templateUrl: './box-content-detail.component.html',
  styleUrls: ['./box-content-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxContentDetailComponent implements OnChanges {
  displayedColumns: string[] = ['sku', 'count'];

  @Input() public content: BoxContentItem[] | null = null;
  protected dataSource: BoxContentItem[] = [];

  constructor() { }


  ngOnChanges(changes: SimpleChanges): void {
    const contentChange = changes['content'];
    if (contentChange !== undefined && contentChange.currentValue !== null) {
      this.dataSource = contentChange.currentValue;
    } else  {
      this.dataSource = [];
    }
  }

}
