<form [formGroup]="configForm" (ngSubmit)="onSubmit()" class="config-form">
    <div class="full-width">
        <mat-form-field>
            <mat-label>URL</mat-label>
            <input matInput id="url" formControlName="url" type="text">
        </mat-form-field>
    </div>
    <div class="half-width">
        <mat-form-field>
            <mat-label>Packing Station</mat-label>
            <input matInput id="packingStation" formControlName="packingStation" type="text">
        </mat-form-field>
    </div>
    <div class="half-width">
        <mat-form-field>
            <mat-label>Company</mat-label>
            <input matInput id="company" formControlName="company" type="text">
        </mat-form-field>
    </div>
    <div class="half-width">
        <mat-form-field>
            <mat-label>Environment</mat-label>
            <input matInput id="environment" formControlName="environment" type="text">
        </mat-form-field>
    </div>
    <div class="half-width">
        <mat-checkbox id="requestWeight" formControlName="requestWeight">Request Weight</mat-checkbox>
    </div>
    <div class="full-width">
        <mat-table [dataSource]="printers.controls" formArrayName="printers" class="mat-elevation-z8">
            <ng-container matColumnDef="documentType">
                <th mat-header-cell *matHeaderCellDef> Document Type </th>
                <td mat-cell *matCellDef="let printer; let i = index;" [formGroupName]="i">
                    <input matInput formControlName="documentType" type="text">
                </td>
            </ng-container>
            <ng-container matColumnDef="printerName">
                <th mat-header-cell *matHeaderCellDef> Printer Name </th>
                <td mat-cell *matCellDef="let printer; let i = index;" [formGroupName]="i">
                    <input matInput formControlName="printerName" type="text">
                </td>
            </ng-container>
            <ng-container matColumnDef="scale">
                <th mat-header-cell *matHeaderCellDef> Scale </th>
                <td mat-cell *matCellDef="let printer; let i = index;" [formGroupName]="i">
                    <mat-form-field>
                        <mat-select formControlName="scale">
                            <mat-option value="">Normal</mat-option>
                            <mat-option value="fit">Fit</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>
            <ng-container matColumnDef="paperSize">
                <th mat-header-cell *matHeaderCellDef> Paper Size </th>
                <td mat-cell *matCellDef="let printer; let i = index;" [formGroupName]="i">
                    <input matInput formControlName="paperSize" type="text">
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let printer; let i = index;" [formGroupName]="i">
                    <button mat-icon-button type="button" (click)="removePrinter(i)">
                        <mat-icon>remove_circle</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['documentType', 'printerName', 'scale', 'paperSize', 'actions']"></tr>
            <tr mat-row
                *matRowDef="let row; columns: ['documentType', 'printerName', 'scale', 'paperSize', 'actions'];"></tr>
        </mat-table>
        <button mat-button type="button" (click)="addPrinter()">Add Printer</button>
    </div>
    <div class="full-width">
        <button mat-raised-button color="primary" type="submit">Save</button>
    </div>
</form>