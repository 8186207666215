import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Observable, Subject, map, takeUntil } from 'rxjs';
import { OrderService } from 'src/app/order/services/order.service';
import { Order } from 'src/app/order/model/order';
import { OrderUtilHelper } from 'src/app/order/util/order-util-helper';
import { OrderKey } from 'src/app/order/model/order-key';
import { OrderRepository } from 'src/app/order/services/state/order.repository';
import { PickType } from 'src/app/picking/manage-pick/model/pick-type';

type ParamsNavigate = { onlyView: boolean, pickType: PickType | undefined, containerId: string};

@Component({
  templateUrl: './package-instructions.component.html',
  styleUrls: ['./package-instructions.component.scss']
})
export class PackageInstructionsComponent implements OnInit, OnDestroy {

  private orderId: OrderKey | undefined;
  private containerId: string | undefined;
  private pickingType: PickType | undefined;
  private onlyView: boolean = false;
  public order$: Observable<Order | undefined> = of(undefined);
  public destroy$ = new Subject<void>();

  constructor(
    private orderService: OrderService,
    private orderRepository: OrderRepository,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  private checkInstructions(order$: Observable<Order | undefined>) {
    order$.pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data !== undefined) {
          let found = false;
          for (let index = 0; index < data.lines.length && !found; index++) {
            const element = data.lines[index];
            found = element.instructions !== undefined && element.instructions.length > 0
          }
          if (!found) {
            this.router.navigate(['/package/order', this.composeParamsToNavigate()]);
          }
        }
      });
  }
  ngOnInit(): void {

    this.activatedRoute.paramMap.subscribe(params => {
      this.onlyView = params.get('onlyView') !== null ? params.get('onlyView') === 'true' : false;
      this.pickingType = params.get('pickType') != null ? params.get('pickType') as unknown as PickType : undefined; 
      this.containerId = OrderUtilHelper.getContainerId(params);
      this.orderId = OrderUtilHelper.getOrderKey(params);
      if (this.orderId !== undefined) {
        this.order$ = this.orderService.selectOrder(this.orderId, false);
        this.checkInstructions(this.order$);
      } else {
        this.order$ = this.orderRepository.activeOrder$.pipe(map(data => data === undefined ? undefined : data[0]));
        this.checkInstructions(this.order$);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private composeParamsToNavigate(): ParamsNavigate {
    const result = {} as ParamsNavigate;
    if (this.containerId !== undefined) {
      result.containerId = this.containerId;
    }
    if (this.onlyView !== undefined) {
      result.onlyView = this.onlyView;
    }
    if (this.pickingType !== undefined) {
      result.pickType = this.pickingType;
    }
    return result;
  }
  protected onHistory(): void {
    this.router.navigate(['/manage/batchPick',  this.composeParamsToNavigate()]);
  }

  protected onContinue(): void {
    this.router.navigate(['/package/order', this.composeParamsToNavigate()]);
  }

}
