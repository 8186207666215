import { Inject, Injectable } from '@angular/core';
import { WINDOW } from 'src/app/common-app/services/window';
import { EnviromentContextService } from 'src/app/enviroment/services/enviroment-context-service';

@Injectable({
  providedIn: 'root'
})
export class ComposeUrlService {

  constructor(
    private enviromentContextService: EnviromentContextService,
    @Inject(WINDOW) private window: Window
  ) { }

  public composeUrl(endpoint: string): string {
    const company = this.enviromentContextService.getCompany();
    return `ODataV4/${endpoint}?Company=${company}`;
  }

  public composeToolsUrl(endpoint: string): string {
    switch (this.window.location.hostname) {
      case 'localhost':
        return `http://localhost:7071/api/${endpoint}`;
      case 'test.cosmosomni.com':
        return `https://cosmos-sga.azurewebsites.net/api/${endpoint}`;
      case 'brownie.cosmosomni.com':
        return `https://sgaonlauthusers.azurewebsites.net/api/${endpoint}`;
      default:
        return '';
    }
  }
}
