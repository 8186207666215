<div class="container">
    <div class="trolley">{{containerId | uppercase}}</div>
    <div><app-container-counter-stats [stats]="stats" [orderCount]="orderCount"></app-container-counter-stats></div>
    <!-- <mat-grid-tile *ngFor="let item of stats">
        <ng-container [ngSwitch]="item[0]">
            <div *ngSwitchCase="waveStateType.Picked" class="pickedText">Picked: {{item[1]}}</div>
            <div *ngSwitchCase="waveStateType.Picking" class="pickedText">Picking: {{item[1]}}</div>
            <div *ngSwitchCase="waveStateType.Packing" class="packingText">Packing: {{item[1]}}</div>
            <div *ngSwitchCase="waveStateType.Packed" class="packedText">Packed: {{item[1]}}</div>
            <div *ngSwitchCase="waveStateType.Incidence" class="incidentText">Incident: {{item[1]}}</div>
        </ng-container>
    </mat-grid-tile> -->
</div>
