import { PackingState } from "src/app/common-app/model/packing-state";
import { OrderLineStatus } from "../model/order-line-status";
import { OrderLine } from "../model/order-line";
import { OrderHelper } from "./order-helper";
import { BoxContentItem } from "src/app/package/model/box-content-item";

export class OrderLineHelper {
    public static composeId(orderId: string, lineNumber: number): string {
        return `${orderId}_${lineNumber}`
    }

    public static getPackingStatusFromBoxList(line: OrderLine, boxList: BoxContentItem[] | undefined): PackingState {
        let result = PackingState.Unknown;
        if (boxList !== undefined && boxList.length > 0) {
            const boxLine = OrderHelper.findContainerBySku(line.sku, boxList);
            if (boxLine !== undefined) {
                if (boxLine.closed) {
                    result = PackingState.Packed;
                } else {
                    if (line.pending === 0) {
                        result = PackingState.Packed;
                    } else {
                        result = PackingState.Packing;
                    }
                }
            } else {
                if (line.pending === 0) {
                    result = PackingState.Packing;
                }
            }
        }
        return result;
    }

    public static getCompletPackingStatus(line: OrderLine, boxLine: BoxContentItem[] | undefined): PackingState {
        let result = PackingState.Unknown;
        if (line.incidences !== undefined && line.incidences.length > 0) {
            result = PackingState.Incident;
        } else {
            if (line.status !== undefined && line.status !== "") {
                switch (line.status) {
                    case OrderLineStatus.Incident:
                    case OrderLineStatus.Damage:
                    case OrderLineStatus.Missing:
                        result = PackingState.Incident;
                        break;
                    default:
                        break;
                }
            } else {
                result = OrderLineHelper.getPackingStatusFromBoxList(line, boxLine);
            }
        }
        return result;
    }

    public static getPackingStatusFromOrderLine(line: OrderLine, boxContent: BoxContentItem[]): PackingState {
        const boxLine = OrderHelper.filterContainerBySku(line.sku, boxContent);
        return OrderLineHelper.getCompletPackingStatus(line, boxLine);
    }

    public static getStatus(packingState: PackingState): string {
        switch (packingState) {
            case PackingState.Incident:
                return OrderLineStatus.Incident;
            case PackingState.Packed:
                return OrderLineStatus.Packed;
            case PackingState.Packing:
                return OrderLineStatus.Pending;
            default:
                return '';
        }
    }

    public static sortOrderLineList(lines: OrderLine[]): OrderLine[] {
        return lines.sort((a, b) => {
            let result = a.sku.localeCompare(b.sku);
            if (result === 0) {
              result = a.pending - b.pending;
            }
            return result;
          });
    }

}