import { Pipe, PipeTransform } from '@angular/core';
import { OrderLinePackedVo } from './model/order-line-packed-vo';
import { PackingState } from '../common-app/model/packing-state';

@Pipe({
  name: 'sortOrderLinePackedVo'
})
export class SortOrderLinePackedVoPipe implements PipeTransform {

  private calculateOrdePackingState(a: PackingState): number {
    let result = 0;
    switch (a) {
      case PackingState.Packed:
        result = 3;
        break;
      case PackingState.Packing:
        result = 1;
        break;
      case PackingState.Incident:
        result = 2;
        break;
    }
    return result;
  }
  private calculateKey(a: OrderLinePackedVo): string {
    return `${a.pending > 0? 0 : 1}-${this.calculateOrdePackingState(a.packingStatus)}-${a.boxList !== undefined && a.boxList.length > 0 ? a.boxList[0].boxId: ''}-${a.sku}`;
  }
  private calculateOrder(a: OrderLinePackedVo, b: OrderLinePackedVo): number {
    const akey = this.calculateKey(a)
    const bkey = this.calculateKey(b)
    return akey.localeCompare(bkey);
  }

  transform(value: OrderLinePackedVo[] | undefined): OrderLinePackedVo[] {
    const result = value !== undefined ? [...value].sort((a, b) => this.calculateOrder(a,b)) : [];
    return result;
  }

}
