import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MenuItem } from '../model/menu-item';
import { EnviromentContextService } from 'src/app/enviroment/services/enviroment-context-service';
import { UserService } from 'src/app/security/services/user-service';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ErrorHistoryViewDialogComponent } from 'src/app/common-app/error-history-view-dialog/error-history-view-dialog.component';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnDestroy {

  mobileQuery: MediaQueryList;
  fillerNav: Array<MenuItem>;
  protected userText: string | undefined;
  protected companyName: string | undefined;
  protected enterpriseInfo: string = '';
  private destroy$ = new Subject<void>();
  protected version = environment.appVersion;
  protected requestWeight: boolean | undefined;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private userService: UserService,
    private dialog: MatDialog,
    protected enviromentContextService: EnviromentContextService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    const navAutheticated = new Array<MenuItem>(
      { title: "Inicio", url: "/manage/packingStation", testId: 'menuInicio' },
      { title: "User logout", url: "/security/logout", testId: 'menuUserLogout' },
      { title: "Token seguridad", url: "/security/init", testId: 'menuTokenSeguridad' },
      { title: "Device", url: "/device/weighingScale", testId: 'weighingScale' },
    );
    const navNotAutheticated = new Array<MenuItem>(
      { title: "Inicio", url: "/manage/packingStation", testId: 'menuInicio' },
      { title: "User login", url: "/security/login", testId: 'menuUserLogin' },
      { title: "Token seguridad", url: "/security/init", testId: 'menuTokenSeguridad' },
      { title: "Device", url: "/device/weighingScale", testId: 'weighingScale' },
    );
    this.fillerNav = navNotAutheticated;

    this.companyName = 'not selected'
    this.enviromentContextService.company.pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.companyName = data !== null ? data : 'not selected';
        this.enterpriseInfo = `Company: ${this.companyName}`;
      })
    this.enviromentContextService.requestWeight.pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.requestWeight = data;
      })

    this.userService.selectUserName().subscribe(
      {
        next: (data) => {
          if (data !== null) {
            this.userText = `User: ${data}`;
            this.fillerNav = navAutheticated;
          } else {
            this.userText = 'Unauthenticated ';
            this.fillerNav = navNotAutheticated;
          }
        }
      }
    )
  }

  public onViewErrors() {
    const dialogRef = this.dialog.open(ErrorHistoryViewDialogComponent , {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.destroy$.next();
    this.destroy$.complete();
  }
}
