import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

interface ScanFormData {
  scanId: string;
}

@Component({
  selector: 'app-scan-input',
  templateUrl: './scan-input.component.html',
  styleUrls: ['./scan-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScanInputComponent implements OnInit {

  protected _disabled: boolean = false;

  @Input() public set disabled(v: boolean) {
    this._disabled = v;
    if (v) {
      this.form.get('scanId')?.disable();
    } else {
      this.form.get('scanId')?.enable();
    }
  }

  @Output() onId: EventEmitter<string> = new EventEmitter<string>();
  protected form: FormGroup<{ scanId: FormControl<string> }>;
  constructor(private fb: FormBuilder) {
    this.form = this.fb.nonNullable.group<ScanFormData>({
      scanId: ''
    });
  }

  onSubmit() {
    const scanId = this.form.value.scanId;
    if (scanId && scanId.length > 0) {
      this.onId.emit(scanId);
      this.form.controls.scanId.setValue('');
    }
  }

  ngOnInit(): void {
  }

}
