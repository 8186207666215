import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TokenSecurityInitComponent } from './token-security-init/token-security-init.component';
import { SecurityRoutingModule } from './security-routing.module';
import { MockAuthorizationTokenService } from './services/mock-autorization-token.service';
import { AuthorizationTokenService } from './services/authorization-token.service';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ClientCrendentialApiService } from './services/client-crendential-api.service';
import { ClientCredentialTokenService } from './services/client-credential-token.service';
import { UserCustomService } from './services/user-custom-service';
import { UserService } from './services/user-service';
import { AuthenticationService } from './services/authentication-service';
import { AuthenticationApiService } from './services/authentication-api.service';
import { LoginComponent } from './login/login.component';
import { UserMockService } from './services/user-mock-service.service';
import { TemporalClientCredentialTokenService } from './services/temporal-client-credential-token.service';
import { LogoutComponent } from './logout/logout.component';
import { CommonAppModule } from '../common-app/common-app.module';



@NgModule({
  declarations: [
    TokenSecurityInitComponent,
    LoginComponent,
    LogoutComponent
  ],
  imports: [
    CommonModule,
    SecurityRoutingModule,
    CommonAppModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class SecurityModule {
  static forRoot(): ModuleWithProviders<SecurityModule> {
    return {
      ngModule: SecurityModule,
      providers: [
        // ClientCrendentialApiService,
        // { provide: AuthorizationTokenService, useClass: ClientCredentialTokenService }
        // MockAuthorizationTokenService,
        // { provide: AuthorizationTokenService, useExisting: MockAuthorizationTokenService },
        // TemporalClientCredentialTokenService,
        // { provide: AuthorizationTokenService, useExisting: TemporalClientCredentialTokenService },
        { provide: UserService, useClass: UserCustomService },
        { provide: AuthenticationService, useClass: AuthenticationApiService }
      ]
    };
  }

  static forRootTesting(): ModuleWithProviders<SecurityModule> {
    return {
      ngModule: SecurityModule,
      providers: [
        MockAuthorizationTokenService,
        { provide: AuthorizationTokenService, useExisting: MockAuthorizationTokenService },
        { provide: UserService, useClass: UserCustomService },
        { provide: AuthenticationService, useClass: AuthenticationApiService }
      ]
    };
  }
}
