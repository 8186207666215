import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeighingScaleInputComponent } from './weighing-scale-input/weighing-scale-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DeviceRoutingModule } from './device-routing.module';
import { WeighingScalePageComponent } from './weighing-scale-page/weighing-scale-page.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle'; 
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [
    WeighingScaleInputComponent,
    WeighingScalePageComponent
  ],
  imports: [
    DeviceRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    WeighingScaleInputComponent
  ]
})
export class DeviceModule { }
