import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MockAuthorizationTokenService } from '../services/mock-autorization-token.service';

interface TokenData {
  token: string;
}

@Component({
  templateUrl: './token-security-init.component.html',
  styleUrls: ['./token-security-init.component.scss']
})
export class TokenSecurityInitComponent implements OnInit {

  protected form: FormGroup<{ token: FormControl<string> }>;
  constructor(protected autorizationTokenService: MockAuthorizationTokenService,
    private fb: FormBuilder) { 
    this.form = this.fb.nonNullable.group<TokenData>({
      token: ''
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    const token = this.form.value.token;
    if (token && token.length > 0) {
      this.autorizationTokenService.tokenValue = token;
    }
  }

}
