import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductivityService {

  private store: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor() { }

  public selectProductivity(): Observable<number | undefined> { return this.store.pipe(distinctUntilChanged()); }
  public updateProductivity(value: number): void { this.store.next(value); }
  public reset(): void { this.store.next(0); }
}
