import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { EnviromentContextService } from './enviroment-context-service';

type StorageEnviromentContext = {enviroment: string | null, company: string | null, packingStation: string | null, requestWeight: boolean};
@Injectable()
export class StorageEnviromentContextService extends EnviromentContextService {

  private static readonly dataKey = 'context-data';
  private dataSubject = new BehaviorSubject<StorageEnviromentContext | null>(this.getFromLocalStorage(StorageEnviromentContextService.dataKey));

  constructor() {
    super();
  }

  private getFromLocalStorage(key: string): StorageEnviromentContext | null {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  public saveToLocalStorage(data: StorageEnviromentContext): void {
    localStorage.setItem(StorageEnviromentContextService.dataKey, JSON.stringify(data));
    this.dataSubject.next(data);
  }

  public get enviroment(): Observable<string | null> {
    return this.dataSubject.asObservable().pipe(map(data => data?.enviroment || null));
  }

  public get company(): Observable<string | null> {
    return this.dataSubject.asObservable().pipe(map(data => data?.company || null));
  }

  public get packingStation(): Observable<string | null> {
    return this.dataSubject.asObservable().pipe(map(data => data?.packingStation || null));
  }

  public get requestWeight(): Observable<boolean> {
    return this.dataSubject.asObservable().pipe(map(data => data?.requestWeight ?? true));
  }

  public getCompany(): string | null {
    return this.dataSubject.getValue()?.company || null;
  }

  public getEnviroment(): string | null {
    return this.dataSubject.getValue()?.enviroment || null;
  }

  public getPackingStation(): string | null {
    return this.dataSubject.getValue()?.packingStation || null;
  }

  private getDefaultValue(): StorageEnviromentContext {
    return {enviroment: null, company: null, packingStation: null, requestWeight: true};
  }

  public setEnviroment(name: string) {
    let data = this.dataSubject.getValue();
    if (data === null) {
      data = this.getDefaultValue();
    }
    data.enviroment = name;
    this.saveToLocalStorage(data);
    this.dataSubject.next(data);
  }

  public setCompany(name: string) {
    let data = this.dataSubject.getValue();
    if (data === null) {
      data = this.getDefaultValue();
    }
    data.company = name;
    this.saveToLocalStorage(data);
    this.dataSubject.next(data);
  }

  public setPackingStation(name: string) {
    let data = this.dataSubject.getValue();
    if (data === null) {
      data = this.getDefaultValue();
    }
    data.packingStation = name;
    this.saveToLocalStorage(data);
    this.dataSubject.next(data);
  }

  public setRequestWeight(requestWeight: boolean): void {
    let data = this.dataSubject.getValue();
    if (data === null) {
      data = this.getDefaultValue();
    }
    data.requestWeight = requestWeight;
    this.saveToLocalStorage(data);
    this.dataSubject.next(data);
  }
    public getRequestWeight(): boolean {
      const value = this.dataSubject.getValue()?.requestWeight;
      return value ?? true;
    }

}