import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { OrderState } from 'src/app/order/model/order-state';

@Component({
  selector: 'app-container-stats',
  templateUrl: './container-stats.component.html',
  styleUrls: ['./container-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerStatsComponent implements OnInit {

  protected waveStateType = OrderState;

  @Input() public containerId: string | undefined;
  @Input() public stats: [OrderState, number][] | null = null;
  @Input() public orderCount= 0;
  
  constructor() { }

  ngOnInit(): void {
  }

}
