import { Observable, map, switchMap, tap } from "rxjs";
import { BoxModelService } from "./box-model.service";
import { OrderBoxGroup } from "../model/order-box-group";
import { BoxContentItemRepository } from "./state/box-content-item.repository";
import { BoxModelApiService } from "./box-model-api.service";
import { Injectable } from "@angular/core";

@Injectable()
export class BoxModelCacheService implements BoxModelService {

    constructor(
        private boxContentItemRepository: BoxContentItemRepository,
        private boxModelApiService: BoxModelApiService) { }

    private internalGetBoxModel(): Observable<OrderBoxGroup | null> {
        return this.boxModelApiService.getBoxModel().pipe(
            tap(data => {
                if (data !== null) {
                    this.boxContentItemRepository.upsertBoxModel(data);
                }
            }),
            this.boxContentItemRepository.skipWhileBoxModelCached(`boxModel`)
        );
    }

    public getBoxModel(): Observable<OrderBoxGroup | null> {
        return this.boxContentItemRepository.isResquestBoxModelCached().pipe(
            switchMap(isCached => {
                if (isCached) {
                    return this.boxContentItemRepository.selectBoxModel().pipe(map(data => data !== undefined && data.length > 0 ? data[0] : null));
                } else {
                    return this.internalGetBoxModel();
                }
            })
        );
    }

}