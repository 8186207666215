import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';

import { PackageRoutingModule } from './package-routing.module';
import { PackageInstructionsComponent } from './package-instructions/package-instructions.component';
import { PackageOrderComponent } from './package-order/package-order.component';
import { OrderModule } from '../order/order.module';
import { CommonOperationsModule } from '../common-operations/common-operations.module';
import { OrderTableComponent } from './order-table/order-table.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SelectBoxComponent } from './select-box/select-box.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { BoxContentTableComponent } from './box-content-table/box-content-table.component';
import { BoxContentDetailComponent } from './box-content-detail/box-content-detail.component';
import { CommonAppModule } from '../common-app/common-app.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PackageOrderService } from './services/package-order.service';
import { PackageOrderCacheService } from './services/package-order-cache.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { ContainerStatsComponent } from './container-stats/container-stats.component';
import { BoxModelApiService } from './services/box-model-api.service';
import { BoxModelService } from './services/box-model.service';
import { BoxModelCacheService } from './services/box-model-cache.service';
import { QRCodeModule } from 'angularx-qrcode';
import { WeightDialogComponent } from './weight-dialog/weight-dialog.component';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ContainerCounterStatsComponent } from './container-counter-stats/container-counter-stats.component';
import { DeviceModule } from '../device/device.module';
import { DocumentModule } from '../document/document.module';
import { SortOrderLinePackedVoPipe } from './sort-order-line-packed-vo.pipe';
import { OrderLinePackedVoService } from './services/order-line-packed-vo.service';


@NgModule({
  declarations: [
    PackageInstructionsComponent,
    PackageOrderComponent,
    OrderTableComponent,
    SelectBoxComponent,
    BoxContentTableComponent,
    BoxContentDetailComponent,
    ContainerStatsComponent,
    WeightDialogComponent,
    ContainerCounterStatsComponent,
    SortOrderLinePackedVoPipe
  ],
  exports: [
    OrderTableComponent,
    ContainerStatsComponent,
    ContainerCounterStatsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PackageRoutingModule,
    CommonAppModule,
    OrderModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    CommonOperationsModule,
    MatSnackBarModule,
    MatGridListModule,
    MatListModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatChipsModule,
    MatToolbarModule,
    NgxSpinnerModule,
    MatExpansionModule,
    QRCodeModule,
    DeviceModule,
    DocumentModule
  ],
  providers: []
})
export class PackageModule {
  static forRoot(): ModuleWithProviders<PackageModule> {
    return {
      ngModule: PackageModule,
      providers: [
        BoxModelApiService,
        { provide: BoxModelService, useClass: BoxModelCacheService },
        { provide: PackageOrderService, useClass: PackageOrderCacheService },
        OrderLinePackedVoService
      ]
    };
  }
}
