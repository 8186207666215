<app-order-summary-sheet [order]="order$ | async"></app-order-summary-sheet>


<div class="wrapperOptions">

    <div class="cards">

        <mat-card class="box-card mat-elevation-z8">
            <mat-card-title-group>
                <mat-card-title>Gift</mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
            </mat-card-title-group>
            <mat-card-content>
                <div class="gift"><img src="assets/images/gift-icon.svg"></div>
            </mat-card-content>
        </mat-card>
        <mat-card class="box-card mat-elevation-z8">
            <mat-card-title-group>
                <mat-card-title>No price</mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
            </mat-card-title-group>
            <mat-card-content>
                <div class="noPrice"><img src="assets/images/no-price-tag.svg"></div>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="actions">
        <app-icon-button icon="history" (click)="onHistory()" testId="buttonHistory">History</app-icon-button>
        <app-icon-button icon="arrow_forward" (click)="onContinue()" testId="buttonContinue">Continue</app-icon-button>
    </div>
</div>