import { Injectable } from '@angular/core';
import { Order } from '../model/order';
import { LogisticDocument } from 'src/app/document/model/logistic-document';
import { OrderKey } from '../model/order-key';
import { OrderService } from './order.service';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderDocumentService {

  constructor(
    private orderService: OrderService,
  ) { }

  private convertOrderToLogisticDocument(order: Order): LogisticDocument[] {
    return order.boxList.map(boxLine => boxLine.documentList).flat().concat(order.documentList);
  }

  public selectOrderDocument(id: OrderKey): Observable<LogisticDocument[]> {
    return this.orderService.selectOrder(id, true).pipe(  
      map(order => order !== undefined ? this.convertOrderToLogisticDocument(order) : [])
    );
  }
}
