import { PackingState } from "src/app/common-app/model/packing-state";
import { OrderIncidence } from "./order-incidence";
import { OrderLineStatus } from "./order-line-status";
import { BoxContentItem } from "src/app/package/model/box-content-item";

export class OrderLine {
    public id!: string;
    public orderId!: string;
    public slotId!: string;
    public lineNumber!: number;
    public sku!: string;
    public barCode: string[] | undefined;
    public description!: string;
    public pending!: number;
    public total!: number;
    public gift!: number;
    public quantityRegistered: number = 0;
    public quantityPacked: number = 0;
    public status!: OrderLineStatus | undefined;
    public packingStatus!: PackingState;
    public instructions: string[] | undefined;
    public incidences: OrderIncidence[] | undefined;
    public boxContentItemList: BoxContentItem[] = [];
}
