<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- Position Column -->
    <ng-container matColumnDef="sku">
        <th mat-header-cell *matHeaderCellDef> SKU </th>
        <td mat-cell *matCellDef="let element"> {{element.sku}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="boxId">
        <th mat-header-cell *matHeaderCellDef> Box Nº </th>
        <td mat-cell *matCellDef="let element"> {{element.boxId}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="count">
        <th mat-header-cell *matHeaderCellDef> Total </th>
        <td mat-cell *matCellDef="let element"> {{element.count}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

