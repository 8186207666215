import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs';
import { tag } from 'rxjs-spy/operators';
import { TextNotificationService } from 'src/app/common-app/services/text-notification.service';
import { OrderKey } from 'src/app/order/model/order-key';
import { OrderService } from 'src/app/order/services/order.service';
import { OrderRepository } from 'src/app/order/services/state/order.repository';
import { BoxContentItemRepository } from 'src/app/package/services/state/box-content-item.repository';
import { PickType } from 'src/app/picking/manage-pick/model/pick-type';

@Injectable()
export class OrderNavigateService {

  constructor(
    private orderService: OrderService,
    private orderRepository: OrderRepository,
    private boxContentItemRepository: BoxContentItemRepository, 
    private spinner: NgxSpinnerService, 
    private router: Router, 
    private textNotificationService: TextNotificationService
  ) { }

  public navigateToOrder(orderId: string, containerId: string, slotId: string, pickType: PickType, onlyView: boolean = false): void {
    const orderKey = new OrderKey();
    orderKey.id = orderId;
    orderKey.containerId = containerId;
    orderKey.slotId = slotId;
    this.spinner.show();
    this.orderService.selectOrder(orderKey, false).pipe(
      tag('orderNavigateService.navigateToOrder.selectOrder'),
      take(1)).subscribe((data) => {
      if (data !== undefined) {
        this.orderRepository.setActiveId(orderKey.id as string);
        this.boxContentItemRepository.addContentItem(data.boxLines)
        this.router.navigate(['/package/instructions', { onlyView: onlyView }]);
      } else {
        this.textNotificationService.text('Not order data');
      }
      this.spinner.hide();
    });
  }
}
