<div class="container" [class.is-mobile]="mobileQuery.matches">
    <mat-toolbar class="title-toolbar">
        <button mat-icon-button (click)="snav.toggle()" data-test-id="buttonMenuPrincipal"><mat-icon>menu</mat-icon></button>
        <span class="example-spacer"></span>
        <span class="station">PACKING STATION:&nbsp;</span><span class="stationValue" data-test-id="toolbarStation">{{this.enviromentContextService.packingStation | async | nullWithDefault:'not selected' }}</span>
        <span class="station">&nbsp;Company:&nbsp;</span><span class="stationValue" data-test-id="toolbarWave">{{companyName}}</span>
        <span class="example-spacer"></span>
        <span class="userText">{{userText}}&nbsp;</span>
        <div class="title"><img src="assets/images/logocosmos.svg"></div>
    </mat-toolbar>


    <mat-sidenav-container cdkScrollable class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
        <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
            fixedTopGap="56">
            <mat-nav-list>
                <a mat-list-item [routerLink]="nav.url" *ngFor="let nav of fillerNav" [attr.data-test-id]="nav.testId">{{nav.title}}</a>
                <a mat-list-item  (click)="onViewErrors()" [attr.data-test-id]="'viewErrorList'">Errors</a>
                <a mat-list-item [routerLink]="'.'"  [attr.data-test-id]="'enterpriseInfo'">{{enterpriseInfo}}</a>
                <a mat-list-item [routerLink]="'.'"  [attr.data-test-id]="'enterpriseInfo'">Request weight : {{requestWeight}}</a>
                <a mat-list-item [routerLink]="'.'"  [attr.data-test-id]="'versionInfo'">v {{version}}</a>
            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content>
            <div class="sidenav-content">
            <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>