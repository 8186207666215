import { Component, OnInit } from '@angular/core';
import { HidService } from '../services/hid.service';

@Component({
  selector: 'app-weighing-scale-page',
  templateUrl: './weighing-scale-page.component.html',
  styleUrls: ['./weighing-scale-page.component.scss']
})
export class WeighingScalePageComponent implements OnInit {

  protected mesage: string = 'Inicio';
  constructor(
    private readonly hidService: HidService) { }

  ngOnInit(): void {
    this.hidService.onConnect().subscribe(data => { this.mesage = `Inicio de ${data.productName}`  });
  }
}
