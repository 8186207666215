import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderSummarySheetComponent } from './order-summary-sheet/order-summary-sheet.component';
import { MatCardModule } from '@angular/material/card';
import { OrderService } from './services/order.service';
import { OrderCacheService } from './services/order-cache.service';
import { OrderApiService } from './services/order-api.service';
import { OrderPackagingContainerApiService } from './services/order-packaging-container-api.service';
import { OrderPackagingContainerService } from './services/order-packaging-container.service';
import { OrderPackagingContainerCacheService } from './services/order-packaging-container-cache.service';
import { OrderRepository } from './services/state/order.repository';

@NgModule({
  declarations: [
    OrderSummarySheetComponent
  ],
  imports: [
    CommonModule,
    MatCardModule
  ],
  exports: [
    OrderSummarySheetComponent
  ],
})
export class OrderModule {
  public static forRoot(): ModuleWithProviders<OrderModule> {
    return {
      ngModule: OrderModule,
      providers: [
        OrderRepository,
        OrderApiService,
        OrderPackagingContainerApiService,
        { provide: OrderService, useClass: OrderCacheService },
        { provide: OrderPackagingContainerService, useClass: OrderPackagingContainerCacheService}
      ]
    }
    
  }
 }
