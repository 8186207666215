import { createStore, emitOnce } from '@ngneat/elf';
import { withEntities, selectAllEntities, updateEntities, deleteEntities, withActiveIds, selectActiveEntities, toggleActiveIds, getActiveEntities, getEntitiesCount, resetActiveIds, deleteAllEntities, upsertEntities, getEntity, removeActiveIds } from '@ngneat/elf-entities';
import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Box } from '../../model/box';

@Injectable({ providedIn: 'root' })
export class BoxRepository {
  activeBox$: Observable<Box[]>;
  box$: Observable<Box[]>;

  private store;

  constructor() {
    this.store = this.createStore();
    this.box$ = this.store.pipe(selectAllEntities());
    this.activeBox$ = this.store.pipe(selectActiveEntities());
  }

  addBox(box: Box, active: boolean) {
    emitOnce(() => {
      this.store.update(upsertEntities(box));
      const idBoxActive = this.getActiveBox();
      if (active && (idBoxActive.length == 0 || idBoxActive[0]._id !== box._id)) {
        this.toggleActiveIds([box._id]);
      }
    });
  }

  updateBox(id: Box['_id'], box: Partial<Box>) {
    this.store.update(updateEntities(id, box));
  }

  deleteBox(id: Box['_id']) {
    this.store.update(deleteEntities(id));
  }

  toggleActiveIds(ids: Array<Box['_id']>) {
    this.store.update(toggleActiveIds(ids));
  }

  public getActiveBox(): Box[] {
    return this.store.query(getActiveEntities());
  }

  public closeBox(boxId: string): void {
    emitOnce(() => {
      const box = this.store.query(getEntity(boxId));
      if (box !== undefined) {
        box.closed = true;
        this.store.update(updateEntities(boxId, box))
        this.store.update(removeActiveIds(boxId));
      }
    });
  }

  public getEntity(boxId: string): Box | undefined {
    return this.store.query(getEntity(boxId));
  }

  public resetBox(): void {
    emitOnce(() => {
      this.store.update(resetActiveIds());
      this.deleteAll();
    });
  }

  public deleteAll(): void {
    this.store.update(deleteAllEntities());
  }

  public selectActiveBoxModelId(): Observable<string | undefined> {
    return this.store.pipe(
      selectActiveEntities(),
      map(data => data.length == 0 ? undefined : data[0].boxModelId));
  }

  public getActiveBoxModelId(): string | undefined {
    const data = this.store.query(getActiveEntities());
    return data.length == 0 ? undefined : data[0].boxModelId;
  }
  private createStore(): typeof store {
    const store = createStore({ name: 'box' }, withEntities<Box, '_id'>({ idKey: '_id' }), withActiveIds());

    return store;
  }
}
