import { Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[appDisabledOnWorking]'
})
export class DisabledOnWorkingDirective implements OnDestroy {

  @Input() appDisabledOnWorking: Observable<void> | undefined;

  private destroy$ = new Subject<void>();
  private timeout: NodeJS.Timeout | undefined;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('click')
  onClick() {
    this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
    if (this.timeout !== undefined) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.renderer.setProperty(this.el.nativeElement, 'disabled', false);
    }, 30000);
    if (this.appDisabledOnWorking !== undefined) {
      this.appDisabledOnWorking.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
        if (this.timeout !== undefined) {
          clearTimeout(this.timeout);
        }
        this.timeout = undefined;
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

}
