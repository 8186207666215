import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { WeighingScaleService } from '../services/weighing-scale.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { HidService } from '../services/hid.service';

@Component({
  selector: 'app-weighing-scale-input',
  templateUrl: './weighing-scale-input.component.html',
  styleUrls: ['./weighing-scale-input.component.scss']
})
export class WeighingScaleInputComponent implements OnInit, OnDestroy {

  @Output() weight: EventEmitter<number> = new EventEmitter();
  protected form: FormGroup;
  private destroy$: Subject<void> = new Subject<void>();
  private unsubscribeMeasure: Subscription | undefined = undefined;
  protected deviceState = 'Not connected';

  constructor(
    private weighingScaleService: WeighingScaleService) {

    this.form = new FormGroup({
      weightValue: new FormControl(''),
      fromDevice: new FormControl(true),
    });
    this.weighingScaleService.onDisconnect().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.deviceState = 'Not connected';
    });
    this.weighingScaleService.onConnect().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.deviceState = 'Connected';
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.unsubscribeMeasure?.unsubscribe();
  }

  ngOnInit(): void {
    this.getMeasure();
    this.form.get('weightValue')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.weight.emit(value);
    });
    this.form.get('fromDevice')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(fromDevice => {
      if (fromDevice) {
        this.getMeasure();
      } else {
        this.unsubscribeMeasure?.unsubscribe();
        this.unsubscribeMeasure = undefined;
      }
    });
  }

  protected getMeasure(): void {
    this.unsubscribeMeasure?.unsubscribe();
    this.unsubscribeMeasure = this.weighingScaleService.getMeasure().subscribe(valor => {
      this.form.get('weightValue')?.setValue(valor);
      this.deviceState = 'Connected';
    });
  }

}
