<app-order-summary-sheet [order]="order"></app-order-summary-sheet>

<mat-card class="wrapperData">
    <div class="back">
        <button mat-stroked-button class="large" color="primary" (click)="onBack()" aria-label="Back"
            [appDisabledOnWorking]="onBackWorking$"><mat-icon>keyboard_backspace</mat-icon> Back</button>
    </div>
    <div class="scan">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box"
            [fullScreen]="true">
            <p class="spinner"> Loading... </p>
        </ngx-spinner>
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-spin"
            [name]="spinnerNameCloseBox" [fullScreen]="true">
            <p class="spinner"> Close box ... </p>
        </ngx-spinner>
        <app-scan-input class="scanInput" (onId)="onScan($event)" [disabled]="isScanDisabled"></app-scan-input>
    </div>
    <div class="scanText" *ngIf="stats !== undefined">SKU:
        <strong>{{stats.skuPacking}}</strong>/<strong>{{stats.skuTotal}}</strong> Units:
        <span><strong>{{stats.pending}}</strong>/<strong>{{stats.total}}</strong></span>
    </div>
    <div class="actions">
        <div class="buttonActions">
        <button mat-stroked-button class="icon-button-large" color="primary" (click)="onPrint()" [disabled]="isPrintDisabled"
            [appDisabledOnWorking]="progres$" aria-label="Print"><mat-icon>print</mat-icon> Print</button>
        <button mat-stroked-button class="icon-button-large" color="primary" (click)="onIncident()" [disabled]="isIncidentDisabled"
            [appDisabledOnWorking]="progres$" aria-label="Incident" data-test-id="buttonIncident"
            [matBadge]="incidentNumber" matBadgeColor="warn"
            [matBadgeHidden]="hideIncidentBadge"><mat-icon>warning</mat-icon> Incident</button>
        <button mat-stroked-button class="icon-button-large" color="primary" (click)="onRestartBox()" aria-label="restart"
            [disabled]="isRestartDisabled" [appDisabledOnWorking]="progres$"
            data-test-id="buttonRestartBox"><mat-icon>restart_alt</mat-icon> Restart</button>
        <button mat-stroked-button class="icon-button-large" color="primary" (click)="onInstructions()"
            [disabled]="isInstructionsDisabled" [appDisabledOnWorking]="progres$" aria-label="Instructions"
            data-test-id="buttonInstructions"><mat-icon>integration_instructions</mat-icon>
            Instructions</button>
        </div>
    </div>

    <mat-card class="orderTable mat-elevation-z8" tabindex="0">
        <app-order-table [orderLines]="packedState.orderLineList | sortOrderLinePackedVo"
            (onSelected)="onChangeSelected($event)" [selectedItem]="lineKey" class="orderList"></app-order-table>

    </mat-card>
    <div class="toolbar">

        <mat-toolbar>
            <mat-toolbar-row>
                <div class="boxType">
                    <span><strong class="boxtypeTitle">Box </strong> </span>
                    <ng-container *ngIf="boxModelId$ | async as boxModelId; else notSelect">
                        <span data-test-id="boxTypeValue">{{boxModelId._id}}</span>
                        <span class="boxId">&nbsp;/ {{boxModelId.boxModelId}}</span>
                    </ng-container>
                    <ng-template #notSelect><span data-test-id="boxTypeValue"> not selected</span></ng-template>
                </div>
            </mat-toolbar-row>
            <mat-toolbar-row>
                <qrcode *ngIf="!isCloseDisabled" [qrdata]="'##CLOSE#'" [width]="60" [errorCorrectionLevel]="'M'">
                </qrcode>
                <button mat-stroked-button class="large buttonToolbar" color="primary" (click)="onCloseBox()"
                    aria-label="closeBox" [disabled]="isCloseDisabled" [appDisabledOnWorking]="progres$"
                    data-test-id="buttonCloseBox"><mat-icon>inventory_2</mat-icon> Close</button>
            </mat-toolbar-row>
            <mat-toolbar-row>
                <ng-container *ngIf="boxModelId$ | async; else notSelectBoxType">
                    <button mat-stroked-button class="large buttonToolbar" color="primary" (click)="onChangeBoxType()"
                        [appDisabledOnWorking]="progres$" aria-label="change box type"
                        data-test-id="buttonChangeBox"><mat-icon>swap_horiz</mat-icon> Change Box Type</button>
                </ng-container>
                <ng-template #notSelectBoxType>
                    <button mat-stroked-button class="large buttonToolbar" color="primary" (click)="onSelectBoxType()"
                        [appDisabledOnWorking]="progres$" aria-label="change box type"
                        data-test-id="buttonChangeBox"><mat-icon>archive</mat-icon> Select Box Type</button>
                </ng-template>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
</mat-card>

<mat-expansion-panel class="boxPacked" tabindex="0">
    <mat-expansion-panel-header>
        <mat-panel-title>Packed </mat-panel-title>
        <mat-panel-description>
            <!-- Inform the date you wish to travel
        <mat-icon>date_range</mat-icon> -->
        </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-container *ngIf="packedState.packedList === undefined || packedState.packedList.length === 0; else withData">
        <div class="sinDatos">
            <mat-icon class="customIconSize">grid_off</mat-icon>
            <span class="sinDatosTexto">Items not packed</span>
        </div>
    </ng-container>
    <ng-template #withData>
        <app-order-table [orderLines]="packedState.packedList | sortOrderLinePackedVo"
            (onSelected)="onChangeSelected($event)" [itemStyle]="orderTableStyle.Packed"
            class="orderList packedList"></app-order-table>
    </ng-template>
</mat-expansion-panel>

<footer class="main-footer">
</footer>

<app-scroll-top></app-scroll-top>