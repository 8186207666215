import { Injectable } from '@angular/core';
import { PassageUser, PassageUserInfo } from '@passageidentity/passage-elements/passage-user';
import { Observable, combineLatest, forkJoin, from, map } from 'rxjs';
import { PassageUserVo } from '../model/passage-user-vo';

@Injectable({
  providedIn: 'root'
})
export class PassageAuthService {

  private userData$ = from(new PassageUser().userInfo());
  private tokenData$ = from(new PassageUser().getAuthToken());
  constructor() { }


  public get value(): Observable<PassageUserVo> {
    return forkJoin([this.userData$, this.tokenData$]).pipe(
      map(data => {
        const result = new PassageUserVo();
        if (data[0] !== undefined && data[0].email !== undefined) {
          result.isAuthenticated = true;
          result.username = data[0].email ? data[0].email : data[0].phone;
          result.token = data[1];
        } else {
          result.isAuthenticated = false;
        }
        return result;
      }
      ));
  }


}
