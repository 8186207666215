import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication-service';
import { Observable, map, of } from 'rxjs';
import { LoginData } from '../model/login-data';
import { UserInfo } from '../model/user-info';
import { BasicHttpClientService } from 'src/app/api/services/basic-http-client.service';
import { LoginRequest } from '../model/api/login-request';
import { LoginResponse } from '../model/api/login-response';
import { ErrorData } from 'src/app/api/model/error-data';
import { UserService } from './user-service';
import { ComposeUrlService } from 'src/app/api/services/compose-url.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationApiService extends AuthenticationService {


  constructor(
    private httpClient: BasicHttpClientService,
    private userService: UserService,
    private composeUrlService: ComposeUrlService
  ) {
    super();
  }
  
  public override logout(): Observable<void> {
    this.userService.clearUserInfo();
    // TODO pendiente de si hay llamada de logout
    return of(undefined);
  }

  public override login(data: LoginData): Observable<UserInfo> {
    const query: LoginRequest = {
      cContrasena: data.password,
      cUsuarioPDA: data.userId
    };
    this.userService.clearUserInfo();
    return this.httpClient
      .post<LoginRequest, LoginResponse>(this.composeUrlService.composeUrl(`CosmosApi_Login`), query)
      .pipe(
        map(data => {
          if (data !== undefined && data.Datos !== undefined && data.Datos.length > 0) {
            const result: UserInfo = {
              userId: data.Datos[0].Login
            };
            this.userService.setUserInfo(result);
            return result;
          } else {
            throw new ErrorData("Data login failed");
          }
        })
      )
  }

  public override isAuthenticated(): boolean {
    return this.userService.getUserName() !== null;
  }
}
