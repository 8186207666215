import { Injectable } from '@angular/core';
import { EnviromentContextService } from 'src/app/enviroment/services/enviroment-context-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiConfigService {

  private server = environment.apiServer;
  private protocol = environment.apiProtocol;

  constructor(private enviromentContextService: EnviromentContextService) { }

  public getUrlBase(): string {
    return `${this.protocol}://${this.server}/v2.0/ef42d319-47c9-4f6b-b37f-e795d2bd1c5e/${this.enviromentContextService.getEnviroment()}`;
  }

  public getUrl(fragment: string) : string {
    return `${this.getUrlBase()}/${fragment}`;
  }
}
