<mat-card class="wrapper">
    <span>Order Id: <strong>{{ this.orderId }}</strong></span>
    <span>Date: <strong>{{ this.date | date : 'shortDate' }}</strong></span>
    <span>Wave: <strong>{{ this.waveId }} # {{this.pickId}}</strong></span>
    <span>Carrier: <strong>{{ carrier }}</strong></span>
    <span>Country: <strong>{{ this.country}}</strong></span>
    <span>Status: <strong>{{ this.status }}</strong></span>
    <span>Slot: <strong>{{ this.slot }}</strong> <span class="containerId">(<strong>{{ this.containerId }}</strong>)</span></span>
    <span>Delivery Type: <strong>{{ this.deliveryType }}</strong></span>
    <span>Channel: <strong>{{ channel }}</strong></span>
    <span *ngIf="urgent" class="urgent"><strong>Urgent</strong></span>
</mat-card>