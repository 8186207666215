<div class="container-list" tabindex="0">
    <table mat-table [dataSource]="dataSource" class="data">
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="description" class="description-column">
            <th mat-header-cell *matHeaderCellDef class="headTitle"> Descr. </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="type" class="type-column">
            <th mat-header-cell *matHeaderCellDef class="headTitle"> Type </th>
            <td mat-cell *matCellDef="let element"> {{element.type}} </td>
        </ng-container>

        <ng-container matColumnDef="print" class="print-column">
            <th mat-header-cell *matHeaderCellDef> Print </th>
            <td mat-cell *matCellDef="let element">
                <ng-container [ngSwitch]="element.documentStatus">
                    <button mat-icon-button  (click)="onPrint(element)" *ngSwitchCase="DocumentPrintStatus.Ok">
                        <mat-icon class="icon-button-large">print</mat-icon>
                    </button>
                    <button mat-icon-button (click)="onMessage(element)" *ngSwitchCase="DocumentPrintStatus.NoFileContent">
                        <mat-icon color="warn">error</mat-icon>
                    </button>
                    <button mat-icon-button (click)="onMessage(element)" *ngSwitchCase="DocumentPrintStatus.PdfError">
                        <mat-icon class="icon-button-large" color="warn">error</mat-icon>
                    </button>
                </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="view" class="open-column">
            <th mat-header-cell *matHeaderCellDef> View </th>
            <td mat-cell *matCellDef="let element">
                <a mat-button href="{{element.url}}" target="_blank">
                    <mat-icon class="icon-button-large">open_in_new</mat-icon>
                </a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>