import { Injectable } from '@angular/core';
import { Observable, map, of, tap, throwError } from 'rxjs';
import { OrderLinePackedVoKey } from 'src/app/package/model/order-line-packed-vo-key';
import { Box } from 'src/app/package/model/box';
import { Wave } from 'src/app/picking/batch-pick/model/wave';
import { OrderLinePackedBoxVo, OrderLinePackedVo } from 'src/app/package/model/order-line-packed-vo';
import { BoxContentItem } from 'src/app/package/model/box-content-item';
import { PackingState } from 'src/app/common-app/model/packing-state';
import { OrderService } from 'src/app/order/services/order.service';
import { OrderKey } from 'src/app/order/model/order-key';
import { Order } from 'src/app/order/model/order';
import { OrderRepository } from 'src/app/order/services/state/order.repository';
import { OrderLine } from 'src/app/order/model/order-line';
import { OrderState } from 'src/app/order/model/order-state';
import { OrderContainer } from 'src/app/order/model/order-container';
import { OrderLineHelper } from 'src/app/order/services/order-line-helper';
import { PackedBoxService } from 'src/app/package/services/packed-box.service';

@Injectable({
  providedIn: 'root'
})
export class OrderPackingOperationsService {

  constructor(
    private orderService: OrderService,
    private orderRepository: OrderRepository,
    private packedBoxService: PackedBoxService,
  ) { }

  public prepareUpdateOrderLine(orderKey: OrderKey): Observable<Order | undefined> {
    return this.orderService.selectOrder(orderKey, false).pipe(
      tap(data => data !== undefined ? this.orderRepository.setActiveId(data.id) : data)
    );
  }

  public postUpdateOrderLine(containerId: string) {
    this.orderRepository.resetOrderContainer(containerId);
  }

  public setBoxLine(orderKey: OrderKey, sku: string, barCode: string[], count: number, boxId: string, boxType: string, closed: boolean) {
    const boxContentItem = new BoxContentItem(orderKey.id as string, sku, count, boxId, boxType, closed);
    this.orderRepository.updateBoxContent(orderKey, boxContentItem);
  }

  public updateOrderLine(orderKey: OrderKey, barCodeOrSku: string, box: Box | undefined, increment: number,
    pullLine: (orderId: OrderKey, line: OrderLine, box: Box | undefined) => Observable<string | undefined>): Observable<OrderLinePackedVoKey | undefined> {
    const order = this.orderRepository.getActiveOrder();
    let result: Observable<OrderLinePackedVoKey | undefined> = of(undefined);
    if (order !== undefined) {
      try {
        let line = this.orderService.updatePending(orderKey, barCodeOrSku, increment, box, false);
        if (line !== undefined) {
          result = pullLine(orderKey, line, box).pipe(
            map(data => {
              line = this.orderService.updatePending(orderKey, barCodeOrSku, increment, box, true);
              const result = new OrderLinePackedVoKey();
              result.boxId = data;
              result.line = line;
              return result;
            }));
        } else {
          result = throwError(() => new Error("Productivity not defined updating line"));
        }
      } catch (error) {
        result = throwError(() => error);
      }
    }
    return result;
  }

  public computeStats(data: Wave[]): [OrderState, number][] {
    const result: [OrderState, number][] = [];

    for (const iterator of data) {
      let state = result.find(item => item[0] === iterator.state);
      if (state === undefined) {
        state = [iterator.state, 0];
        result.push(state);
      }
      state[1] = state[1] + 1;
    }

    return result
  }

  public computeStatsByOrderContainer(data: OrderContainer | undefined): [OrderState, number][] {
    const result: [OrderState, number][] = [];

    if (data !== undefined && data.orders !== undefined && data.orders.length > 0) {
      for (const iterator of data.orders) {
        let state = result.find(item => item[0] === iterator.status);
        if (state === undefined) {
          state = [iterator.status, 0];
          result.push(state);
        }
        state[1] = state[1] + 1;
      }
    }

    return result
  }

  public computeStatsByWave(data: Wave[]): [OrderState, number][] {
    const result: [OrderState, number][] = [];

    for (const iterator of data) {
      if (iterator.orderId !== undefined) {
        let state = result.find(item => item[0] === iterator.state);
        if (state === undefined) {
          state = [iterator.state, 0];
          result.push(state);
        }
        state[1] = state[1] + 1;
      }
    }

    return result
  }

  public updateOrderLinePacked(orderLineList: OrderLinePackedVo[] | undefined, packedLines: OrderLinePackedVo[] | undefined, activeContent: BoxContentItem[], boxContentList: BoxContentItem[]) {
    if (orderLineList !== undefined) {

      for (const item of boxContentList) {
        this.updateItemInfoInPackedLines(orderLineList, packedLines, item, activeContent);
      }
    }
  }

  public updatePackedLines(orderId: string, item: BoxContentItem, descriptionItem: string, lines: OrderLinePackedVo[]) {
    const linePacked = lines.find(i => i.sku === item.sku && i.orderId === orderId);
    if (linePacked !== undefined) {
      linePacked.total = item.count + linePacked.total;
      this.updateBoxList(linePacked, item);
    } else {
      const linePacked = new OrderLinePackedVo();
      linePacked.orderId = orderId;
      linePacked.sku = item.sku;
      linePacked.description = descriptionItem;
      linePacked.total = item.count;
      linePacked.pending = 0;
      linePacked.gift = 0;
      linePacked.packingStatus = PackingState.Unknown;
      this.updateBoxList(linePacked, item);
      lines.push(linePacked);
    }
  }

  private updateBoxList(line: OrderLinePackedVo, boxContent: BoxContentItem) {
    const box = line.boxList.find(i => i.boxId === boxContent.boxId);
    if (box !== undefined) {
      box.itemPackedCount = box.itemPackedCount + boxContent.count;
      box.closed = boxContent.closed;
      box.status = this.packedBoxService.statusOrderLinePackedBoxVo(box);
    } else {
      const box = new OrderLinePackedBoxVo();
      box.boxId = boxContent.boxId;
      box.boxModelType = boxContent.boxType;
      box.itemPackedCount = boxContent.count;
      box.closed = boxContent.closed;
      box.status = this.packedBoxService.statusOrderLinePackedBoxVo(box);
      line.boxList.push(box);
    }

  }

  private updateItemInfoInPackedLines(orderLineList: OrderLinePackedVo[] | undefined, packedLines: OrderLinePackedVo[] | undefined, item: BoxContentItem, activeContent: BoxContentItem[]) {
    if (orderLineList !== undefined && packedLines !== undefined) {
      let total = item.count;
      const sku = item.sku;
      const itemSelected = orderLineList.filter(orderLine => orderLine.sku === sku && item.orderId === orderLine.orderId)
        .sort((a, b) => ((a.boxList.find(i => i.boxId === item.boxId && i.status === PackingState.Unknown) !== undefined) || a.pending === 0 ? 0 : 1) - ((b.boxList.find(i => i.boxId === item.boxId && i.status === PackingState.Unknown) !== undefined) || b.pending === 0 ? 0 : 1));

      let itemInActualBox = false;
      let description = '';
      for (let index = 0; index < itemSelected.length && total > 0; index++) {
        const line = itemSelected[index];
        line.packingStatus = OrderLineHelper.getPackingStatusFromOrderLine(line, activeContent);
        description = line.description;
        if (activeContent.findIndex(itemActive => itemActive.sku === sku && itemActive.orderId === item.orderId
          && ((itemActive.boxId == item.boxId) || (itemActive.boxId === undefined))) >= 0) {

          const totalPacked = line.total < total ? line.total : total;
          total = total - totalPacked;
          this.updateBoxList(line, item)
          itemInActualBox = true;
        }
      }
      if (!itemInActualBox) {
        const orderId = item.orderId;
        this.updatePackedLines(orderId, item, description, packedLines);
      }
    }
  }
}
