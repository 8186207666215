import { Injectable } from '@angular/core';
import { map, mergeMap, Observable } from 'rxjs';
import { OrderBoxGroup } from '../model/order-box-group';
import { BasicHttpClientService } from 'src/app/api/services/basic-http-client.service';
import { BoxModel } from '../model/box-model';
import { BoxGroup } from '../model/box-group';
import { BoxModelService } from './box-model.service';
import { BoxTypeRequest } from '../model/api/box-type-request';
import { UserService } from 'src/app/security/services/user-service';
import { BoxTypeResponse } from '../model/api/box-type-response';
import { ComposeUrlService } from 'src/app/api/services/compose-url.service';

@Injectable()
export class BoxModelApiService implements BoxModelService {

  constructor(
    private httpClient: BasicHttpClientService,
    private composeUrlService: ComposeUrlService,
    private userService: UserService) { }

  private convert(data: BoxTypeResponse): OrderBoxGroup | null {
    const result: OrderBoxGroup = new OrderBoxGroup();
    if (data !== undefined && data.Datos !== undefined && data.Datos.length > 0) {
      const boxGroup = new BoxGroup();
      result.boxModel = [boxGroup];
      boxGroup.boxList = data.Datos.map(item => {
        const model = new BoxModel();
        model.id = item.Id;
        let barCode = item['Bar Code'];
        if (barCode !== undefined && barCode !== null) {
          barCode = barCode.trim().toUpperCase();
        } else {
          barCode = '';
        }
        model.barCode = barCode;
        model.description = item.Description;
        return model;
      });
    }
    return result;
  }

  public getBoxModel(): Observable<OrderBoxGroup | null> {
    return this.userService.selectUserName().pipe(
      mergeMap(userName => {
        const query: BoxTypeRequest = {
          "cUsuarioPDA": userName as string
        };
        return this.httpClient.post<BoxTypeRequest, BoxTypeResponse>(this.composeUrlService.composeUrl(`CosmosApi_ObtenerListaCajasEmpaquetado`), query)
          .pipe(map(this.convert));
      }));

  }
}
