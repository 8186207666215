import { Injectable } from '@angular/core';
import { forkJoin, map, mergeMap, Observable, take, throwError } from 'rxjs';
import { BasicHttpClientService } from 'src/app/api/services/basic-http-client.service';
import { Order } from '../model/order';
import { OrderKey } from '../model/order-key';
import { OrderLine } from '../model/order-line';
import { OrderService } from './order.service';
import { OrderHelper } from './order-helper';
import { OrderLineStats } from '../model/order-line-stats';
import { OrderState } from '../model/order-state';
import { ArticleIncidentType } from 'src/app/incident/model/article-incident-type';
import { UserService } from 'src/app/security/services/user-service';
import { ReiniciarCajasPedidoRequest } from 'src/app/container/model/api/reiniciar-cajas-pedido-request';
import { ReiniciarCajasPedidoResponse } from 'src/app/container/model/api/reiniciar-cajas-pedido-response';
import { ComposeUrlService } from 'src/app/api/services/compose-url.service';
import { IdType, OrderPackagingContainerApiService } from './order-packaging-container-api.service';
import { ProductivityService } from 'src/app/productivity/services/productivity.service';
import { OrderIncidence } from '../model/order-incidence';

@Injectable({
  providedIn: 'root'
})
export class OrderApiService extends OrderService {

  constructor(
    private httpClient: BasicHttpClientService,
    private composeUrlService: ComposeUrlService,
    private productivityService: ProductivityService,
    private userService: UserService,
    private orderPackagingContainerApiService: OrderPackagingContainerApiService) {
    super();
  }

  public calculatePending(line: OrderLine): number {
    throw new Error('Method not implemented.');
  }

  public refreshOrder(orderId: OrderKey): Observable<Order | undefined> {
    throw new Error('Method not implemented.');
  }
  public updateBoxModelType(boxId: string, boxModelId: string): void {
    throw new Error('Method not implemented.');
  }
  public override updateIncident(orderId: OrderKey, newState: OrderState, incidentValue: ArticleIncidentType, barCodeOrSku: string, increment: number): void {
    throw new Error('Method not implemented.');
  }

  public updateOrderIncident(orderId: OrderKey, newState: OrderState, incidentValue: OrderIncidence): void {
    throw new Error('Method not implemented.');
  }

  public override updateStatus(orderKey: OrderKey, status: OrderState | undefined): void {
    throw new Error('Method not implemented.');
  }
  public override closeBox(orderId: OrderKey | undefined, boxId: string): void {
    throw new Error('Method not implemented.');
  }

  public override calculateStats(lines: OrderLine[] | undefined): OrderLineStats {
    throw new Error('Method not implemented.');
  }

  public override resetCache(): void {
    throw new Error('Method not implemented.');
  }

  public override getOrder(orderKey: OrderKey): Order | undefined {
    throw new Error('Method not implemented.');
  }

  public selectOrder(orderKey: OrderKey): Observable<Order | undefined> {
    if (orderKey.id !== undefined) {
      return this.orderPackagingContainerApiService.getOrderPackagingContainerByType(orderKey.id as string, IdType.Order).pipe(
        map(data => {
          if (data !== undefined && data.orders !== undefined) {
            return data.orders.find(i => i.id === orderKey.id);
          } else {
            return undefined;
          }
        })
      );
    } else {
      return throwError(() => new Error("Not found order id"));
    }

  }

  public checkArticle(i: OrderLine, barCodeOrSku: string, orderId: string | undefined = undefined): boolean {
    return OrderHelper.checkArticle(i, barCodeOrSku, orderId);
  }

  public newLines(orderId: string, lines: OrderLine[]): Observable<OrderLine[]> {
    return this.httpClient.post<OrderLine[], OrderLine[]>(`orders/${orderId}/orderLine`, lines);
  }

  public updateLines(orderId: string, lines: OrderLine[]): Observable<OrderLine[]> {
    return this.httpClient.put<OrderLine[], OrderLine[]>(`orders/${orderId}/orderLine`, lines);
  }

  public updatePending(orderId: OrderKey | undefined, barCode: string, increment: number): OrderLine | undefined {
    throw new Error("Not implemented");
  }

  public updateLine(orderId: string, lines: Partial<OrderLine>[]): void {
    throw new Error("Not implemented");
  }

  public getLine(orderId: OrderKey | undefined, barCode: string): OrderLine[] {
    throw new Error("Not implemented");
  }

  public reInitOrder(orderId: OrderKey): Observable<Order | undefined> {
    return forkJoin([
      this.userService.selectUserName().pipe(take(1)),
      this.productivityService.selectProductivity().pipe(take(1))])
      .pipe(
        mergeMap(data => {
          // const order = this.orderService.getOrder(orderId);
          // TODO controlar si es necesario el cDocumento
          const query: ReiniciarCajasPedidoRequest = {
            "iId": data[1] as number,
            "cUsuarioPDA": data[0] as string,
            cPedido: orderId.id as string,
            cDocumento: '',
          };
          return this.httpClient
            .post<ReiniciarCajasPedidoRequest, ReiniciarCajasPedidoResponse>(this.composeUrlService.composeUrl(`CosmosApi_ReiniciarCajasPedido`), query)
            .pipe(map(data => {
              // TODO devolver dato si hace falta
                 return undefined;
            })
            )
        }
        )
      );
  }

}
