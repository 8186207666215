<form [formGroup]="form">
    <mat-form-field appearance="standard">
        <mat-label><ng-content></ng-content></mat-label>
        <input matInput type="number" formControlName="weightValue">
        <mat-hint>{{deviceState}}</mat-hint>
    </mat-form-field>


    <button mat-icon-button aria-label="scale" color="primary" (click)="getMeasure()">
        <mat-icon>scale</mat-icon>
      </button>

</form>