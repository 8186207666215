import { Injectable } from '@angular/core';
import { OrderLinePackedBoxVo, OrderLinePackedVo } from '../model/order-line-packed-vo';
import { Order } from 'src/app/order/model/order';
import { BoxContentItem } from '../model/box-content-item';
import { PackingState } from 'src/app/common-app/model/packing-state';
import produce, { freeze } from 'immer';
import { OrderPackedState } from '../model/order-packed-state';
import { OrderState } from 'src/app/order/model/order-state';
import { OrderLineHelper } from 'src/app/order/services/order-line-helper';
import { PackedBoxService } from './packed-box.service';
import { Box } from '../model/box';
import { OrderService } from 'src/app/order/services/order.service';
import { OrderLine } from 'src/app/order/model/order-line';

@Injectable()
export class OrderLinePackedVoService {

  constructor(
    private packedBoxService: PackedBoxService,
    private orderService: OrderService
  ) { }

  private groupBoxLineBySku(data: BoxContentItem[]): Map<string, BoxContentItem[]> {
    const result = new Map<string, BoxContentItem[]>();
    for (const iterator of data) {
      const element = result.get(iterator.sku);
      if (element !== undefined) {
        element.push(iterator);
      } else {
        result.set(iterator.sku, [iterator]);
      }
    }
    return result;
  }



  private splitLineWithBox(line: OrderLinePackedVo, boxGroup: Map<string, BoxContentItem[]>, initialState: OrderPackedState, activeBox: Box | undefined): OrderPackedState {
    const box = boxGroup.get(line.sku);

    if (box === undefined) {
      initialState.orderLineList.push(line);
    } else {
      let quantityPacked = line.quantityPacked;
      let boxList: OrderLinePackedBoxVo[] = [];
      for (const iterator of box.sort((a, b) => a.closed === b.closed ? 0 : a.closed ? 1 : -1)){
        if (quantityPacked > 0 && iterator.count > 0) {
          const quantity = Math.min(iterator.count, quantityPacked);
          const result = new OrderLinePackedBoxVo();
          result.boxId = iterator.boxId;
          result.boxModelType = iterator.boxType;
          result.itemPackedCount = quantity;
          result.closed = iterator.closed;
          result.status = this.packedBoxService.statusOrderLinePackedBoxVo(result);
          boxList.push(result);
          this.substracMapBoxContentItem(boxGroup, line.sku, iterator.boxId, quantity);
          quantityPacked -= quantity;
        }
      }

      if (activeBox !== undefined) {
        boxList = boxList.sort((a, b) => a.boxId === activeBox._id ? -1 : b.boxId === activeBox._id ? 1 : 0);
      }
      initialState.orderLineList.push(produce(line, draft => {
        draft.boxList = boxList;
      }));
      const boxListClosed = boxList.filter(i => i.closed);
      if (boxListClosed.length > 0) {
        boxListClosed.forEach(i => {
          initialState.packedList.push(produce(line, draft => {
            draft.boxList = [i];
          }));
        });

      }
    }
    return initialState;
  }

  private substracMapBoxContentItem(boxGroup: Map<string, BoxContentItem[]>, sku: string, boxId: string, count: number): void {
    const box = boxGroup.get(sku);
    if (box !== undefined) {
      const updatedBox = produce(box, draft => {
        const index = draft.findIndex(i => i.boxId === boxId);
        if (index >= 0) {
          const countUpdate = draft[index].count - count;
          if (countUpdate <= 0) {
            draft.splice(index, 1);
          } else {
            draft[index] = new BoxContentItem(draft[index].orderId, draft[index].sku, countUpdate, draft[index].boxId, draft[index].boxType, draft[index].closed);
          }
        }
      });
      if (updatedBox.length === 0) {
        boxGroup.delete(sku);
      } else {
        boxGroup.set(sku, updatedBox);
      }
    }
  }


  public convertOrderToLinePackedVoList(orderData: Order | undefined, box: Box | undefined): OrderPackedState {
    let orderPackedState = new OrderPackedState();
    if (orderData !== undefined && orderData.lines !== undefined) {
      let content = this.groupBoxLineBySku(structuredClone(orderData.boxLines));
      const lineasWitoutPendingFirst = produce(orderData.lines, draft => OrderLineHelper.sortOrderLineList(draft));
      for (const item of lineasWitoutPendingFirst) {
        const line = this.converOrderLine(item as OrderLinePackedVo);
        orderPackedState = this.splitLineWithBox(line, content, orderPackedState, box);
      }
    }
    return orderPackedState;
  }

  public converOrderLine(line: OrderLine): OrderLinePackedVo {
    const result = new OrderLinePackedVo();
    result.id = line.id;
    result.orderId = line.orderId;
    result.lineNumber = line.lineNumber;
    result.sku = line.sku;
    result.description = line.description;
    result.pending = this.orderService.calculatePending(line);
    result.total = line.total;
    result.gift = line.gift;
    result.status = line.status;
    result.packingStatus = line.packingStatus;
    if (line.packingStatus === PackingState.Unknown) {
      if (line.boxContentItemList !== undefined && line.boxContentItemList.length > 0) {
        result.packingStatus = OrderLineHelper.getCompletPackingStatus(line, line.boxContentItemList);
      }
    }
    result.barCode = line.barCode;
    result.slotId = line.slotId;
    result.quantityPacked = line.quantityPacked;
    result.quantityRegistered = line.quantityRegistered;
    result.incidences = line.incidences;
    return result;
  }

  public convertOrderLinePacked(lines: OrderLine[] | undefined): OrderLinePackedVo[] {
    if (lines === undefined) {
      return [];
    }
    const result: OrderLinePackedVo[] = [];
    for (const item of lines) {
      const line = this.converOrderLine(item);
      result.push(line);
    }
    return result;
  }

  public convertOrderStatusToPackingStatus(status: OrderState | undefined): PackingState {
    if (status === undefined) {
      return PackingState.Unknown;
    }
    switch (status) {
      case OrderState.Incidence:
        return PackingState.Incident;
      case OrderState.Packed:
        return PackingState.Packed;
      case OrderState.Packing:
        return PackingState.Packing;
      case OrderState.Picked:
        return PackingState.Picked;
      case OrderState.Picking:
        return PackingState.Packing;
      case OrderState.OutOfPacking:
        return PackingState.OutOfPacking;
      default:
        return PackingState.Unknown;
    }
  }
}
